import { isRejectedWithValue } from '@reduxjs/toolkit';


export const rtkQueryErrorLogger = (api) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
        console.warn('data' in action.error
            ? action.error.data.message
            : action.error.message);
    }
    return next(action);
};
