import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import dropdownIcon from 'assets/img/dropdown-icon.svg'
import clearIcon from 'assets/img/clear-search-icon.svg'

const CurrencySelector = ({ initialValue = 'USD', onChange }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [filteredOptions, setFilteredOptions] = useState([]);
    const [searchTerm, setSearchTerm] = useState(initialValue);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const dropdownRef = useRef(null);
    const inputRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleMenuOpen = async () => {
        if (!isOpen && options.length === 0) {
            setLoading(true);
            setError('');
            try {
                const result = await axios('https://restcountries.com/v3.1/all');
                const mappedOptions = result.data.map(country => {
                    const currencies = country.currencies || {};
                    const firstCurrencyCode = Object.keys(currencies)[0] || 'N/A';
                    const firstCurrency = currencies[firstCurrencyCode] || {};
                    return {
                        value: firstCurrencyCode,
                        label: `${firstCurrencyCode} (${firstCurrency.symbol || 'No symbol'})`,
                        name: `${country.name.common}`,
                        emoji: country.flags?.png || ''
                    };
                });
                setOptions(mappedOptions);
                setFilteredOptions(mappedOptions);
            } catch (error) {
                console.error('Error fetching countries:', error);
                setError('Failed to fetch currency data');
            } finally {
                setLoading(false);
            }
        }
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        if (searchTerm === "") {
            setFilteredOptions(options);
        } else {
            const filtered = options.filter(option =>
                option.label.toLowerCase().includes(searchTerm.toLowerCase())
                || option.name.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setFilteredOptions(filtered);
        }
    }, [searchTerm, options]);

    const handleSelectOption = (option) => {
        onChange(option.value);
        setSearchTerm(option.label);
        setIsOpen(false);
    };

    const handleClearSearchTerm = () => {
        onChange('');
        setSearchTerm('');
    }

    return (
        <div ref={dropdownRef} className="relative w-full">
            <input
                ref={inputRef}
                onClick={() => handleMenuOpen()}
                onChange={(e) => setSearchTerm(e.target.value)}
                className='w-full p-2 bg-stone-900 outline-none rounded'
                value={searchTerm}
                placeholder="Search..."
            />
            <span className={`absolute right-3 top-[15px] transition-transform ${isOpen ? 'rotate-180' : ''}`}>
                <img src={dropdownIcon} className='h-3' alt="" />
            </span>
            <div className=' absolute h-6 w-[1px] bg-stone-200 top-2 right-[35px]'></div>
            {searchTerm &&
                <div className=' absolute bg-stone-200 top-[10px] right-[50px] cursor-pointer' onClick={() => handleClearSearchTerm()} >
                    <img src={clearIcon} className='bg-stone-900 h-5' />
                </div>
            }
            {isOpen && (
                <ul className="absolute w-full bg-stone-900 text-stone-200 border border-stone-700
                max-h-[200px] overflow-y-scroll list-none z-10 rounded py-2">
                    {loading && <li>Loading...</li>}
                    {error && <li>{error}</li>}
                    {filteredOptions.length > 0 && filteredOptions.map((option, index) => (
                        <li
                            key={index}
                            onClick={() => handleSelectOption(option)}
                            className="flex flex-row text-sm justify-left items-center px-3 py-2 rounded-sm cursor-pointer hover:bg-stone-700">
                            <img src={option.emoji} alt="Flag" className="inline w-8" />
                            <div className='flex flex-col ml-5'>
                                <span >{option.name}</span>
                                <span>{option.label}</span>
                            </div>
                        </li>
                    ))}
                    {
                        filteredOptions.length == 0 && <li className='flex flex-row justify-between items-center px-3 py-2 rounded-sm cursor-pointer hover:bg-stone-700'>No item match!</li>
                    }
                </ul>
            )}
        </div>
    );
};

export default CurrencySelector;
