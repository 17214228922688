import { useEffect, useState } from "react";
import Modal from "components/core/Modal";
import Button from "components/core/Button";
import { CATEGORY_FIELDS } from "configs/category.config";


const CategorySelectionOnType = ({ catSelectedName, onSelectCat, renderList, isParentType, currentCatId }) => {

    const [selectedCatName, setSelectedCatName] = useState();
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [selectionList, setSelectionList] = useState([])
    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false)
    const handleSelectCat = (id, name) => {
        setSelectedCatName(name);
        onSelectCat(id, name);
        closeModal()
    }
    useEffect(() => {
        if (isParentType) {
            setSelectionList(() => {
                return renderList.filter(catItem => {
                    return !catItem[CATEGORY_FIELDS.parentCatId] && catItem[CATEGORY_FIELDS.id] !== currentCatId
                })
            })
        } else {
            setSelectionList(renderList)
        }
    }, [])
    useEffect(() => {
        setSelectedCatName(catSelectedName)
    }, [catSelectedName])
    return (<>
        {
            <p type="text" onClick={openModal} className="mx-2 bg-stone-900 p-2 rounded-md cursor-pointer">{selectedCatName || 'Not Selected'}</p>
        }
        <Modal
            className='z-20'
            isOpen={isModalOpen}
            onClose={closeModal}
        >
            <div className="grid grid-cols-3 w-min-[50rem] max-h-[600px] gap-4 p-10 overflow-y-auto ">
                {
                    selectionList.map(catItem => {
                        return <div key={catItem._id} className="flex flex-row justify-left items-center gap-2 bg-stone-900 rounded-lg cursor-pointer p-3" onClick={() => handleSelectCat(catItem['_id'], catItem['name'])}>
                            <div className="rounded-full flex justify-center items-center bg-stone-300 h-14 w-14 ">
                                <img src={`/${catItem.icon_dir}`} className="h-10" alt="icon" />
                            </div>
                            <span className="bg-transparent outline-none placeholder:text-stone-200 text-base" type="text">{catItem.name}</span>
                        </div>
                    }
                    )
                }
            </div>
        </Modal>
    </>);
}

export default CategorySelectionOnType;