import Modal from "components/core/Modal";
import MoneyInputField from "components/core/MoneyInputField";
import { useEffect, useState } from "react";
import { BUDGET_FIELDS } from "configs/budget.config";
import TimePeriodSelector from "components/core/TimePeriodSelector";
import CategorySelector from "components/core/CategorySelector";

const BudgetModal = ({ currentBudget, isOnAdd, isOnEdit, onClose }) => {
    const [isOpen, setIsOpen] = useState(isOnAdd || isOnEdit);
    const [formData, setFormData] = useState(currentBudget);
    const [isDataChanged, setIsDataChanged] = useState(true)
    const [isCanSubmit, setIsCanSubmit] = useState(true)

    const handleChange = (value, field) => {
        if (value) {
            setFormData((prev) => {
                return {
                    ...prev,
                    [field]: value
                }
            })
        }
    }

    const closeModal = () => {
        setIsOpen(false);
        onClose()
    }

    const handleOnSave = () => {

    }

    useEffect(() => {
        setIsOpen(isOnAdd || isOnEdit);
    }, [isOnAdd, isOnEdit])

    useEffect(() => {
        // set
    })

    return (
        <Modal
            isOpen={isOpen}
            onClose={closeModal}
            isForm
            handleOnSave={handleOnSave}
            isDataChanged={isDataChanged}
            isCanSubmit={isCanSubmit}
        >
            <h4 className="mb-5 text-lg font-bold">Your Budget</h4>
            <div className="flex flex-col gap-4 w-[25rem]">
                <label className="font-bold text-sm text-stone-300">Select Category</label>
                <div className="flex flex-col gap-2 text-base w-[15rem]">
                    <CategorySelector
                        type='expense'
                        onSelectCat={(value) => handleChange(value, BUDGET_FIELDS.categoryId)}
                    />
                </div>
                <div className="flex flex-col gap-2 text-base w-[15rem]">
                    <MoneyInputField
                        label='Amount'
                        initialValue={formData && formData[BUDGET_FIELDS.value]}
                        onChangeAmount={value => handleChange(value, BUDGET_FIELDS.value)}
                    />
                </div>
                <div className="flex flex-col gap-2 text-base w-[25rem]">
                    <label className="font-bold text-sm text-stone-300">Date Range</label>
                    <TimePeriodSelector
                        onSelectDateRange={value => handleChange(value, BUDGET_FIELDS.dateRange)}
                    />
                </div>
            </div>
        </Modal>
    );
}

export default BudgetModal;