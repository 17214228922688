import forgotPassIcon from "assets/img/forgot.png"
import BgImage from "components/core/BgImage";
import Text from "components/core/Text";
import ChoiceSeparation from "components/core/ChoiceSeparation";
import FormAccessContainer from "components/access-page/FormAccessContainer";
import HeaderFormAccessContainer from "components/access-page/HeaderFormAccessContainer";
import { useNavigate } from "react-router-dom";
import ForgotPassForm from "components/access-page/ForgotPassForm";
import Footer from "components/layouts/Footer";



const ForgotPassword = () => {
    const navigate = useNavigate();
    const moveToSignUp = () => {
        navigate('/signup');
    }
    const moveToLogin = () => {
        navigate('/login');
    }
    return (
        <main className="bg-stone-200 p-0 m-0 flex flex-col h-full ">
            <div className=" text-stone-900 flex flex-col justify-center items-center ml-auto mr-auto h-full mb-11 pb-11">
                <FormAccessContainer>
                    <HeaderFormAccessContainer classCss="flex flex-col justify-center items-center">
                        <div className="mt-9 mb-3" >
                            <BgImage
                                url={forgotPassIcon}
                                height="100px"
                                width="100px"
                                size='contain'
                            />
                        </div>
                        <div className="mt-0 mx-10 mb-3">
                            <Text
                                className='text-[16px] font-semibold leading-5'
                            >
                                Trouble logging in?
                            </Text>

                        </div>
                        <div className="mb-3 mx-10">
                            <Text className='text-accessSecondaryText text-[14px]'>
                                Enter your email and we'll send you a link to get back into your account.
                            </Text>
                        </div>
                    </HeaderFormAccessContainer>
                    <ForgotPassForm className='flex flex-col w-full px-10 justify-center' />
                    <div className="py-3 px-10 w-full">
                        <ChoiceSeparation />
                    </div>
                    <div className="py-2 px-9 w-full" onClick={moveToSignUp}>
                        <Text className='text-accessLink text-sm cursor-pointer font-semibold'>
                            Create new account
                        </Text>
                    </div>
                    <div onClick={moveToLogin} className="w-full text-accessPrimaryText flex flex-row justify-center round-[1px] py-1 mt-5 bg-accessSecondaryBackground border-solid border-stone-300 border cursor-pointer">
                        <span className="w-full text-sm text-center m-2 font-semibold ">Back to login</span>
                    </div>
                </FormAccessContainer>
            </div>
            <Footer className="text-accessSecondaryText" />
        </main>
    );
}

export default ForgotPassword;