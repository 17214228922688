import TransactionTable from "components/transaction-page/TransactionTable"
import TransactionSlidePanel from "components/transaction-page/TransactionSlidePanel";
import { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import TimePeriodFilter from "components/core/TimePeriodFilter";
import { isEmptyObj, toastError } from "utils/helpers";
import WalletFilter from 'components/core/WalletFilter';
import CategoryFilter from 'components/core/CategoryFilter';
import { TRANSACTION_FIELDS } from 'configs/transaction.config';
import { useDuplicateTransactionsMutation, useListTransactionMutation } from "services/api/TransactionService";
import { useListWalletsQuery } from "services/api/WalletService";
import trashIcon from 'assets/img/trash-icon.svg'
import TransactionDeleteModal from "components/transaction-page/TransactionDeleteModal";
import TransactionPagination from "components/transaction-page/TransactionPagination";
import ExportButton from "components/transaction-page/ExportButton";
import duplicateIcon from 'assets/img/duplicate-icon.svg'
import toast from "react-hot-toast";


const Transactions = ({ newTransaction, onAdded }) => {
    const walletId = useSelector(state => state.walletFilter.id)
    const dateFilter = useSelector(state => state.dateFilter.filterDate)
    const categoryFilterPosition = useSelector(state => state.dateFilter.name) !== 'Customize' ? true : false;
    const { refetch } = useListWalletsQuery();
    const [isPanelOpen, setIsPanelOpen] = useState(false)
    const [queryGetList, setQueryGetList] = useState({
        page: 1,
        limit: 10,
        sortDirection: 'desc',
        filters: {
            date: dateFilter,
            walletId: walletId
        }
    })
    const [listTransactions, { data, isSuccess, error }] = useListTransactionMutation();
    const [transactionList, setTransactionList] = useState([]);
    const [selectedIds, setSelectedIds] = useState([]);
    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
    const [currentTransaction, setCurrentTransaction] = useState();
    const [isOnEdit, setIsOnEdit] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(3);
    const [isDeleted, setIsDeleted] = useState(false);
    const [isCancelSelect, setIsCancelSelect] = useState(false);
    const [duplicateTransactions, { data: duplicateData, isSuccess: isDuplicateSuccess, error: duplicateError }] = useDuplicateTransactionsMutation();

    useEffect(() => {
        setQueryGetList(prev => {
            return {
                ...prev,
                page: 1,
                filters: {
                    ...prev.filters,
                    wallet_id: walletId
                }
            }
        })
    }, [walletId]);
    useEffect(() => {
        setQueryGetList(prev => {
            return {
                ...prev,
                page: 1,
                filters: {
                    ...prev.filters,
                    date: dateFilter
                }
            }
        })
        setCurrentPage(1);
    }, [dateFilter]);


    const handleOnDeleted = (ids) => {
        if (!ids || ids.length === 0) return
        setTransactionList((prev) => {
            const newList = prev.filter(transaction => !ids.includes(transaction[TRANSACTION_FIELDS.id]))
            return newList
        })
        setIsDeleted(true);
    }

    const handleUpdated = (transaction) => {
        if (!transaction || isEmptyObj(transaction)) return
        setTransactionList(prev => {
            const newList = prev.map(item => item[TRANSACTION_FIELDS.id] === transaction[TRANSACTION_FIELDS.id] ? transaction : item)
            return newList
        })
    }

    const handleOnClosePanel = () => {
        setIsPanelOpen(false)
        setIsOnEdit(false);
        setCurrentTransaction({})
    }

    const handleChangeFilter = (value, field) => {
        setQueryGetList(prev => {
            return {
                ...prev,
                page: 1,
                filters: {
                    ...prev.filters,
                    [field]: value
                }
            }
        })
        setCurrentPage(1);
    }
    const handleOnEdit = (transaction) => {
        setIsPanelOpen(true)
        setIsOnEdit(true);
        setCurrentTransaction(transaction)
    }

    const handleDuplicate = async () => {
        await duplicateTransactions({ ids: selectedIds });
    }

    useEffect(() => {
        if (newTransaction) {
            refetch()
            setTransactionList(prev => [newTransaction, ...prev])
            onAdded()
        }
    }, [newTransaction])
    useEffect(() => {
        if (isDuplicateSuccess) {
            setIsCancelSelect(true);
            setTransactionList(prev => [...duplicateData.metadata, ...prev]);
            refetch();
            toast.success(duplicateData.message);
        }
        if (duplicateError) {
            toastError(duplicateError);
        }
    }, [duplicateData, isDuplicateSuccess, duplicateError])

    useEffect(() => {
        const fetchData = async () => {
            await listTransactions(queryGetList);
        }
        fetchData();
    }, [queryGetList])

    useEffect(() => {
        if (isSuccess) {
            setTransactionList(data.metadata.transactions)
            setTotalPages(data.metadata.totalPages)
        }
        if (error) {
            toastError(error)
        }
    }, [isSuccess, error, data])

    const handleOnSelectedIds = listIds => {
        setSelectedIds(listIds)
        setIsCancelSelect(false);
    }


    const handlePageChange = page => {
        setCurrentPage(page);
        setQueryGetList(prev => {
            return {
                ...prev,
                page
            }
        })
    };
    const handleOnSort = field => {
        setQueryGetList(prev => {
            return {
                ...prev,
                sortField: field,
                sortDirection: prev.sortDirection === 'desc' ? 'asc' : 'desc'
            }
        })
    }
    return (
        <div className="flex flex-col mb-[10rem] w-full p-10">
            <div className="py-5 flex flex-row justify-between text-stone-200">
                <h3 className="font-bold xl:text-lg phone:text-base">Your transactions</h3>
            </div>
            <div className="w-full">
                <div className={`flex xl:flex-row flex-col justify-between gap-2 mb-4`}>
                    <div className={`flex sm:flex-row  flex-col gap-4 sm:items-center items-star`}>
                        <WalletFilter />
                        <TimePeriodFilter />
                        {categoryFilterPosition && <CategoryFilter
                            onSelectCat={(catId) => handleChangeFilter(catId, TRANSACTION_FIELDS.categoryId)}
                        />}
                    </div>

                    <div className="flex flex-row gap-4 items-center justify-between">
                        <div className="flex flex-row gap-4">
                            <ExportButton query={queryGetList} />
                        </div>
                    </div>
                </div>
                {!categoryFilterPosition && <CategoryFilter
                    onSelectCat={(catId) => handleChangeFilter(catId, TRANSACTION_FIELDS.categoryId)}
                />}
                <div className='flex flex-col gap-2 mb-4'>
                    <div className='flex lg:flex-row flex-col gap-4 justify-between lg:items-center items-start mt-4'>
                        {selectedIds.length > 0 &&
                            <div className="flex flex-row gap-2 items-center">
                                <button className='flex flex-row gap-2 items-center p-2 bg-green-800 text-stone-200 outline-none rounded-lg' onClick={(e) => handleDuplicate()}>
                                    <img src={duplicateIcon} className='h-4' />
                                    <span>Duplicate ({selectedIds.length}) selected</span>
                                </button>
                                <button className='flex flex-row gap-2 items-center p-2 bg-red-800 text-stone-200 outline-none rounded-lg' onClick={(e) => setIsOpenDeleteModal(true)}>
                                    <img src={trashIcon} className='h-4' />
                                    <span>Delete ({selectedIds.length}) selected</span>
                                </button>
                                <button className="items-center p-2 bg-stone-500 text-stone-200 outline-none rounded-lg" onClick={(e) => setIsCancelSelect(true)}>
                                    <span>Cancel</span>
                                </button>
                            </div>}
                    </div>
                    <div className="w-full">
                        <TransactionTable
                            transactionList={transactionList}
                            onSelectedIds={(selectedIds) => handleOnSelectedIds(selectedIds)}
                            onEdit={handleOnEdit}
                            onSort={handleOnSort}
                            isDeleted={isDeleted}
                            isCancelSelect={isCancelSelect}
                        />
                    </div>
                    <div className="mt-2 items-right">
                        <TransactionPagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
                    </div>
                </div >
                <TransactionSlidePanel
                    isOpen={isPanelOpen}
                    onClose={handleOnClosePanel}
                    currentTransaction={currentTransaction}
                    isOnEdit={isOnEdit}
                    onUpdated={handleUpdated}
                />
                <TransactionDeleteModal
                    transactionIds={selectedIds}
                    isOnOpen={isOpenDeleteModal}
                    onClose={() => setIsOpenDeleteModal(false)}
                    onDeleted={handleOnDeleted}
                />
            </div >
        </div>);
}

export default Transactions;