import { useState, useRef, useEffect } from 'react';

const DropdownDateInput = ({ initialValue = 1, onChange }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedDay, setSelectedDay] = useState(initialValue);
    const toggleDropdown = () => setIsOpen(!isOpen);

    const daysOptions = Array.from({ length: 31 }, (_, i) => `${i + 1}`);

    const handleSelect = (day) => {
        onChange(day)
        setSelectedDay(day);
        setIsOpen(false);
    };

    // Handle clicking outside to close dropdown
    const dropdownRef = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [dropdownRef]);

    return (
        <div className="relative w-[200px] " ref={dropdownRef}>
            <div className=" w-[5rem] bg-stone-900 p-2 rounded text-stone-200 cursor-pointer" onClick={toggleDropdown}>
                {selectedDay} <span className="float-right text-[1.5rem] self-center">&#9662;</span>
            </div>
            {isOpen && (
                <ul className="absolute w-[5rem] bg-stone-900 text-stone-200 border border-stone-700
                max-h-[200px] overflow-y-scroll list-none z-10 rounded py-2">
                    {daysOptions.map(day => (
                        <li key={day} onClick={() => handleSelect(day)} className=" px-3 py-2 rounded-sm cursor-pointer hover:bg-stone-700">
                            {day}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
}

export default DropdownDateInput;
