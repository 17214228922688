import { Link as RouterLink } from "react-router-dom";

const SidebarItem = ({ to, icon, text, active }) => {

    return (
        <RouterLink to={to} >
            <li className={`rounded-md hover:bg-stone-800 ${active && 'bg-stone-800'}`}>
                <span className="flex flex-row sm:justify-start xl:p-5 p-5 xl:gap-5 sm:p-2 sm:gap-2 gap-2 text-base no-underline">
                    <img className="h-6" src={icon} alt="" />
                    <span className="">
                        {text}
                    </span>
                </span>
            </li>
        </RouterLink >
    );
}

export default SidebarItem;