import { BrowserRouter as Router } from 'react-router-dom'
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from "store";
import { Toaster } from 'react-hot-toast';
import ReactGA from 'react-ga4';


ReactGA.initialize("G-KNGFDJTFFJ");
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <App />
      </Router>
      <Toaster
        position="bottom-left"
        reverseOrder={false}
      />
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
