export const WALLET_FIELDS = {
    name: 'name',
    id: '_id',
    type: 'type',
    currency: 'currency',
    includeInTotal: 'include_in_total',
    balance: 'balance',
    initialBalance: 'initial_balance',
    attributes: 'attributes',
}

export const WALLET_TYPES = ['basic', 'credit', 'goal']

export const ATTRIBUTES_ON_TYPE = {
    credit: {
        creditLimit: 'credit_limit',
        statementDay: 'statement_day',
        paymentDueDay: 'payment_due_day'
    },
    goal: {
        goalValue: 'goal_value',
        endingDate: 'ending_date'
    }
}

export const BLANK_WALLET = {
    basic: {
        name: '',
        type: 'basic',
        currency: 'VND',
        include_in_total: true,
        balance: undefined,
        initial_balance: undefined,
    },
    credit: {
        name: '',
        type: 'credit',
        currency: 'VND',
        include_in_total: false,
        initial_balance: undefined,
        attributes: {
            credit_limit: undefined,
            statement_day: 1,
            payment_due_day: 10,
        }
    },
    goal: {
        name: '',
        type: 'goal',
        currency: 'VND',
        include_in_total: false,
        initial_balance: undefined,
        attributes: {
            goal_value: undefined,
            ending_date: undefined
        }
    }
}

