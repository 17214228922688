import { useEffect, useState } from 'react';
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useListExpenseCategoriesQuery, useListIncomeCategoriesQuery } from "services/api/CategoryService";
import { formatMoneyNumber } from 'utils/helpers';


const RADIAN = Math.PI / 180;
const CategoriesPieChart = ({ categoryStatic }) => {
    // useEffect(() => {
    //     console.log(categoryStatic);
    // }, [categoryStatic])
    const { data: expenseList, isSuccess: isGetExpenseListSuccess } = useListExpenseCategoriesQuery();
    const { data: incomeList, isSuccess: isGetIncomeListSuccess } = useListIncomeCategoriesQuery();
    const [data, setData] = useState([]);
    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#A4DE6C', '#D0ED57', '#FFC658'];

    const [categoryList, setCategoryList] = useState([]);

    useEffect(() => {
        if (isGetExpenseListSuccess && isGetIncomeListSuccess) {
            setCategoryList([...expenseList.metadata, ...incomeList.metadata]);
        }
    }, [isGetExpenseListSuccess, isGetIncomeListSuccess])

    useEffect(() => {
        const categoryMap = new Map(
            categoryList
                .filter(cat => cat.type === 'expense')
                .map(cat => [cat._id, cat.name])
        );
        const formattedTransactions = categoryStatic.map(transaction => {
            let categoryName = '';
            if (transaction.name !== 'Others') {
                categoryName = categoryMap.get(transaction.name) || 'unknown'
            } else {
                categoryName = 'Others'
            }
            return { name: categoryName, value: Math.abs(transaction.value) };
        })
        setData(formattedTransactions);
    }, [categoryList, categoryStatic])

    const renderLegend = (value, entry, index) => {
        return (
            <span>
                {value}: {formatMoneyNumber(String(entry.payload.value))}
            </span>
        );
    };

    const renderCustomTooltip = ({ payload, label, active }) => {
        if (active && payload && payload.length) {
            const total = data.reduce((acc, entry) => acc + entry.value, 0);
            const percentage = ((payload[0].value / total) * 100).toFixed(2);
            return (
                <div className="bg-stone-950 text-stone-200 p-2 border rounded shadow-lg">
                    <p className="font-semibold">{`${payload[0].name} : ${formatMoneyNumber(String(payload[0].value))}`}</p>
                    <p>{`(${percentage}%)`}</p>
                </div>
            );
        }

        return null;
    };
    const CustomLegendContent = ({ payload }) => (
        <div className="flex flex-col items-start p-2">
            {payload.map((entry, index) => (
                <div key={`item-${index}`} className="flex items-center mb-1">
                    <div className="w-3 h-3 rounded-full mr-2" style={{ backgroundColor: entry.color }} />
                    {renderLegend(entry.value, entry, index)}
                </div>
            ))}
        </div>
    );
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };

    return (
        <ResponsiveContainer >
            <PieChart>
                <Pie
                    data={data}
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    // outerRadius={150}
                    fill="#8884d8"
                    labelLine={false}
                    label={renderCustomizedLabel}
                >
                    {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                </Pie>
                <Tooltip content={renderCustomTooltip} />
                <Legend content={<CustomLegendContent />} />
            </PieChart>
        </ResponsiveContainer>
        // <h1>helllo</h1>
    );
}

export default CategoriesPieChart;
