import Modal from "components/core/Modal";
import ToggleOnOff from "components/core/ToggleOnOff";
import goalIcon from 'assets/img/goal-money-icon.svg'
import MoneyInputField from "components/core/MoneyInputField";
import CurrencySelector from "components/core/CurrencySelection";
import useWalletModalController from "hooks/wallet/useWalletModalController";
import { ATTRIBUTES_ON_TYPE, WALLET_FIELDS } from "configs/wallet.config";
import get from 'lodash/get'
import { getCurrencySymbol, timestampToDateInputValue } from "utils/helpers";

const GoalWalletModal = ({ currentWallet, isOnOpen, onClose, onUpdated, onCreated }) => {
    const {
        isModalOpen,
        isCanSubmit,
        formData,
        isDataChanged,
        closeModal,
        handleChange,
        handleOnSave
    } = useWalletModalController(currentWallet, isOnOpen, onClose, onUpdated, onCreated)

    return (
        <Modal
            isOpen={isModalOpen}
            onClose={closeModal}
            isForm
            handleOnSave={handleOnSave}
            isCanSubmit={isCanSubmit}
            isDataChanged={isDataChanged}
        >
            <h4 className="mb-5 text-lg font-bold">Goal Wallet</h4>
            <div className="flex flex-col gap-4 sm:w-[30rem] phone:w-[20rem]">
                <div className="flex flex-col gap-2 text-base w-full">
                    <label className="font-bold text-sm text-stone-300">Wallet Name</label>
                    <input
                        className="h-7 py-4 px-4 bg-stone-900 rounded outline-none w-1/2"
                        onChange={e => handleChange(e.target.value, 'name')}
                        value={formData && formData[WALLET_FIELDS.name]}
                    />
                    <div className="flex flex-col gap-2 w-1/2 my-2">
                        <span className="font-bold text-sm text-stone-300 ">Currency</span>
                        <CurrencySelector
                            initialValue={formData && `${formData[WALLET_FIELDS.currency]} (${getCurrencySymbol(formData[WALLET_FIELDS.currency])})`}
                            onChange={value => handleChange(value, 'currency')} />
                    </div>
                    <div className="flex flex-row gap-2 ">
                        <MoneyInputField
                            label='Goal Amount'
                            initialValue={get(formData, `${WALLET_FIELDS.attributes}.${ATTRIBUTES_ON_TYPE.goal.goalValue}`)}
                            currencyCode={get(formData, `${WALLET_FIELDS.currency}`, 'VND')}
                            onChangeAmount={value => handleChange(value, ATTRIBUTES_ON_TYPE.goal.goalValue)}
                        />
                        <MoneyInputField
                            label='Initial Amount'
                            initialValue={get(formData, `${WALLET_FIELDS.initialBalance}`)}
                            currencyCode={get(formData, `${WALLET_FIELDS.currency}`, 'VND')}
                            onChangeAmount={value => handleChange(value, WALLET_FIELDS.initialBalance)}
                        />
                    </div>
                    <div className="flex flex-row gap-2 mt-3 ">
                        <div className="flex flex-col gap-2 w-full">
                            <label className="font-bold text-sm text-stone-300">End date</label>
                            <input
                                type="date"
                                className="h-7 py-4 px-4 bg-stone-900 rounded outline-none w-1/2"
                                value={timestampToDateInputValue(get(formData, `${WALLET_FIELDS.attributes}.${ATTRIBUTES_ON_TYPE.goal.endingDate}`, 1714489705))}
                                onChange={e => handleChange(e.target.value, ATTRIBUTES_ON_TYPE.goal.endingDate)}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex flex-col gap-2 text-base w-[10rem]">
                    <label className="font-bold text-sm text-stone-300">Include in total</label>
                    <input type='hidden' value={formData && formData[WALLET_FIELDS.includeInTotal]} />
                    <ToggleOnOff isOn={formData && formData[WALLET_FIELDS.includeInTotal]} onToggle={value => handleChange(value, 'include_in_total')} />
                </div>
            </div>

            <div className="absolute right-5 top-5 opacity-40 rotate-45 overflow-hidden z-[-1]">
                <img src={goalIcon} className="h-[10rem]" alt="" />
            </div>
        </Modal>
    );
}

export default GoalWalletModal;