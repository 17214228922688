export const CATEGORY_FIELDS = {
    iconDir: 'icon_dir',
    name: 'name',
    parentCatId: 'parent_category',
    id: '_id',
    type: 'type',
    isOweType: 'is_owe_type'
}

export const CATEGORY_DEFAULT_ON_TYPE = {
    'income': 'Other Income',
    'expense': 'Other Expense',
    'owe': 'Debt'
}