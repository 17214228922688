import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { setLogout } from "store/auth";

export const useLogoutController = () => {
    const { isErrorWithMessage, isFetchBaseQueryError } = require("utils/helpers");
    const dispatch = useDispatch();
    const handleLogoutClick = async (e, token, requestCallback) => {
        e.preventDefault();
        await requestCallback({ token });
    }

    const handleLogoutSuccess = (isSuccess) => {
        if (!isSuccess) {
            return
        }
        dispatch(setLogout());
    }

    const handleLogoutError = (error) => {
        if (error) {
            if (isErrorWithMessage(error)) toast.error(error.message)
            if (isFetchBaseQueryError(error)) toast.error(error.data.message)
        }
    }

    return {
        handleLogoutClick,
        handleLogoutSuccess,
        handleLogoutError
    }
}