export const categoriesIconName = [
    'food.svg',
    'bills.svg',
    'transportation.svg',
    'family.svg',
    'shopping.svg',
    'party.svg',
    'restaurant.svg',
    'coffee.svg',
    'other-expense.svg',
    'salary.svg',
    'collect-interest.svg',
    'selling.svg',
    'award.svg',
    'other-income.svg',
    'broken-heart.svg',
    'beer.svg',
    'donation.svg',
    'loan.svg',
    'debt.svg',
    'debt-collection.svg',
    'repayment.svg',
    'electricity-bill.svg',
    'entertainment.svg',
    'health.svg',
    'education.svg',
    'phone-bill.svg',
    'rental.svg',
    'movie.svg',
    'television.svg',
    'game.svg',
    'investment.svg',
    'saving.svg'
]