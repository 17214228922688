import { ATTRIBUTES_ON_TYPE, WALLET_FIELDS } from "configs/wallet.config";
import { formatCurrency, formatDate, getNextPaymentDate } from "utils/helpers";
import threeDotsIcon from 'assets/img/threedots-icon.svg'
import creditIcon from 'assets/img/credit-icon.svg'
import trashIcon from 'assets/img/trash-icon.svg'

const CreditWalletCard = ({ walletItem, onClickEdit, onClickDelete }) => {
    if (!walletItem || walletItem[WALLET_FIELDS.type] !== 'credit') return null

    const nextPaymentDate = getNextPaymentDate(walletItem[WALLET_FIELDS.attributes][ATTRIBUTES_ON_TYPE.credit.paymentDueDay]);
    const creditLimit = walletItem[WALLET_FIELDS.attributes][ATTRIBUTES_ON_TYPE.credit.creditLimit];
    const walletBalance = walletItem[WALLET_FIELDS.balance] + walletItem[WALLET_FIELDS.initialBalance];
    const creditOutstanding = formatCurrency(creditLimit - walletBalance, walletItem[WALLET_FIELDS.currency])
    return (
        <div
            className="flex flex-col justify-between bg-amber-700 text-stone-200 relative group z-0 p-5 rounded-lg shadow-md">
            <div className="w-full flex flex-row gap-6 justify-left items-center">
                <img src={creditIcon} className="h-[90px]" alt="icon" />
                <div className='xl:text-2xl phone:text-sm text-stone-300 font-bold' >
                    <p>
                        {walletItem[WALLET_FIELDS.name]}
                    </p>
                </div>
            </div>
            <div className='xl:text-lg phone:text-sm'>
                <p className='text-stone-300'>Credit Outstanding:</p>
                <p className='text-stone-200 font-bold'>
                    {creditOutstanding}
                </p>
                <p className='text-stone-300'>Next payment due date:</p>
                <p className='text-stone-200 font-bold'>{formatDate(nextPaymentDate.toLocaleDateString())}</p>
            </div>
            <div className='absolute top-2 right-3 cursor-pointer' onClick={e => onClickEdit(e, walletItem)}>
                <img src={threeDotsIcon} alt="" className='h-8' />
            </div>
            <div className='absolute bottom-3 right-3 cursor-pointer' onClick={e => onClickDelete(e, walletItem)}>
                <img src={trashIcon} alt="" className='h-7' />
            </div>
        </div>
    );
}

export default CreditWalletCard;