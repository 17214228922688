import { createApi } from "@reduxjs/toolkit/query/react"
import { baseQueryWithReAuth } from "./QueryReAuth"
export const categoryApi = createApi({
    reducerPath: 'categoryApi',
    baseQuery: baseQueryWithReAuth,
    endpoints: (builder) => ({
        // listCategories: builder.query({
        //     query: () => `/category`
        // }),
        listIncomeCategories: builder.query({
            query: () => `/category/income`
        }),
        listExpenseCategories: builder.query({
            query: () => `/category/expense`
        }),
        listOweCategories: builder.query({
            query: () => `/category/owe`
        }),
        updateCategory: builder.mutation({
            query: ({ catId, ...body }) => {
                return {
                    url: `/category/${catId}`,
                    method: 'PATCH',
                    body: body
                }
            },
        }),
        createCategory: builder.mutation({
            query: ({ ...body }) => {
                return {
                    url: `/category`,
                    method: 'POST',
                    body: body
                }
            },
        }),
        deleteCategory: builder.mutation({
            query: ({ catId, replaceId }) => {
                return {
                    url: `/category/${catId}/${replaceId}`,
                    method: 'DELETE',
                }
            },
        })
    })
})

export const {
    useListExpenseCategoriesQuery,
    useListIncomeCategoriesQuery,
    useListOweCategoriesQuery,
    useUpdateCategoryMutation,
    useCreateCategoryMutation,
    useDeleteCategoryMutation
} = categoryApi
