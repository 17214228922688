export const TRANSACTION_FIELDS = {
    id: '_id',
    date: 'date',
    walletId: 'wallet_id',
    categoryId: 'category_id',
    amount: 'amount',
    note: 'note'
}

export const BLANK_TRANSACTION = {
    [TRANSACTION_FIELDS.date]: undefined,
    [TRANSACTION_FIELDS.categoryId]: undefined,
    [TRANSACTION_FIELDS.walletId]: undefined,
    [TRANSACTION_FIELDS.amount]: undefined,
    [TRANSACTION_FIELDS.note]: undefined
}

