export const BUDGET_FIELDS = {
    id: '_id',
    dateRange: 'date_range',
    categoryId: 'category_id',
    value: 'value',
    realValue: 'real_value',
    isRepeat: 'is_repeat'
}

export const BLANK_BUDGET = {
    [BUDGET_FIELDS.dateRange]: undefined,
    [BUDGET_FIELDS.categoryId]: undefined,
    [BUDGET_FIELDS.value]: undefined,
    [BUDGET_FIELDS.isRepeat]: false
}