import { loadGoogleSignInScript } from "services/google_services/googleLogin";
import { isErrorWithMessage, isFetchBaseQueryError } from "utils/helpers";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { setLogin } from "store/auth";

const useGoogleLoginController = () => {
    const dispatch = useDispatch();
    const handleCredentialResponse = async (response, requestServerCallBack) => {
        if (!response) return
        await requestServerCallBack({ accessToken: response.credential })
    };

    const handleServerResponse = (serverData, error) => {
        if (serverData && !error) {
            const userData = serverData.metadata;
            dispatch(setLogin({
                userId: userData.user.userId,
                email: userData.user.email,
                name: userData.user.name,
                accessToken: userData.tokens.accessToken,
                refreshToken: userData.tokens.refreshToken
            }))
        }
        if (error) {
            if (isFetchBaseQueryError(error)) {
                const errorMessage = error.data ? error.data.message : error.error
                toast.error(errorMessage)
            }
            if (isErrorWithMessage(error)) {
                toast.error(error.message)
            }
        }
    }

    const loadGoogleAuthScript = (responseCallBack) => {
        loadGoogleSignInScript(responseCallBack);
    }

    return {
        loadGoogleAuthScript,
        handleCredentialResponse,
        handleServerResponse
    };



}

export default useGoogleLoginController