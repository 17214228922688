import Modal from "components/core/Modal";
import { CATEGORY_FIELDS } from "configs/category.config";
import { useEffect, useState } from "react";
import plusMinusIcon from "assets/img/plus-minus.svg"
import arrowIcon from "assets/img/arrow-icon.svg"
import childrenIcon from 'assets/img/children-icon.svg'

const CategoryReadOnlyModal = ({ catItem, renderList, isOnOpen, onClose, onEditChildCat }) => {
    const [isModalOpen, setIsModalOpen] = useState(isOnOpen)
    const [childCats, setChildCats] = useState();

    useEffect(() => {
        if (catItem) {
            setChildCats(() => {
                let childCatsArr = renderList.filter((cat) => {
                    return cat[CATEGORY_FIELDS.parentCatId] === catItem[CATEGORY_FIELDS.id]
                })
                if (childCatsArr)
                    return childCatsArr
            })
        }

    }, [catItem, renderList])


    useEffect(() => {
        setIsModalOpen(isOnOpen);
    }, [isOnOpen])

    const closeModal = () => {
        onClose()
        setIsModalOpen(false)
    }
    const handleClickChild = (e, cat) => {
        e.preventDefault()
        onEditChildCat(cat);
    }
    return (
        <Modal
            isOpen={isModalOpen}
            onClose={closeModal}
            className='p-5'
        >
            <div className="flex flex-col gap-5">
                <div className="flex flex-row gap-5 justify-left items-center min-w-64 text-lg">
                    <div className="rounded-full flex justify-center items-center bg-stone-300 h-11 w-11">
                        <img src={catItem && catItem[CATEGORY_FIELDS.iconDir]} className="h-8" alt="icon" />
                    </div>
                    <p className="bg-transparent outline-none placeholder:text-stone-500 min-w-64 text-lg" >{catItem && catItem[CATEGORY_FIELDS.name]}</p>
                </div>
                <div className="flex flex-row gap-5 justify-left items-center min-w-64 opacity-80">
                    <div className="rounded-full flex justify-center items-center h-11 w-11 ">
                        <img src={plusMinusIcon} className="h-8" alt="icon" />
                    </div>
                    <p className="text-lg">{catItem ? catItem[CATEGORY_FIELDS.type] : ''}</p>
                </div>
                {childCats && childCats.length !== 0 &&
                    <div className="flex flex-wrap gap-1 justify-left items-center max-w-[500px] ">
                        <div className="flex justify-center items-center h-11 w-11 mr-5">
                            <img src={childrenIcon} className="h-7" alt="icon" />
                        </div>
                        <span className="flex self-center">Children</span>
                        <img src={arrowIcon} className="h-5 self-center ml-2" alt="category parent" />
                        {
                            childCats && childCats.map((cat) => {
                                return <span onClick={(e) => handleClickChild(e, cat)} key={cat[CATEGORY_FIELDS.id]} type="text" className="mx-1 bg-gray-700 p-2 rounded-md cursor-pointer">{cat[CATEGORY_FIELDS.name]}</span>
                            })
                        }
                    </div>}
            </div>
        </Modal>
    );
}

export default CategoryReadOnlyModal;