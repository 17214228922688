import logo from "assets/img/logo.png"
import BgImage from "components/core/BgImage";
import Text from "components/core/Text";
import ChoiceSeparation from "components/core/ChoiceSeparation";
import FormAccessContainer from "components/access-page/FormAccessContainer";
import HeaderFormAccessContainer from "components/access-page/HeaderFormAccessContainer";
import SignUpForm from "components/access-page/SignUpForm";
import Link from "components/core/Link";
import { useNavigate } from "react-router-dom";
import GoogleLoginButton from "components/access-page/GoogleLoginButton";
import Footer from "components/layouts/Footer";

const SignUp = () => {

    const navigate = useNavigate();
    const moveToLogin = () => {
        navigate('/login');
    }
    return (
        <main className="bg-stone-200 p-0 m-0 flex flex-col h-full ">
            <div className=" text-stone-900 flex flex-col justify-center items-center ml-auto mr-auto h-full">
                <FormAccessContainer className='pb-2'>
                    <HeaderFormAccessContainer classCss="flex flex-col justify-center items-center">
                        <div className="mt-9 mb-3" >
                            <BgImage
                                url={logo}
                                height="100px"
                                width="100px"
                                size='contain'
                            />
                        </div>
                        <div className="mt-0 mx-10 mb-3">
                            <Text
                                className='text-accessSecondaryText text-[16px] font-semibold leading-5'
                            >
                                Sign up to love and dating your money.
                            </Text>
                        </div>
                        <div className="py-2 px-9 w-full ">
                            <GoogleLoginButton />
                        </div>
                        <div className="py-3 px-10 w-full">
                            <ChoiceSeparation />
                        </div>
                    </HeaderFormAccessContainer>
                    <SignUpForm className='flex flex-col w-full px-10 my-2 justify-center'>
                        <Text className=' text-accessSecondaryText text-[12px] mt-2'>
                            Users of our financial management service may have shared their financial data with us. <Link url="/" className='text-accessLink'>Learn More</Link>
                            <span className='block my-2'>
                                By signing up, you agree to our <Link url="/terms" className='text-accessLink'>Terms</Link>, <Link url="/privacy" className='text-accessLink'>Privacy Policy</Link>
                            </span>
                        </Text>
                    </SignUpForm>

                </FormAccessContainer>
                <div className="w-full text-accessPrimaryText flex flex-row justify-center round-[1px] py-3 bg-accessPrimaryBackground border-solid border-stone-300 border my-3 ">
                    <p className="w-full text-sm text-center m-4">Have an account? <span onClick={moveToLogin} className='inline text-sm font-semibold text-accessPrimaryButton cursor-pointer'>Log in</span></p>

                </div>
            </div>
            <Footer className="text-accessSecondaryText" />
        </main>
    );
}

export default SignUp;