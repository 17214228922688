import Button from "components/core/Button";
import AccessInput from "components/access-page/AccessInput";
import { useEffect } from "react";

import { useLogInUserMutation } from "services/api/AccessService";
import useAccessFormController from "hooks/access/useAccessFormController";

import { LoginFields } from "configs/access.config";

const LogInForm = ({ className, children }) => {
    const [logInUser, { data: logInData, isLoading: isLoginLoading, isSuccess: isLogInSuccess, error: loginError }] = useLogInUserMutation();
    const { isCanSubmit, isLoading, handleCheckValid, handleOnAccessInputChange, handleOnSubmit, handleError, handleUserData } = useAccessFormController();

    useEffect(() => {
        isLogInSuccess && handleUserData(logInData)
        loginError && handleError(loginError)
    }, [logInData, isLogInSuccess, loginError])

    return (
        <form onSubmit={(e) => handleOnSubmit(e, logInUser)} className={className} method="POST">
            {LoginFields.map((field) => {
                return (
                    <AccessInput
                        key={field.label}
                        label={field.label}
                        labelText={field.text}
                        onAccessInputChange={handleOnAccessInputChange}
                        onCheckValid={handleCheckValid}
                    />
                )
            })}
            {children}
            <Button
                type='submit'
                disabled={!isCanSubmit || isLoginLoading}
                className='bg-accessPrimaryButton text-accessTextWhite py-[7px] px-[16px] my-3'>
                {isLoading ? 'Logging...' : 'Log in'}
            </Button>
        </form>
    );
}

export default LogInForm;