const Button = ({
    className,
    children,
    onClick,
    disabled,
    ...props
}) => {

    className = `w-full text-[14px] font-semibold cursor-pointer text-center border-none rounded-lg disabled:opacity-[0.7] disabled:cursor-auto  ${className} `;

    return (
        <button disabled={disabled} className={className} onClick={onClick} {...props}>
            {children}
        </button>
    );
}

export default Button;