import Modal from "components/core/Modal";
import { CATEGORY_FIELDS } from "configs/category.config";
import { useEffect, useState } from "react";
import CategoryIconSelection from "components/categories-page/CategoryIconSelection";
import { useUpdateCategoryMutation } from "services/api/CategoryService";
import { containsEmptyStringOrNaN, findObjByAttr, getChangedKeys, isEmptyObj, removeKey, toastError } from "utils/helpers";

import toast from "react-hot-toast";
import plusMinusIcon from "assets/img/plus-minus.svg"
import parentCategoryIcon from "assets/img/parent-category.svg"
import arrowIcon from "assets/img/arrow-icon.svg"
import CategorySelectionOnType from "./CategorySelectionOnType";
import clearIcon from 'assets/img/clear-icon.svg'
import childrenIcon from 'assets/img/children-icon.svg'

const CategoryUpdateModal = ({ catItem, renderList, isOnOpen, onClose, onUpdated, onEditChildCat }) => {
    const [updateCategory, { data: updatedCat, isSuccess: updatedSuccess, error: updatedError }] = useUpdateCategoryMutation();
    const [currentCat, setCurrentCat] = useState(catItem)
    const [isModalOpen, setIsModalOpen] = useState(isOnOpen)
    const [selectedIcon, setSelectedIcon] = useState()
    const [childCats, setChildCats] = useState();
    const [catName, setCatName] = useState();
    const [parentCat, setParentCat] = useState({
        name: null,
        id: null
    });
    const [formData, setFormData] = useState({});
    const [initialData, setInitialData] = useState(catItem);
    const [isCanSubmit, setIsCanSubmit] = useState(false)
    const [isDataChanged, setIsDataChanged] = useState(false)


    useEffect(() => {
        if (catItem) {
            setCurrentCat(catItem)
            setCatName(catItem[CATEGORY_FIELDS.name])
            setSelectedIcon(catItem[CATEGORY_FIELDS.iconDir])

            if (catItem[CATEGORY_FIELDS.parentCatId]) {
                const parentCat = findObjByAttr(renderList, CATEGORY_FIELDS.id, catItem[CATEGORY_FIELDS.parentCatId])
                if (parentCat) {
                    setParentCat({
                        id: catItem[CATEGORY_FIELDS.parentCatId],
                        name: parentCat[CATEGORY_FIELDS.name]
                    })
                }
            }
            setChildCats(() => {
                let childCatsArr = renderList.filter((cat) => {
                    return cat[CATEGORY_FIELDS.parentCatId] === catItem[CATEGORY_FIELDS.id]
                })
                if (childCatsArr)
                    return childCatsArr
            })

        } else {
            setCatName()
            setSelectedIcon()
            setParentCat({
                id: null,
                name: null
            })
        }

    }, [catItem, renderList])

    useEffect(() => {
        setFormData((prev) => {
            return {
                ...prev,
                [CATEGORY_FIELDS.name]: catName,
                [CATEGORY_FIELDS.iconDir]: selectedIcon,
                [CATEGORY_FIELDS.parentCatId]: parentCat.id
            }

        })
    }, [catName, selectedIcon, parentCat])

    useEffect(() => {
        if (currentCat) {
            const newFormData = JSON.parse(JSON.stringify(currentCat));
            const initialData = JSON.parse(JSON.stringify(currentCat));
            setFormData(newFormData);
            setInitialData(initialData);
        }
    }, [currentCat])

    useEffect(() => {
        if (formData && initialData) {
            setIsCanSubmit(!containsEmptyStringOrNaN(removeKey(formData, ['is_owe_type', 'parent_category'])));
            const updatedData = getChangedKeys(formData, initialData);
            setIsDataChanged(!isEmptyObj(updatedData));
        }
    }, [initialData, formData]);

    useEffect(() => {
        if (updatedError) {
            toastError(updatedError);
        }
        if (updatedSuccess) {
            toast.success('Category updated!')
            onUpdated(updatedCat.metadata)
        }
    }, [updatedError, updatedCat])


    useEffect(() => {
        setIsModalOpen(isOnOpen);
    }, [isOnOpen])

    const clearParentCat = () => {
        setParentCat({
            id: null,
            name: null
        })
    }

    const handleOnSubmit = async (e) => {
        e.preventDefault();
        if (isCanSubmit && isDataChanged) {
            const payload = getChangedKeys(formData, initialData);
            await updateCategory({
                catId: catItem[CATEGORY_FIELDS.id],
                ...payload
            })
        }
    }

    const handleOnSelectCat = (id, name) => {
        setParentCat({ id, name })
    }
    const handleNameChange = (e) => {
        setCatName(e.target.value);
    }
    const handleOnSelectIcon = (icon) => {
        setSelectedIcon(icon);
    }
    const closeModal = () => {
        setCurrentCat();
        onClose()
        setIsModalOpen(false)
    }
    const handleClickChild = (e, cat) => {
        e.preventDefault()
        onEditChildCat(cat);
    }
    return (
        currentCat &&
        <Modal
            isOpen={isModalOpen}
            onClose={closeModal}
            isForm
            handleOnSave={handleOnSubmit}
            isDataChanged={isDataChanged}
            isCanSubmit={isCanSubmit}

        >
            <div className="flex flex-col gap-5">
                <div className="flex flex-row gap-5 justify-left items-center min-w-64 text-lg">
                    <CategoryIconSelection
                        defaultSelectedIcon={currentCat[CATEGORY_FIELDS.iconDir]}
                        onSelectIcon={icon => handleOnSelectIcon(icon)}
                    />
                    <input className="bg-transparent outline-none placeholder:text-stone-500 min-w-64 text-lg border-none outline-none:focus" placeholder="Enter name" type="text" value={catName} onChange={e => handleNameChange(e)} maxLength='30' />
                </div>
                <div className="flex flex-row gap-5 justify-left items-center min-w-64 opacity-80">
                    <div className="rounded-full flex justify-center items-center h-11 w-11 ">
                        <img src={plusMinusIcon} className="h-8" alt="icon" />
                    </div>
                    <p className="text-lg">{currentCat[CATEGORY_FIELDS.type]}</p>
                </div>
                <div className="flex flex-row gap-5 justify-left items-center min-w-64 ">
                    <div className="rounded-full flex justify-center items-center h-11 w-11" >
                        <img src={parentCategoryIcon} className="h-7" alt="icon" />
                    </div>
                    <div className="flex flex-row item-center">
                        <span className="flex self-center">Parent</span>
                        <img src={arrowIcon} className="h-5 flex self-center ml-2" alt="category parent" />
                        <CategorySelectionOnType
                            catSelectedName={parentCat.name}
                            onSelectCat={(id, name) => handleOnSelectCat(id, name)}
                            renderList={renderList}
                            currentCatId={currentCat[CATEGORY_FIELDS.id]}
                            isParentType
                        />
                        <button disabled={!parentCat.name} className="flex self-center bg-stone-900 p-1 rounded-md opacity-80 cursor-pointer disabled:cursor-default disabled:opacity-50" onClick={clearParentCat}>
                            <img src={clearIcon} className="h-7" alt="clear parent" />
                        </button>
                    </div>
                </div>
                {childCats && childCats.length !== 0 &&
                    <div className="flex flex-wrap gap-1 justify-left items-center max-w-[500px] ">
                        <div className="flex justify-center items-center h-11 w-11 mr-5">
                            <img src={childrenIcon} className="h-7" alt="icon" />
                        </div>
                        <span className="flex self-center">Children</span>
                        <img src={arrowIcon} className="h-5 self-center ml-2" alt="category parent" />
                        {
                            childCats && childCats.map((cat) => {
                                return <span onClick={(e) => handleClickChild(e, cat)} key={cat[CATEGORY_FIELDS.id]} type="text" className="mx-1 bg-gray-700 p-2 rounded-md cursor-pointer">{cat[CATEGORY_FIELDS.name]}</span>
                            })
                        }
                    </div>}
            </div>
        </Modal>
    );
}

export default CategoryUpdateModal;