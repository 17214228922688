import Button from "components/core/Button";
import Modal from "components/core/Modal";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDeleteTransactionsMutation } from "services/api/TransactionService";
import { useListWalletsQuery } from "services/api/WalletService";
import { toastError } from "utils/helpers";

const TransactionDeleteModal = ({ transactionIds, isOnOpen, onClose, onDeleted }) => {
    const { refetch } = useListWalletsQuery();
    const [isModalOpen, setIsModalOpen] = useState(isOnOpen)
    const [transactionList, setTransactionList] = useState([]);
    const [deleteTransactions, { isSuccess, error }] = useDeleteTransactionsMutation();

    const closeModal = (e) => {
        e.preventDefault();
        onClose();
        setIsModalOpen(false)
    }

    const handleDelete = async (transactionList) => {
        await deleteTransactions({ ids: transactionList })
    }

    useEffect(() => {
        if (error) {
            toastError(error);
        }
        if (isSuccess) {
            toast.success('transaction deleted!')
            refetch();
            onDeleted(transactionIds)
            onClose()
            setIsModalOpen(false)
        }
    }, [error, isSuccess])

    useEffect(() => {
        setTransactionList(transactionIds);
    }, [transactionIds])

    useEffect(() => {
        setIsModalOpen(isOnOpen)
    }, [isOnOpen])

    return (
        <Modal
            isOpen={isModalOpen}
            onClose={closeModal}
            className='max-h-1/2 max-w-1/2 h-[15rem] w-[35rem] flex flex-col gap-2 items-center pt-4 px-4'
        >
            <div className="flex flex-col gap-5 justify-left min-w-64 w-full  ">
                <span className="text-left leading-7 w-full">Are you sure to delete {transactionList.length} transactions
                </span>
            </div>
            <div className=" absolute w-full bottom-5 flex flex-col justify-center items-center gap-2 px-5">
                <Button onClick={(e) => handleDelete(transactionList)} className='bg-red-700 px-3 py-2'>Delete All Transactions</Button>
                <Button type='button' className='bg-stone-600 px-3 py-2' onClick={closeModal}>Cancel</Button>
            </div>
        </Modal>
    );
}

export default TransactionDeleteModal;