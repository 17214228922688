import { Route, Routes, useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';

import Footer from "components/layouts/Footer";
import Sidebar from "components/layouts/Sidebar";
import Dashboard from "components/layouts/Dashboard";
import Transactions from "components/layouts/Transactions";
import Setting from "components/layouts/Setting";
import Categories from "components/layouts/Categories";
import Wallets from "components/layouts/Wallets";
import Budgets from "components/layouts/Budgets";
import { getAuthStored } from 'utils/helpers';
import { useAuthUserController } from 'hooks/access/useAuthUserController';
import { useEffect, useState } from 'react';
import { useAuthUserMutation } from 'services/api/AccessService';
import createIcon from 'assets/img/create-icon.svg'
import TransactionSlidePanel from "components/transaction-page/TransactionSlidePanel";
import { BLANK_TRANSACTION } from "configs/transaction.config";


const InHome = () => {
    const authState = useSelector(state => state.auth)
    const [authUser, { data: authData, error: authError }] = useAuthUserMutation();
    const { handleAuthStored, handleCheckAuth } = useAuthUserController();
    const location = useLocation();
    const authStored = getAuthStored();

    useEffect(() => {
        handleAuthStored(authStored, authUser)
    }, [])

    useEffect(() => {
        handleCheckAuth(authState.isLogin, authData, authStored, authError);
    }, [authState.isLogin, authData, authError])


    const [isPanelOpen, setIsPanelOpen] = useState(false)
    const [currentTransaction, setCurrentTransaction] = useState();
    const [isOnEdit, setIsOnEdit] = useState(false);
    const [newTransaction, setNewTransaction] = useState();


    const handleAddNew = () => {
        setIsPanelOpen(true);
        setIsOnEdit(false);
        setCurrentTransaction(BLANK_TRANSACTION)
    }


    const handleOnClosePanel = () => {
        setIsPanelOpen(false)
        setIsOnEdit(false);
        setCurrentTransaction({})
    }

    const handleOnCreated = (newTransaction) => {
        if (location.pathname === '/transactions') {
            setNewTransaction(newTransaction);
        }
    }



    return (<>
        <Sidebar />
        <main className="bg-stone-800 flex w-full xl:justify-end sm:justify-center xl:pl-0 lg:pl-[200px] sm:pl-[150px] phone:justify-center min-h-screen">
            <div className="relative flex flex-col xl:w-4/5 lg:w-full w-full items-center">
                <Routes>
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/transactions" element={<Transactions newTransaction={newTransaction} onAdded={() => setNewTransaction()} />} />
                    <Route path="/setting" element={<Setting />} />
                    <Route path="/categories" element={<Categories defaultActiveType='expense' />} />
                    <Route path="/wallets" element={<Wallets />} />
                    <Route path="/budgets" element={<Budgets />} />
                </Routes>
                <Footer className='text-stone-200 absolute bottom-0' />
            </div>
            <div className="absolute right-10 bottom-10">
                <button className="p-3 bg-green-500 rounded-[50%] opacity-80" onClick={handleAddNew}>
                    <img className="h-8" src={createIcon} alt="" />
                </button>
            </div>
            <TransactionSlidePanel
                isOpen={isPanelOpen}
                onClose={handleOnClosePanel}
                currentTransaction={currentTransaction}
                onCreated={handleOnCreated}
                isOnEdit={isOnEdit}
            />
        </main>
    </>

    );
}

export default InHome;