import { Routes, Route, useLocation } from 'react-router-dom'
import SignUp from 'pages/SignUp';
import InHome from 'pages/InHome';
import Home from 'pages/Home';
import LogIn from 'pages/LogIn';
import ForgotPassword from 'pages/ForgotPassword'
import ResetPassword from 'pages/ResetPassword';
import Privacy from 'pages/Privacy';
import Terms from 'pages/Term';
import ReactGA from 'react-ga4';
import { useEffect } from 'react';

function App() {
  const location = useLocation();
  useEffect(() => {
    ReactGA.send(
      {
        hitType: "pageview",
        page: location.pathname + location.search,
        debug_mode: true
      });
  }, [location]);
  return (
    <Routes>
      <Route exact path='*' element={<InHome />} />
      <Route path='/' element={<Home />} />
      <Route path='/signup' element={<SignUp />} />
      <Route path='/login' element={<LogIn />} />
      <Route path='/forgot-password' element={<ForgotPassword />} />
      <Route path='/reset-password' element={<ResetPassword />} />
      <Route path='/privacy' element={<Privacy />} />
      <Route path='/terms' element={<Terms />} />
    </Routes>
  );
}

export default App;
