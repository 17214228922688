import Button from "components/core/Button";
import AccessInput from "components/access-page/AccessInput";
import useAccessFormController from "hooks/access/useAccessFormController";
import { useForgotPasswordMutation } from "services/api/AccessService";
import { useEffect } from "react";
import toast from "react-hot-toast";
import { toastError } from "utils/helpers";


const ForgotPassForm = ({ className, children }) => {
    const { isCanSubmit, handleOnAccessInputChange, handleCheckValid, handleOnSubmit } = useAccessFormController()
    const [forgotPassword, { isLoading: isSendingMail, isSuccess: isSent, isError: isSentFail, error }] = useForgotPasswordMutation();

    useEffect(() => {
        if (isSent) {
            toast.success('Sent success! Please check your email');
        }

    }, [isSent])

    useEffect(() => {
        if (isSentFail) {
            toastError(error);
        }
    }, [error])

    return (
        <form onSubmit={(e) => handleOnSubmit(e, forgotPassword)} className={className} method="POST">
            <AccessInput
                label='email'
                labelText='Your Email'
                onAccessInputChange={handleOnAccessInputChange}
                onCheckValid={handleCheckValid}
            />
            {children}
            <Button
                type='submit'
                disabled={!isCanSubmit || isSendingMail}
                className='bg-accessPrimaryButton text-accessTextWhite py-[7px] px-[16px] my-3'>
                {isSendingMail ? 'Sending...' : 'Send login link'}
            </Button>
        </form>
    );
}

export default ForgotPassForm;