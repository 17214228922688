import cashIcon from 'assets/img/cash-icon.svg'
import creditIcon from 'assets/img/credit-icon.svg'
import goalIcon from 'assets/img/goal-money-icon.svg'
import Modal from 'components/core/Modal';
import { useEffect, useState } from 'react';


const WalletTypeSelection = ({ isOnOpen, onClose, onSelectType }) => {
    const [isModalOpen, setIsModalOpen] = useState(isOnOpen);

    const handleSelect = (type) => {
        onSelectType(type);
        onClose()
        closeModal()
    }

    const closeModal = () => {
        onClose();
        setIsModalOpen(false)
    }

    useEffect(() => {
        setIsModalOpen(isOnOpen)
    }, [isOnOpen])

    return (
        <Modal
            className='z-10'
            isOpen={isModalOpen}
            onClose={closeModal}
        >
            <div className='mt-5 ml-10'>
                Choose a wallet type
            </div>
            <div className='flex flex-wrap justify-center gap-5 p-10'>
                <div className='bg-lime-700 cursor-pointer p-2 h-auto rounded-lg' onClick={e => handleSelect('basic')}>Basic
                    <img src={cashIcon} alt="" className='w-[150px]' />
                </div>
                <div className='bg-amber-700 cursor-pointer p-2 h-auto rounded-lg' onClick={e => handleSelect('credit')} >Credit
                    <img src={creditIcon} alt="" className='w-[150px]' />
                </div>
                <div className='bg-blue-600 cursor-pointer p-2 h-auto rounded-lg' onClick={e => handleSelect('goal')}>Goal
                    <img src={goalIcon} alt="" className='w-[150px]' />
                </div>
            </div>
        </Modal>
    );
}

export default WalletTypeSelection;