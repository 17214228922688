import Footer from "components/layouts/Footer";
import Header from "components/layouts/Header";

const Privacy = () => {
    return (
        <main className="bg-stone-200 p-0 m-0 flex flex-col">
            <Header />
            <div className="text-accessSecondaryText flex flex-col justify-center items-center ml-auto mr-auto ">
                <div className=" p-6  shadow-lg rounded-lg">
                    <h1 className="text-2xl font-bold mb-4">Privacy Policy</h1>
                    <p className="text-sm text-gray-600 mb-2">Effective date: 6/8/2024</p>

                    <h2 className="text-xl font-semibold mb-2">Introduction</h2>
                    <p className="mb-4">Our app is committed to protecting your privacy. This Privacy Policy explains how your personal information is collected, used, and disclosed by our app.</p>

                    <h2 className="text-xl font-semibold mb-2">Information We Collect</h2>
                    <p className="mb-4">We collect information you provide directly to us, such as when you create or modify your account, request services, or communicate with us. The types of information we may collect include your name, email address, and any other information you choose to provide.</p>

                    <h2 className="text-xl font-semibold mb-2">How We Use Information</h2>
                    <p className="mb-4">We use the information we collect to provide, maintain, and improve our services, such as:</p>
                    <ul className="list-disc list-inside mb-2">
                        <li>Providing and delivering the services you request</li>
                        <li>Sending you technical notices, updates, security alerts, and support messages</li>
                        <li>Responding to your comments, questions, and requests</li>
                    </ul>

                    <h2 className="text-xl font-semibold mb-2">Sharing of Information</h2>
                    <p className="mb-4">We do not share your personal information with third parties except as described in this Privacy Policy. We may share information about you as follows:</p>
                    <ul className="list-disc list-inside mb-2">
                        <li>With your consent or at your direction</li>
                        <li>With service providers who need access to such information to carry out work on our behalf</li>
                        <li>To comply with laws or respond to lawful requests and legal process</li>
                    </ul>

                    <h2 className="text-xl font-semibold mb-2">Your Choices</h2>
                    <p className="mb-4">You may update or correct your account information at any time by logging into your account. You can also request to have your information deleted by contacting us at <b>thanhlam5797@gmail.com</b>.</p>

                    <h2 className="text-xl font-semibold mb-2">Contact Us</h2>
                    <p className="mb-4">If you have any questions about this Privacy Policy, please contact us at <b>thanhlam5797@gmail.com</b>.</p>
                </div>
            </div>
            <Footer />
        </main>
    )
}

export default Privacy;