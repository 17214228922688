import Button from "components/core/Button";
import AccessInput from "components/access-page/AccessInput";

import { useSignUpUserMutation } from "services/api/AccessService"
import useAccessFormController from "hooks/access/useAccessFormController";
import { SignUpFields } from "configs/access.config";
import { useEffect } from "react";


const SignUpForm = ({ className, children }) => {
    const [signUpUser, { data: signUpData, isLoading: isSignUpLoading, isSuccess: isSignUpSuccess, error: signUpError }] = useSignUpUserMutation();
    const { isCanSubmit, handleCheckValid, handleOnAccessInputChange, handleOnSubmit, handleUserData, handleError } = useAccessFormController();

    useEffect(() => {
        isSignUpSuccess && handleUserData(signUpData)
        signUpError && handleError(signUpError)
    }, [signUpData, isSignUpSuccess, signUpError])


    return (
        <form onSubmit={(e) => handleOnSubmit(e, signUpUser)} className={className} method="POST">
            {SignUpFields.map((field) => {
                return (
                    <AccessInput
                        key={field.label}
                        label={field.label}
                        labelText={field.text}
                        onAccessInputChange={handleOnAccessInputChange}
                        onCheckValid={handleCheckValid}
                    />
                )
            })}
            {children}
            <Button
                type='submit'
                disabled={!isCanSubmit || isSignUpLoading}
                className='bg-accessPrimaryButton text-accessTextWhite py-[7px] px-[16px] enabled:hover:bg-accessPrimaryButtonHover'>
                {isSignUpLoading ? 'Signing Up...' : 'Sign Up'}
            </Button>
        </form>
    );
}

export default SignUpForm;