import { useEffect, useState } from 'react';
import { useListWalletsQuery } from 'services/api/WalletService';
import BasicWalletModal from 'components/wallets-page/BasicWalletModal';
import CreditWalletModal from 'components/wallets-page/CreditWalletModal';
import GoalWalletModal from 'components/wallets-page/GoalWalletModal';
import { WALLET_FIELDS, BLANK_WALLET } from 'configs/wallet.config';
import BasicWalletCard from 'components/wallets-page/BasicWalletCard';
import CreditWalletCard from 'components/wallets-page/CreditWalletCard';
import GoalWalletCard from 'components/wallets-page/GoalWalletCard';
import createIcon from 'assets/img/create-icon.svg'
import WalletTypeSelection from 'components/wallets-page/WalletTypeSelection';
import DeleteWalletModal from 'components/wallets-page/DeleteWalletModal';


const Wallets = () => {
    const { data, isSuccess } = useListWalletsQuery();
    const [walletEditItem, setWalletEditItem] = useState({});
    const [walletDeleteItem, setWalletDeleteItem] = useState();
    const [renderList, setRenderList] = useState([])
    const [isOpenEditBasic, setIsOpenEditBasic] = useState(false)
    const [isOpenEditCredit, setIsOpenEditCredit] = useState(false)
    const [isOpenEditGoal, setIsOpenEditGoal] = useState(false)
    const [isOnOpenCreateNew, setIsOnOpenCreateNew] = useState(false)
    const [isOnOpenDelete, setIsOnOpenDelete] = useState(false)

    const handleCreateWallet = (type) => {
        switch (type) {
            case 'basic':
                setIsOpenEditBasic(true);
                setWalletEditItem(BLANK_WALLET.basic);
                break;
            case 'credit':
                setIsOpenEditCredit(true)
                setWalletEditItem(BLANK_WALLET.credit);
                break;
            case 'goal':
                setIsOpenEditGoal(true)
                setWalletEditItem(BLANK_WALLET.goal);
                break;
            default:
                break;
        }
    }

    const handleOnCloseEdit = () => {
        setIsOpenEditBasic(false)
        setIsOpenEditCredit(false);
        setIsOpenEditGoal(false)
    }
    const handleOnClickEdit = (e, wallet) => {
        setWalletEditItem(wallet)
        if (wallet.type === 'basic') {
            setIsOpenEditBasic(true);
        }
        if (wallet.type === 'credit') {
            setIsOpenEditCredit(true);
        }
        if (wallet.type === 'goal') {
            setIsOpenEditGoal(true);
        }
    }

    const handleOnClickDelete = (e, wallet) => {
        setWalletDeleteItem(wallet);
        setIsOnOpenDelete(true);
    }

    const handleUpdated = (updatedItem) => {
        setRenderList(prev =>
            prev.map(wallet =>
                wallet[WALLET_FIELDS.id] === updatedItem[WALLET_FIELDS.id] ? updatedItem : wallet
            ))
    }

    const handleCreated = (createdItem) => {
        setRenderList(prev => {
            const newList = [...prev, { ...createdItem }]
            return newList
        }
        )
    }

    const handleDeleted = (walletId) => {
        setRenderList(prev => {
            return prev.filter(item => item[WALLET_FIELDS.id] !== walletId)
        })
    }

    useEffect(() => {
        if (isSuccess) {
            setRenderList(data.metadata)
        }
    }, [isSuccess])
    return (
        <div className="flex flex-col mb-[10rem] w-full p-10 text-stone-200">
            <div className="py-5 flex flex-row justify-between text-stone-200">
                <h3 className="font-bold xl:text-lg phone:text-base">Your wallets</h3>
                <button className='flex flex-row gap-2 items-center p-2 bg-green-700 text-stone-200 outline-none rounded-lg' onClick={(e) => setIsOnOpenCreateNew(true)}>
                    <img src={createIcon} className='h-4' />
                    <span>Add new</span>
                </button>
            </div>
            <div className="text-base bg-gray-600 rounded-md p-5 mb-5">
                <div className="text-stone-900 font-bold">
                    <p>INCLUDED IN TOTAL</p>
                </div>

                <div className="grid xl:grid-cols-3 lg:grid-cols-2 phone:grid-cols-1 gap-5 py-5">
                    {
                        renderList && renderList.map((wallet) => {
                            if (wallet[WALLET_FIELDS.includeInTotal]) {
                                switch (wallet[WALLET_FIELDS.type]) {
                                    case 'basic':
                                        return <BasicWalletCard
                                            key={wallet[WALLET_FIELDS.id]}
                                            walletItem={wallet}
                                            onClickEdit={handleOnClickEdit}
                                            onClickDelete={handleOnClickDelete} />
                                    case 'credit':
                                        return <CreditWalletCard
                                            key={wallet[WALLET_FIELDS.id]}
                                            walletItem={wallet}
                                            onClickEdit={handleOnClickEdit}
                                            onClickDelete={handleOnClickDelete} />
                                    case 'goal':
                                        return <GoalWalletCard
                                            key={wallet[WALLET_FIELDS.id]}
                                            walletItem={wallet}
                                            onClickEdit={handleOnClickEdit}
                                            onClickDelete={handleOnClickDelete} />
                                    default:
                                        return null
                                }
                            }
                        })
                    }
                </div>
            </div>
            <div className="text-base bg-gray-600 rounded-md p-5">
                <div className="text-stone-900 font-bold">
                    <p>EXCLUDED FROM TOTAL</p>
                </div>
                <div className="grid xl:grid-cols-3 lg:grid-cols-2 phone:grid-cols-1 gap-5 py-5">
                    {
                        renderList && renderList.map((wallet) => {
                            if (!wallet[WALLET_FIELDS.includeInTotal]) {
                                switch (wallet[WALLET_FIELDS.type]) {
                                    case 'basic':
                                        return <BasicWalletCard
                                            key={wallet[WALLET_FIELDS.id]}
                                            walletItem={wallet}
                                            onClickEdit={handleOnClickEdit}
                                            onClickDelete={handleOnClickDelete} />
                                    case 'credit':
                                        return <CreditWalletCard
                                            key={wallet[WALLET_FIELDS.id]}
                                            walletItem={wallet}
                                            onClickEdit={handleOnClickEdit}
                                            onClickDelete={handleOnClickDelete} />
                                    case 'goal':
                                        return <GoalWalletCard
                                            key={wallet[WALLET_FIELDS.id]}
                                            walletItem={wallet}
                                            onClickEdit={handleOnClickEdit}
                                            onClickDelete={handleOnClickDelete} />
                                    default:
                                        return null
                                }
                            }

                        })
                    }
                </div>
            </div>
            <BasicWalletModal
                currentWallet={walletEditItem}
                isOnOpen={isOpenEditBasic}
                onClose={handleOnCloseEdit}
                onUpdated={handleUpdated}
                onCreated={handleCreated}
            />
            <CreditWalletModal
                currentWallet={walletEditItem}
                isOnOpen={isOpenEditCredit}
                onClose={handleOnCloseEdit}
                onUpdated={handleUpdated}
                onCreated={handleCreated}
            />
            <GoalWalletModal
                currentWallet={walletEditItem}
                isOnOpen={isOpenEditGoal}
                onClose={handleOnCloseEdit}
                onUpdated={handleUpdated}
                onCreated={handleCreated}
            />
            <WalletTypeSelection
                isOnOpen={isOnOpenCreateNew}
                onClose={() => setIsOnOpenCreateNew(false)}
                onSelectType={handleCreateWallet}
            />
            <DeleteWalletModal
                walletItem={walletDeleteItem}
                isOnOpen={isOnOpenDelete}
                onClose={() => setIsOnOpenDelete(false)}
                onDeleted={handleDeleted}
            />
        </div>
    );
}

export default Wallets;