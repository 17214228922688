import Button from "components/core/Button"
import { useLogInUserMutation } from "services/api/AccessService";
import { useEffect } from "react";
import useAccessFormController from "hooks/access/useAccessFormController";


const DemoLoginButton = () => {

    const [logInUser, { data, isSuccess, error }] = useLogInUserMutation();
    const { handleUserData, handleError } = useAccessFormController();

    const handleClickLogin = async () => {
        await logInUser({ email: 'demo@moneydating.com', password: 'demo@123' });
    }

    useEffect(() => {
        isSuccess && handleUserData(data)
        error && handleError(error)
    }, [isSuccess, data, error])
    return (
        <Button onClick={handleClickLogin} width='100%' className='bg-accessPrimaryButton hover:bg-accessPrimaryButtonHover text-accessTextWhite py-[7px] px-[16px]' >
            Demo Login
        </Button>
    );
}

export default DemoLoginButton;