const Text = ({
    className,
    children
}) => {

    const defaultClassName = 'text-center'

    return (
        <p className={`${className} ${defaultClassName}`}>
            {children}
        </p>
    );
}

export default Text;