const BgImage = ({ url, size = 'cover', width = '150px', height = '250px', bgRepeat = 'no-repeat', className = '', ...props }) => {
    const style = {
        backgroundImage: `url(${url})`,
        backgroundSize: size,
        width,
        height,
        backgroundRepeat: bgRepeat,
        className,
        ...props
    };

    return <div style={style} className={className}></div>;
};

export default BgImage;
