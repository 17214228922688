import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLogoutController } from "hooks/access/useLogoutController";
import { useLogOutMutation } from "services/api/AccessService";


import SidebarItem from "components/access-page/SidebarItem";
import logo from "assets/img/logo.png"
import BgImage from "components/core/BgImage";
import logoText from "assets/img/logo-text.png"
import logoutIcon from "assets/img/logout-icon.svg"
import { SIDEBAR_ITEMS } from "configs/sidebar.config";


const Sidebar = () => {
    const authState = useSelector(state => state.auth)
    const location = useLocation();
    const [activeTab, setActiveTab] = useState('');
    const [isOpen, setIsOpen] = useState(false);

    const [logOut, { data, isSuccess: isLogoutSuccess, error: logoutError }] = useLogOutMutation();
    const { handleLogoutClick, handleLogoutSuccess, handleLogoutError } = useLogoutController();

    useEffect(() => {
        handleLogoutSuccess(isLogoutSuccess)
    }, [data])

    useEffect(() => {
        handleLogoutError(logoutError)
    }, [logoutError])
    useEffect(() => {
        setActiveTab(location.pathname)
    }, [location.pathname]);

    return (
        <>
            <aside className={`fixed sm:w-1/5 h-full xl:px-4 sm:px-1 text-stone-200 bg-stone-950 border-stone-100 sm:block hidden`}>
                <div className="p-4 flex items-center">
                    <BgImage
                        url={logo}
                        height="80px"
                        width="80px"
                        size="contain"
                        className="lg:block sm:hidden"
                    />
                    <BgImage
                        url={logoText}
                        height="40px"
                        width="140px"
                        size="contain"
                    />
                </div>
                <nav>
                    <ul className="md:pl-4 sm:pl-3 mt-4 h-full flex flex-col sm:gap-5">
                        {SIDEBAR_ITEMS.map((item, index) => (
                            <SidebarItem
                                key={index}
                                text={item.text}
                                icon={item.icon}
                                to={item.to}
                                active={item.to === activeTab}
                            />
                        ))}
                    </ul>
                </nav>
                <div className="absolute xl:left-10 sm:left-4 xl:bottom-5 sm:bottom-10">
                    <button
                        onClick={(e) => handleLogoutClick(e, authState.refreshToken, logOut)}
                        className="flex w-full flex-row items-center gap-4 xl:py-5 xl:px-10 sm:px-2 sm:py-2 rounded-md text-stone-100 text-lg bg-stone-600"
                    >
                        <img className="inline max-w-none sm:h-10" src={logoutIcon} alt="Logout" />
                        Logout
                    </button>
                </div>
            </aside>
            <div className={`sm:hidden fixed ${isOpen ? 'bg-stone-950 h-full w-full' : ''} z-50 p-3`}>
                <button
                    onClick={() => setIsOpen(!isOpen)}
                    className="text-stone-200 focus:outline-none"
                >
                    <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16m-7 6h7"}></path>
                    </svg>
                </button>
                <div className={` p-2 text-sm text-stone-200  ${isOpen ? 'block' : 'hidden'}`}>
                    <nav>
                        <ul className="h-full flex flex-col gap-10" onClick={() => setIsOpen(!isOpen)}>
                            {SIDEBAR_ITEMS.map((item, index) => (
                                <SidebarItem
                                    key={index}
                                    text={item.text}
                                    icon={item.icon}
                                    to={item.to}
                                    active={item.to === activeTab}
                                />
                            ))}
                        </ul>
                    </nav>
                    <div className="absolute bottom-3">
                        <button
                            onClick={(e) => handleLogoutClick(e, authState.refreshToken, logOut)}
                            className="flex w-1/2 flex-row items-center mt-10 gap-4 px-2 py-2 rounded-md text-stone-100 text-base"
                        >
                            <img className="inline max-w-none h-8" src={logoutIcon} alt="Logout" />
                            Logout
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Sidebar;