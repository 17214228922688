import { createApi } from "@reduxjs/toolkit/query/react"
import { baseQueryWithReAuth } from "./QueryReAuth"
export const walletApi = createApi({
    reducerPath: 'walletApi',
    baseQuery: baseQueryWithReAuth,
    endpoints: (builder) => ({
        listWallets: builder.query({
            query: () => `/wallet`
        }),
        updateWallet: builder.mutation({
            query: ({ walletId, ...body }) => {
                return {
                    url: `/wallet/${walletId}`,
                    method: 'PATCH',
                    body: body
                }
            },
        }),
        createWallet: builder.mutation({
            query: ({ ...body }) => {
                return {
                    url: `/wallet`,
                    method: 'POST',
                    body: body
                }
            },
        }),
        deleteWallet: builder.mutation({
            query: (walletId) => {
                return {
                    url: `/wallet/${walletId}`,
                    method: 'DELETE'
                }
            },
        }),
    })
})

export const {
    useListWalletsQuery,
    useUpdateWalletMutation,
    useCreateWalletMutation,
    useDeleteWalletMutation
} = walletApi
