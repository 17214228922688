import { useEffect, useRef, useState } from "react";
import dropdownIcon from 'assets/img/dropdown-icon.svg'
import clearIcon from 'assets/img/clear-search-icon.svg'
import { useListExpenseCategoriesQuery, useListIncomeCategoriesQuery } from "services/api/CategoryService";
import { sortCategoryArr } from "utils/helpers";
import { CATEGORY_FIELDS } from "configs/category.config";


const CategoryFilter = ({ onSelectCat }) => {
    const { data: expenseList, isSuccess: isGetExpenseListSuccess } = useListExpenseCategoriesQuery();
    const { data: incomeList, isSuccess: isGetIncomeListSuccess } = useListIncomeCategoriesQuery();
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null)
    const [searchTerm, setSearchTerm] = useState('All categories')
    const [options, setOptions] = useState([]);
    const [selectedCat, setSelectedCat] = useState();
    const [filteredOptions, setFilteredOptions] = useState([]);

    const handleMenuOpen = () => {
        setIsOpen(!isOpen)
    }

    const handleSelectCat = (cat) => {
        setIsOpen(false)
        onSelectCat(cat[CATEGORY_FIELDS.id])
        setSelectedCat(cat)
        setSearchTerm(cat[CATEGORY_FIELDS.name])
    }

    const handleClearCat = () => {
        setSearchTerm('')
        setSelectedCat();
    }

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
                setIsOpen(false)
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    useEffect(() => {
        if (isGetIncomeListSuccess && isGetExpenseListSuccess) {
            const rawData = [...expenseList.metadata, ...incomeList.metadata]
            const listOptions = sortCategoryArr(rawData);
            setOptions([
                {
                    [CATEGORY_FIELDS.id]: null,
                    [CATEGORY_FIELDS.name]: 'All categories'
                },
                {
                    [CATEGORY_FIELDS.id]: -1,
                    [CATEGORY_FIELDS.iconDir]: 'assets/category-icon/other-expense.svg',
                    [CATEGORY_FIELDS.name]: 'All expense'
                },
                {
                    [CATEGORY_FIELDS.id]: 1,
                    [CATEGORY_FIELDS.iconDir]: 'assets/category-icon/other-income.svg',
                    [CATEGORY_FIELDS.name]: 'All income'
                },
                ...listOptions])
        }
    }, [isGetIncomeListSuccess, isGetExpenseListSuccess])

    useEffect(() => {
        if (searchTerm === "") {
            setFilteredOptions(options);
        } else {
            const filtered = options.filter(option =>
                option[CATEGORY_FIELDS.name].toLowerCase().includes(searchTerm.toLowerCase())
            );
            setFilteredOptions(filtered);
        }
    }, [searchTerm, options]);

    return (
        <div ref={dropdownRef} className="relative h-[3rem] flex flex-row gap-2 items-center w-[15rem] max-w-[18rem] bg-stone-950 rounded-md border border-borderTable px-3 py-2 text-sm 
                            focus:outline-stone-200 focus:outline">
            <div className="rounded-full flex justify-center items-center bg-stone-300 h-8 w-8">
                {selectedCat && <img src={selectedCat[CATEGORY_FIELDS.iconDir]} className="inline w-6" />}
            </div>
            <input
                type="text"
                className=" text-stone-200 bg-stone-950 outline-none text-sm"
                placeholder="Search category..."
                onClick={e => handleMenuOpen()}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
            />
            <span className={`absolute right-3 top-[1/2] -translate-y-[1/2] transition-transform ${isOpen ? 'rotate-180' : ''}`}>
                <img src={dropdownIcon} className='h-3' alt="" />
            </span>
            <div className=' absolute h-7 w-[1px] bg-stone-200 top-[1/2] -translate-y-[1/2] right-[35px]'></div>
            {searchTerm &&
                <div className=' absolute bg-stone-200 top-[1/2] -translate-y-[1/2] right-[50px] cursor-pointer' onClick={handleClearCat} >
                    <img src={clearIcon} className='bg-stone-900 h-6' />
                </div>
            }
            {isOpen && <ul className="absolute left-0 top-[3.5rem] w-full bg-stone-950 text-stone-200 border border-stone-700
                max-h-[200px] overflow-y-scroll list-none z-10 rounded py-2">
                {
                    filteredOptions.length === 0 &&
                    <li className="flex flex-row text-sm justify-left items-center px-3 py-2
                         rounded-sm cursor-pointer hover:bg-stone-700 h-[3.5rem]">Not found</li>
                }
                {filteredOptions.map((option, index) =>
                    <li
                        key={index}
                        className="flex flex-row text-sm justify-left items-center px-3 py-2
                         rounded-sm cursor-pointer hover:bg-stone-700 h-[3.5rem]"
                        onClick={(e) => handleSelectCat(option)}
                    >
                        {option[CATEGORY_FIELDS.parentCatId] &&
                            <div className="w-8 relative h-full">
                                <div className="absolute w-4 h-1/2 top-0 right-0 border-b border-l border-stone-200">
                                </div>
                            </div>
                        }
                        <div className="rounded-full flex justify-center items-center bg-stone-300 h-10 w-10">
                            <img src={option[CATEGORY_FIELDS.iconDir]} className="inline w-7" />
                        </div>

                        <div className='flex flex-col ml-5'>
                            <span >{option[CATEGORY_FIELDS.name]}</span>
                        </div>
                    </li>
                )}
            </ul>}
        </div>
    );
}

export default CategoryFilter
