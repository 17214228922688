import Footer from "components/layouts/Footer";
import Header from "components/layouts/Header";


const Home = () => {
    return (
        <main className="bg-stone-200 p-0 m-0 flex flex-col">
            <Header />
            <div className=" text-stone-900 flex flex-col justify-center items-center ml-auto mr-auto h-full mb-2 pb-11 w-full">
                <div className=" lg:pt-[20px] lg:mb-[24px] lg:h-full w-full">
                    <div className="md:px-[14px] lg:px-[44px]">
                        <h1
                            className="tracking-tight lg:text-[64px] lg:leading-[83px] font-medium text-[36px] text-green-500 leading-[52px]  mb-1 text-center">
                            Simple way</h1>
                        <div
                            className="font-ibm text-[36px] text-stone-900 leading-[48px] lg:leading-[56px] lg:text-[40px] text-center">
                            <h1
                                className="tracking-tight lg:text-[64px] lg:leading-[83px] text-stone-900 font-light text-[36px] leading-[48px] lg:inline">
                                to manage </h1>
                            <h1
                                className="tracking-tight lg:text-[64px] lg:leading-[83px] font-medium text-[36px] leading-[48px] lg:inline text-green-500">
                                personal finances</h1>
                        </div>
                        <div className="flex gap-x-4 justify-center mt-9 lg:mt-12 cursor-pointer font-bold text-stone-200 ">
                            <a href="/login"
                                className=" bg-green-500 px-[40px] py-[14px] text-base align-middle lg:w-full flex items-center justify-center border border-[#2db84c] rounded-lg max-h-12 max-w-[196px]">Login
                                for free</a>
                        </div>
                        <div
                            className="lg:invisible fixed bg-monochrome-white bottom-0 inset-x-0 z-10 flex justify-around invisible">
                            <div className="grid grid-cols-2 gap-2 mx-4 my-4"><a href="https://web.moneylover.me" target="_blank"
                                rel="noopener noreferrer" className="button-ml text-16 md:w-full">Try on browser</a><a
                                    href="#cta" className="button-ml-primary bg-green-ml text-16 md:w-full">Download for free</a>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="container mt-14 lg:mt-[44px]">
                    <figure
                        className="h-[400px] grid grid-cols-8 lg:grid-cols-9 items-center justify-items-start gap-y-4 lg:gap-x-12 lg:gap-y-0 pb-5 lg:py-0">
                        <figcaption
                            className="col-span-full lg:col-span-5 lg:col-start-7 lg:place-self-center lg:self-center lg:order-2">
                            <h3 className="text-[26px] tracking-tight font-medium lg:leading-[52px] leading-[32px] text-stone-900">
                                All insight in one place</h3>
                            <p className="mt-2 text-stone-800">It takes seconds to record finances static. Include income, expense, balance with simple sorting in date follow wallets and categories</p>
                        </figcaption>
                        <div className="lg:order-1 col-span-full lg:col-span-5 lg:col-start-2 lg:place-self-center lg:self-center">
                            <div className="lg:w-11/12 mx-auto">
                                <img className="rounded-2xl " src="/assets/figure1.png" alt="coverage" />
                            </div>
                        </div>
                    </figure>
                    <figure
                        className="h-[400px] grid grid-cols-8 lg:grid-cols-12 items-center justify-items-start gap-y-4 lg:gap-x-12 lg:gap-y-0 pb-14 lg:py-0">
                        <figcaption className="col-span-full lg:col-span-5 lg:col-start-2 lg:place-self-center lg:self-center">
                            <h3 className="text-[26px] tracking-tight font-medium lg:leading-[52px] leading-[32px] text-stone-900">
                                Simple Money Tracker</h3>
                            <p className="mt-2 text-stone-800">It takes seconds to record a transaction, with full information include date, note, currency, wallet, type of transaction. Simple sorting, search, delete,..</p>
                        </figcaption>
                        <div className="col-span-full lg:col-span-11 lg:col-start-7 lg:place-self-center lg:self-center">
                            <div className=" lg:w-11/12 mx-auto">
                                <img className="rounded-2xl" src="/assets/figure2.png"
                                    alt="risks and tests to run" />
                            </div>
                        </div>
                    </figure>
                    <figure
                        className="h-[400px] grid grid-cols-8 lg:grid-cols-12 items-center justify-items-start gap-y-4 lg:gap-x-12 lg:gap-y-0 pb-14 lg:py-0">
                        <figcaption
                            className="col-span-full lg:col-span-12 lg:col-start-11 lg:place-self-center lg:self-center lg:order-2">
                            <h3
                                className="text-[26px] tracking-tight font-medium lg:leading-[52px] leading-[32px] text-stone-900">
                                Beautiful Category In Your Style</h3>
                            <p className="mt-2 text-stone-800">It takes seconds to record daily transactions. Put them into clear and visualized categories such as Expense: Food, Shopping or Income: Salary, Gift.</p>
                        </figcaption>
                        <div className="lg:order-1 col-span-full lg:col-span-9 lg:col-start-2 lg:place-self-center lg:self-center">
                            <div className="lg:w-11/12 mx-auto">
                                <img className="rounded-2xl" src="/assets/figure3.png"
                                    alt="time savings" /></div>
                        </div>
                    </figure>
                </section>
            </div>
            <Footer className="text-accessSecondaryText text-base" />
        </main>
    );
}

export default Home;