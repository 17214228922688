import { useEffect } from "react";
import Button from "components/core/Button"
import googleIcon from "assets/img/google-icon.svg"
import useGoogleLoginController from "hooks/access/useGoogleLoginController";
import { useLogInGGUserMutation } from "services/api/AccessService";


const GoogleLoginButton = () => {
    const [logInGGUser, { data: serverResponse, error: serverError }] = useLogInGGUserMutation()
    const { handleCredentialResponse, handleServerResponse, loadGoogleAuthScript } = useGoogleLoginController()
    useEffect(() => {
        const onCredentialResponse = (response) => {
            handleCredentialResponse(response, logInGGUser);
        }
        loadGoogleAuthScript(onCredentialResponse);
    }, [])

    useEffect(() => {
        handleServerResponse(serverResponse, serverError);
    }, [serverResponse, serverError])

    return (
        <Button id="signInGoogle" width='100%' className='flex justify-center bg-transparent hover:bg-transparent text-[#385185] py-[7px] px-[16px]' >
            <span >
                <img className=" inline-block w-5 h-5 mr-2" src={googleIcon} alt="icon-fb" />
            </span>
            Log in with Google
        </Button>
    );
}

export default GoogleLoginButton;