
// Import necessary dependencies
import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { setLogin, setLogout } from "store/auth";
import { getAuthStored } from "utils/helpers";

// Define header constants
const HEADER = {
    API_KEY: 'x-api-key',
    CLIENT_ID: 'x-client-id',
    AUTHORIZATION: 'authorization',
    REFRESHTOKEN: 'x-rtoken-id',
};

// Get API URL and key from environment variables
const API_URL = process.env.REACT_APP_API_URL;
const apiKey = process.env.REACT_APP_API_KEY;

// Set up the base query with header preparation
const baseQuery = fetchBaseQuery({
    baseUrl: API_URL,
    prepareHeaders: (headers, { getState }) => {
        const state = getState();
        const { auth } = state;
        if (auth.isLogin) {
            headers.set(HEADER.CLIENT_ID, auth.userProfile.userId);
            headers.set(HEADER.AUTHORIZATION, auth.accessToken);
        } else {
            const authStored = getAuthStored();
            if (authStored) {
                headers.set(HEADER.CLIENT_ID, authStored.userId);
                headers.set(HEADER.AUTHORIZATION, authStored.accessToken);
            }
        }
        headers.set(HEADER.API_KEY, apiKey);
        headers.set("Content-Type", "application/json");
        return headers;
    }
});

let isRefreshing = false;
let pendingRequests = [];

const processPendingRequests = (error, newToken) => {
    pendingRequests.forEach(callback => {
        if (error) {
            callback.reject(error);
        } else {
            callback.resolve(newToken);
        }
    });
    pendingRequests = [];
};

export const baseQueryWithReAuth = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);

    if (result.error && result.error.status === 401) {
        const authStored = getAuthStored();

        if (!isRefreshing) {
            isRefreshing = true;
            const oldHeader = args.headers ? args.headers : {};
            const newQuery = {
                ...args,
                url: '/user/refresh-token',
                method: 'POST',
                headers: {
                    ...oldHeader,
                    [HEADER.REFRESHTOKEN]: authStored.refreshToken
                }
            };

            const refreshResult = await baseQuery(newQuery, api, extraOptions);
            isRefreshing = false;

            if (refreshResult.data) {
                const authData = refreshResult.data.metadata;
                api.dispatch(setLogin({
                    userId: authData.user.userId,
                    userEmail: authData.user.email,
                    userName: authData.user.name,
                    accessToken: authData.tokens.accessToken,
                    refreshToken: authData.tokens.refreshToken
                }));
                processPendingRequests(null, authData.tokens.accessToken);
                result = await baseQuery(args, api, extraOptions);
            } else {
                api.dispatch(setLogout());
                processPendingRequests(refreshResult.error, null);
            }
        } else {
            return new Promise((resolve, reject) => {
                pendingRequests.push({ resolve, reject });
            }).then(() => baseQuery(args, api, extraOptions));
        }
    }

    return result;
};