import { useEffect, useRef, useState } from "react";
import dropdownIcon from 'assets/img/dropdown-icon.svg'
import { WALLET_FIELDS } from "configs/wallet.config";
import basicWalletIcon from 'assets/img/cash-icon.svg'
import creditWalletIcon from 'assets/img/credit-icon.svg'
import goalWalletIcon from 'assets/img/goal-money-icon.svg'

const walletIconMap = {
    'basic': basicWalletIcon,
    'credit': creditWalletIcon,
    'goal': goalWalletIcon
}

const WalletSelector = ({
    walletOptions,
    currentWallet,
    onSelectWallet,
    placeHolder = 'Select a wallet',
    className
}) => {

    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null)
    const [options, setOptions] = useState([]);
    const [selectedWallet, setSelectedWallet] = useState(currentWallet);

    const handleMenuOpen = () => {
        setIsOpen(!isOpen)
    }

    const handleSelectWallet = (wallet) => {
        setIsOpen(false)
        setSelectedWallet(wallet)
        onSelectWallet(wallet)
    }


    useEffect(() => {
        const handleClickOutside = (e) => {
            if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
                setIsOpen(false)
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    useEffect(() => {
        setOptions(walletOptions)
    }, [walletOptions])
    useEffect(() => {
        setSelectedWallet(currentWallet)
    }, [currentWallet])

    return (<div ref={dropdownRef} className={className}>
        <input
            type="text"
            className=" text-stone-200 bg-stone-950 outline-none text-sm"
            placeholder={placeHolder}
            onClick={e => handleMenuOpen()}
            value={selectedWallet[WALLET_FIELDS.name] || ''}
            onChange={(e) => e.preventDefault()}
        />
        <span className={`absolute right-3 top-[20px] transition-transform ${isOpen ? 'rotate-180' : ''}`}>
            <img src={dropdownIcon} className='h-3' alt="" />
        </span>
        <div className=' absolute h-9 w-[1px] bg-stone-200 top-2 right-[35px]'></div>
        {isOpen && <ul className="absolute left-0 top-[3.5rem] w-full bg-stone-900 text-stone-200 border border-stone-700
                max-h-[200px] overflow-y-scroll list-none z-10 rounded py-2">
            {options.map((option, index) =>
                <li
                    key={index}
                    className="flex flex-row text-sm justify-left items-center px-3 py-2
                         rounded-sm cursor-pointer hover:bg-stone-700 h-[3.5rem]"
                    onClick={(e) => handleSelectWallet(option)}
                >
                    <div className="rounded-full flex justify-center items-center bg-stone-300 h-10 w-10">
                        <img src={walletIconMap[option.type]} className="inline w-7" />
                    </div>
                    <div className='flex flex-col ml-5'>
                        <span >{option[WALLET_FIELDS.name]}</span>
                    </div>
                </li>
            )}
        </ul>}
    </div>);
}

export default WalletSelector;