import { createSlice } from "@reduxjs/toolkit"
import { getStartEndDateByTimePeriod } from "utils/helpers";

const initialState = {
    name: 'Last 30 days',
    filterDate: getStartEndDateByTimePeriod('Last 30 days')
};

const dateFilterSlice = createSlice({
    name: 'date_filter',
    initialState,
    reducers: {
        setDateFilter: (state, action) => {
            const { name, filterDate } = action.payload;
            if (name) state.name = name;
            if (filterDate) state.filterDate = filterDate
        }
    }
})

export const { setDateFilter } = dateFilterSlice.actions
export default dateFilterSlice.reducer;