import { useEffect, useState } from "react";

const CategoryNavbar = ({ defaultActive, onActiveType }) => {
    const options = [['income', 'Income'], ['expense', 'Expense'], ['owe', 'Debt/Loan']];

    const defaultClassName = 'py-2 px-10 phone:px-5 hover:bg-stone-800 hover:rounded-md w-1/3 text-center';
    const activeClass = `${defaultClassName} bg-stone-800 rounded-md`;

    const [activeType, setActiveType] = useState(defaultActive);

    const handleActiveTab = (type) => {
        setActiveType(type);
        onActiveType(type)
    };

    return (
        <nav className="w-full border-stone-400 flex flex-row items-center lg:gap-3 phone:gap-1 justify-between lg:p-4 phone:p-2 lg:text-base phone:text-sm bg-stone-950 text-stone-200 rounded-lg">
            {options.map(option => (
                <a
                    href="#"
                    className={activeType === option[0] ? activeClass : defaultClassName}
                    onClick={() => handleActiveTab(option[0])}
                    key={option[0]}>
                    {option[1]}
                </a>
            ))}
        </nav>
    );
};

export default CategoryNavbar;