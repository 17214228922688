
const TransactionPagination = ({ currentPage, totalPages, onPageChange }) => {

    const renderPageNumbers = () => {
        const pageNumbers = [];
        const delta = 2;
        const range = [];

        for (let i = Math.max(2, currentPage - delta); i <= Math.min(totalPages - 1, currentPage + delta); i++) {
            range.push(i);
        }

        if (currentPage - delta > 2) {
            range.unshift('...');
        }
        if (currentPage + delta < totalPages - 1) {
            range.push('...');
        }

        range.unshift(1);
        if (totalPages > 1) {
            range.push(totalPages);
        }

        range.forEach((page, index) => {
            pageNumbers.push(
                <li key={index}>
                    <a
                        href="#"
                        onClick={() => handlePageChange(page)}
                        className={`inline-flex text-stone-200 items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:bg-stone-600 h-10 w-10 duration-300 ease-in-out ${currentPage === page
                            ? 'bg-stone-600 scale-105'
                            : ''
                            }`}
                    >
                        {page}
                    </a>
                </li>
            );
        });

        return pageNumbers;
    };

    const handlePageChange = page => {
        if (page !== currentPage && page > 0 && page <= totalPages) {
            onPageChange(page);
        }
    };
    const handlePrevious = () => {
        handlePageChange(currentPage - 1);
    };

    const handleNext = () => {
        handlePageChange(currentPage + 1);
    };

    return (
        <nav
            role="navigation"
            aria-label="pagination"
            className="mx-auto flex w-full justify-center"
        >
            <ul className="flex flex-row items-center gap-1">
                <li>
                    <a
                        className={`inline-flex items-center text-stone-200 justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2  h-10 px-4 py-2 gap-1 pl-2.5 hover:bg-stone-600 duration-300 ease-in-out 
                        ${currentPage == 1 ? 'pointer-events-none opacity-50' : ''}`}
                        aria-label="Go to previous page"
                        href="#"
                        onClick={handlePrevious}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className=" h-4 w-4"
                        >
                            <path d="m15 18-6-6 6-6"></path>
                        </svg>
                        <span>Previous</span>
                    </a>
                </li>
                {renderPageNumbers()}
                <li>
                    <a
                        className={`inline-flex text-stone-200 items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 hover:bg-accent hover:bg-stone-600 h-10 px-4 py-2 gap-1 pr-2.5  duration-300 ease-in-out ${currentPage == totalPages ? 'pointer-events-none opacity-50' : ''}`}
                        aria-label="Go to next page"
                        href="#"
                        onClick={handleNext}
                        disabled={currentPage == totalPages}
                    >
                        <span>Next</span>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="lucide lucide-chevron-right h-4 w-4"
                        >
                            <path d="m9 18 6-6-6-6"></path>
                        </svg>
                    </a>
                </li>
            </ul>

        </nav>
    );
};

export default TransactionPagination;
