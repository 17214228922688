import { CATEGORY_FIELDS } from "configs/category.config";
import { TRANSACTION_FIELDS } from "configs/transaction.config";
import { WALLET_FIELDS } from "configs/wallet.config";
import { useEffect, useState } from "react";
import { useListExpenseCategoriesQuery, useListIncomeCategoriesQuery } from "services/api/CategoryService";
import { useListWalletsQuery } from "services/api/WalletService";
import { formatDate, formatMoneyNumber, getCurrencySymbol } from "utils/helpers";
import threeDotIcon from 'assets/img/threedots-icon.svg'

const CheckmarkSVG = () => (
    <span className="flex items-center justify-center text-current" style={{ pointerEvents: 'none' }}>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="h-4 w-4">
            <path d="M20 6 9 17l-5-5"></path>
        </svg>
    </span>
);
const walletColorMap = {
    'basic': 'bg-lime-800',
    'credit': 'bg-amber-800',
    'goal': 'bg-blue-700'
}
const isExpenseColor = {
    'expense': ['text-rose-200 bg-rose-600 bg-opacity-35'],
    'income': ['text-green-500 bg-green-500 bg-opacity-25']
};

const TransactionRow = ({ transaction, isChecked, toggleSelect, onEdit }) => {
    const { data: expenseList, isSuccess: isGetExpenseListSuccess } = useListExpenseCategoriesQuery();
    const { data: incomeList, isSuccess: isGetIncomeListSuccess } = useListIncomeCategoriesQuery();
    const { data: walletList, isSuccess: isGetWalletListSuccess } = useListWalletsQuery();

    const [categoryList, setCategoryList] = useState();
    const [category, setCategory] = useState();
    const [wallet, setWallet] = useState();


    useEffect(() => {
        if (isGetExpenseListSuccess && isGetIncomeListSuccess) {
            setCategoryList([...expenseList.metadata, ...incomeList.metadata]);
        }
    }, [expenseList, incomeList])

    useEffect(() => {
        if (categoryList) {
            const foundCategory = categoryList.find(item => item[CATEGORY_FIELDS.id] === transaction[TRANSACTION_FIELDS.categoryId]);
            if (foundCategory) setCategory(foundCategory);
        }

        if (walletList && isGetWalletListSuccess) {
            const foundWallet = walletList.metadata.find(item => item[WALLET_FIELDS.id] === transaction[TRANSACTION_FIELDS.walletId])
            if (foundWallet) setWallet(foundWallet);
        }
    }, [walletList, categoryList, transaction])

    return (
        <tr
            className={`w-full border-b border-borderTable hover:bg-stone-900 max-h-[5rem]
                        ${isChecked(transaction[TRANSACTION_FIELDS.id]) ? "bg-stone-900" : "bg-stone-950"}`}>
            <td className="w-4 p-4">
                <button
                    type="button"
                    role="checkbox"
                    className={` h-4 w-4 shrink-0 rounded-sm border border-primary ring-offset-background focus-visible:outline-none focus-visible:ring-2 
                                     focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50
                                     ${isChecked(transaction[TRANSACTION_FIELDS.id]) ? "bg-stone-200 text-stone-900" : ""}`}
                    onClick={() => toggleSelect(transaction[TRANSACTION_FIELDS.id])}
                >
                    {isChecked(transaction[TRANSACTION_FIELDS.id]) ? <CheckmarkSVG /> : ''}
                </button>
            </td>
            <td className="p-3 text-center">
                <div className="w-full whitespace-nowrap">
                    {formatDate(transaction[TRANSACTION_FIELDS.date])}
                </div>
            </td>
            <td className="py-3 px-[1rem] text-center w-[10rem]">
                <div className="flex flex-row items-center justify-left gap-3">
                    <div className="rounded-full flex justify-center items-center bg-stone-300 h-11 w-11">
                        <img src={category && category[CATEGORY_FIELDS.iconDir]} className="h-8" alt="icon" />
                    </div>
                    <div className='text-sm sm:text-center xl:text-left xl:text-base phone:text-sm' >
                        <p>
                            {category && category[CATEGORY_FIELDS.name]}
                        </p>
                    </div>
                </div>
            </td>
            <td className="py-3 text-center">
                <span className={`p-2  rounded ${wallet && walletColorMap[wallet[WALLET_FIELDS.type]]} text-stone-200 `} >{wallet && wallet[WALLET_FIELDS.name]}</span>
            </td>
            <td className="p-3 text-center">
                <span className={`${category && isExpenseColor[category[CATEGORY_FIELDS.type]]} p-2 rounded-[1rem] whitespace-nowrap`}>
                    {transaction[TRANSACTION_FIELDS.amount] > 0 ? '+' : '-'}
                    {wallet && getCurrencySymbol(wallet[WALLET_FIELDS.currency])}
                    {formatMoneyNumber(transaction[TRANSACTION_FIELDS.amount].toString())}
                </span>
            </td>
            <td className="py-2 text-center w-[5rem] ">
                <div className=" w-[5rem] truncate">
                    <span className="text-stone-200">
                        {transaction[TRANSACTION_FIELDS.note]}
                    </span>
                </div>
            </td>

            <td className="p-3">
                <div className="w-full flex items-center justify-center">
                    <div
                        className="p-1 hover:bg-stone-500 rounded-[16px] cursor-pointer"
                        onClick={e => { onEdit(transaction) }}>
                        <img src={threeDotIcon} alt="edit icon" className="h-6" />
                    </div>
                </div>
            </td>
        </tr >
    );
}

export default TransactionRow; 