import Footer from "components/layouts/Footer";
import Header from "components/layouts/Header";

const Terms = () => {
    return (
        <main className="bg-stone-200 p-0 m-0 flex flex-col min-h-full w-full">
            <Header />
            <div className="text-stone-900 flex flex-col justify-center items-center ml-auto mr-auto w-full">
                <div className="w-full p-6  shadow-lg rounded-lg">
                    <h1 className="text-2xl font-bold mb-2">Terms of Service</h1>
                    <p className="text-sm text-gray-600 mb-6">Effective date: 6/8/2024</p>

                    <h2 className="text-xl font-semibold mb-2">Introduction</h2>
                    <p className="mb-4">Welcome to our app. These Terms of Service ("Terms") govern your use of our services.</p>

                    <h2 className="text-xl font-semibold mb-2">Acceptance of Terms</h2>
                    <p className="mb-4">By accessing or using our services, you agree to be bound by these Terms.</p>

                    <h2 className="text-xl font-semibold mb-2">Changes to Terms</h2>
                    <p className="mb-4">We may modify the Terms at any time. We will notify you of any changes by posting the new Terms on our website.</p>

                    <h2 className="text-xl font-semibold mb-2">Use of Services</h2>
                    <p className="mb-4">You agree to use the services only for lawful purposes and in accordance with the Terms.</p>

                    <h2 className="text-xl font-semibold mb-2">Account Termination</h2>
                    <p className="mb-4">We may terminate or suspend your account if you violate any of the Terms.</p>

                    <h2 className="text-xl font-semibold mb-2">Limitation of Liability</h2>
                    <p className="mb-4">Our liability is limited to the maximum extent permitted by law.</p>

                    <h2 className="text-xl font-semibold mb-2">Contact Us</h2>
                    <p className="mb-4">If you have any questions about these Terms, please contact us at <b>thanhlam5797@gmail.com</b>.</p>
                </div>
            </div>
            <Footer />
        </main>
    )
}

export default Terms;