import { useEffect, useState } from "react";
import Modal from "components/core/Modal";
import { CATEGORY_FIELDS } from "configs/category.config";
import { findObjByAttr } from "utils/helpers";
import editIcon from 'assets/img/edit-tiny-icon.svg'


const CategorySelectionOnReplacement = ({ deletedCatId, onSelectCat, renderList, defaultReplacementCatName }) => {

    const [selectedCatName, setSelectedCatName] = useState(defaultReplacementCatName);
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [selectionList, setSelectionList] = useState([])
    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false)
    const handleSelectCat = (catItem) => {
        setSelectedCatName(catItem[CATEGORY_FIELDS.name]);
        onSelectCat(catItem);
        closeModal()
    }
    useEffect(() => {
        setSelectionList(() => {
            return renderList.filter(catItem => {
                return catItem[CATEGORY_FIELDS.id] !== deletedCatId
            })
        })
    }, [deletedCatId])
    useEffect(() => {
        if (!selectedCatName) {
            const defaultReplacementCat = findObjByAttr(renderList, [CATEGORY_FIELDS.name], defaultReplacementCatName)
            setSelectedCatName(defaultReplacementCat[CATEGORY_FIELDS.name])
            onSelectCat(defaultReplacementCat)
        }
    }, [selectedCatName])
    return (<>
        {
            <span type="text" onClick={openModal} className="mx-2 bg-green-700 p-2 rounded-md cursor-pointer">
                {selectedCatName}
                <img src={editIcon} className="inline h-4 mb-1 ml-2" />
            </span>
        }
        <Modal
            className='z-20'
            isOpen={isModalOpen}
            onClose={closeModal}
        >
            <div className="grid grid-cols-3 w-min-[50rem] max-h-[600px] gap-4 p-10 overflow-y-auto ">
                {
                    selectionList.map(catItem => {
                        return <div key={catItem[CATEGORY_FIELDS.id]} className="flex flex-row justify-left items-center gap-2 bg-stone-900 rounded-lg cursor-pointer p-3" onClick={() => handleSelectCat(catItem)}>
                            <div className="rounded-full flex justify-center items-center bg-stone-300 h-14 w-14 ">
                                <img src={`/${catItem[CATEGORY_FIELDS.iconDir]}`} className="h-10" alt="icon" />
                            </div>
                            <span className="bg-transparent outline-none placeholder:text-stone-200 text-base" type="text">{catItem[CATEGORY_FIELDS.name]}</span>
                        </div>
                    }
                    )
                }
            </div>
        </Modal>
    </>);
}

export default CategorySelectionOnReplacement;