import createIcon from "assets/img/create-icon.svg"
import Modal from "components/core/Modal";
import { useState, useEffect, useRef } from "react";
import CategoryIconSelection from "./CategoryIconSelection";
import { useCreateCategoryMutation } from "services/api/CategoryService";
import toast from "react-hot-toast";
import { toastError } from "utils/helpers";
import plusMinusIcon from "assets/img/plus-minus.svg"
import parentCategoryIcon from "assets/img/parent-category.svg"
import arrowIcon from "assets/img/arrow-icon.svg"
import CategorySelectionOnType from "./CategorySelectionOnType";
import clearIcon from 'assets/img/clear-icon.svg'
import { CATEGORY_FIELDS } from "configs/category.config";
const defaultIcon = `assets/category-icon/other-expense.svg`


const CategoryCreateButton = ({ onCreatedData, type, renderList }) => {
    const [createCategory, { data, isSuccess, error }] = useCreateCategoryMutation()
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [catName, setCatName] = useState('');
    const [selectedIcon, setSelectedIcon] = useState(defaultIcon);
    const [createData, setCreateData] = useState({})
    const [parentCat, setParentCat] = useState({
        name: null,
        id: null
    })
    const originalDataRef = useRef({
        [CATEGORY_FIELDS.name]: catName,
        [CATEGORY_FIELDS.iconDir]: selectedIcon,
        [CATEGORY_FIELDS.parentCatId]: parentCat.id
    })
    const [isDataChanged, setIsDataChanged] = useState(false)
    const [isCanSubmit, setIsCanSubmit] = useState(false);


    const openModal = () => setIsModalOpen(true)
    const closeModal = () => {
        setCatName('')
        setSelectedIcon(defaultIcon)
        setParentCat({
            name: null,
            id: null
        })
        setIsModalOpen(false)
    }
    const handleOnSubmit = async (e) => {
        e.preventDefault();
        await createCategory({ ...createData });
    }
    const handleOnSelectIcon = (icon) => {
        setSelectedIcon(icon)
    }

    const handleNameChange = (e) => {
        setCatName(e.target.value);
    }
    const clearParentCategory = () => {
        setParentCat({
            name: null,
            id: null
        })
    }
    useEffect(() => {
        setCreateData((prev) => {
            return {
                ...prev,
                [CATEGORY_FIELDS.name]: catName,
                [CATEGORY_FIELDS.iconDir]: selectedIcon,
                [CATEGORY_FIELDS.parentCatId]: parentCat.id
            }
        })
    }, [catName, selectedIcon, parentCat])
    useEffect(() => {
        if (JSON.stringify(originalDataRef.current) === JSON.stringify(createData)) {
            setIsDataChanged(false)
        } else {
            setIsDataChanged(true)
        }
        if (createData.name !== '')
            setIsCanSubmit(true)
        else
            setIsCanSubmit(false)
    }, [createData])

    useEffect(() => {
        if (error) {
            toastError(error);
        }
        if (isSuccess) {
            toast.success('Category created!')
            setIsModalOpen(false)
            setCatName('')
            setSelectedIcon(defaultIcon)
            setParentCat({
                name: null,
                id: null
            })
            onCreatedData(data.metadata)
        }
    }, [error, data])

    return (<>
        <div className="flex justify-center items-center bg-stone-950 text-stone-200 text-base xl:w-full phone:w-[10rem] relative z-0 p-5 rounded-lg shadow-md cursor-pointer" onClick={openModal}>
            <div className="w-full flex justify-center items-center transition-all opacity-70 duration-300 ease-in-out hover:scale-110 hover:shadow-lg hover:opacity-100">
                <img className="h-10" src={createIcon} alt="create icon" />
            </div>
        </div>
        <Modal
            isOpen={isModalOpen}
            onClose={closeModal}
            isForm
            handleOnSave={handleOnSubmit}
            isDataChanged={isDataChanged}
            isCanSubmit={isCanSubmit}
        >
            <div className="flex flex-col gap-5">
                <div className="flex flex-row gap-5 justify-left items-center min-w-64 text-lg">
                    <CategoryIconSelection
                        defaultSelectedIcon={selectedIcon}
                        onSelectIcon={icon => handleOnSelectIcon(icon)} />
                    <input className="bg-transparent outline-none placeholder:text-stone-500 min-w-64 text-lg" required placeholder="Enter name" type="text" value={catName} onChange={handleNameChange} maxLength='30' />
                </div>
                <div className="flex flex-row gap-5 justify-left items-center min-w-64 opacity-80">
                    <div className="rounded-full flex justify-center items-center h-11 w-11 " onClick={openModal}>
                        <img src={plusMinusIcon} className="h-8" alt="icon" />
                    </div>
                    <p className="text-lg">{type}</p>
                </div>
                <div className="flex flex-row gap-5 justify-left items-center min-w-64 ">
                    <div className="rounded-full flex justify-center items-center h-11 w-11" onClick={openModal}>
                        <img src={parentCategoryIcon} className="h-7" alt="icon" />
                    </div>
                    <div className="flex flex-row item-center">
                        <span className="flex self-center">Parent</span>
                        <img src={arrowIcon} className="h-5 flex self-center ml-2" alt="category parent" />
                        <CategorySelectionOnType
                            catSelectedName={parentCat.name}
                            onSelectCat={(id, name) => setParentCat({ id, name })}
                            renderList={renderList}
                            isParentType
                        />
                        <button disabled={!parentCat.name} className="flex self-center bg-stone-900 p-1 rounded-md opacity-80 cursor-pointer disabled:cursor-default disabled:opacity-50" onClick={() => clearParentCategory()}>
                            <img src={clearIcon} className="h-7" alt="clear parent" />
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    </>
    );
}

export default CategoryCreateButton;