import { useEffect } from "react";
import Input from "components/core/Input";
import validIcon from 'assets/img/valid-icon.svg'
import wrongIcon from 'assets/img/wrong-icon.svg'
import BgImage from "components/core/BgImage";
import Button from "components/core/Button";
import { useAccessInputController } from "hooks/access/useAccessInputController";

const AccessInput = ({ label, labelText, onCheckValid, onAccessInputChange, ...props }) => {
    const {
        isValid,
        showHidePassProps,
        finalPlaceholderStyle,
        inputType,
        isShowValidSign,
        isShowWrongSign,
        isShowHidePassButton,
        handleClickButton,
        handleChange,
        handleFocus,
        handleBlur
    } = useAccessInputController({ label, onCheckValid, onAccessInputChange, ...props })

    useEffect(() => {
        onCheckValid(label, isValid);
    }, [isValid, label, onCheckValid]);



    return (
        <div className="flex flex-row border border-solid w-full tex-[14px] items-center my-1 rounded-[3px] relative bg-accessSecondaryBackground">
            <label className="flex grow h-[36px] text-full relative" >
                <span className={finalPlaceholderStyle}>
                    {labelText}
                </span>
                <Input
                    type={inputType}
                    label={label}
                    handleBlur={handleBlur}
                    handleFocus={handleFocus}
                    handleChange={handleChange}
                    {...props}
                />
            </label>
            <div className="flex flex-row items-center pr-2">
                {isShowValidSign && <BgImage
                    url={validIcon}
                    height="22px"
                    width="22px"
                    size='contain'
                />}
                {isShowWrongSign && <BgImage
                    url={wrongIcon}
                    height="22px"
                    width="22px"
                    size='contain'
                />}
                {isShowHidePassButton && <div className="ml-2">
                    <Button
                        type='button'
                        className='bg-transparent text-accessSecondaryButton!important enabled:hover:bg-transparent enabled:hover:text-accessSecondaryText px-0 py-0'
                        onClick={handleClickButton}
                    >{showHidePassProps.buttonText}</Button>
                </div>}
            </div>
        </div>
    );
}

export default AccessInput;