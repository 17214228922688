import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    name: 'Total',
    id: null
};

const walletFilterSlice = createSlice({
    name: 'wallet_filter',
    initialState,
    reducers: {
        setWalletFilter: (state, action) => {
            const { name, id } = action.payload;
            if (name !== undefined) state.name = name;
            if (id !== undefined) state.id = id;
        }
    }
})

export const { setWalletFilter } = walletFilterSlice.actions
export default walletFilterSlice.reducer