import { useEffect, useState } from "react";
import CategorySelector from "components/core/CategorySelector";
import plusIcon from 'assets/img/plus-icon.svg'
import minusIcon from 'assets/img/minus-icon.svg'
import WalletSelector from "components/core/WalletSelector";
import { BLANK_WALLET, WALLET_FIELDS } from "configs/wallet.config";
import MoneyInputField from "components/core/MoneyInputField";
import { TRANSACTION_FIELDS } from "configs/transaction.config";
import { containsEmptyStringOrNaN, getChangedKeys, isEmptyObj, removeKey, toastError } from "utils/helpers";
import { useCreateTransactionMutation, useUpdateTransactionMutation } from "services/api/TransactionService";
import toast from "react-hot-toast";
import { useListWalletsQuery } from "services/api/WalletService";

const TransactionSlidePanel = ({ isOpen, onClose, currentTransaction, onCreated, isOnEdit, onUpdated }) => {
    const [isExpense, setIsExpense] = useState()
    const [currentWallet, setCurrentWallet] = useState(BLANK_WALLET)
    const [formData, setFormData] = useState({})
    const [initialData, setInitialData] = useState(currentTransaction);
    const [isCanSubmit, setIsCanSubmit] = useState(false);
    const [isDataChanged, setIsDataChanged] = useState(false);
    const { data: walletList, isSuccess: getWalletListSuccess } = useListWalletsQuery();
    const [createTransaction, { data: createdData, isSuccess: isCreatedSuccess, error: createdError }] = useCreateTransactionMutation()
    const [updateTransaction, { data: updatedData, isSuccess: isUpdatedSuccess, error: updatedError }] = useUpdateTransactionMutation()
    const [walletOptions, setWalletOptions] = useState([])

    const handleChange = (value, field) => {
        setFormData(prev => {
            return {
                ...prev,
                [field]: value
            }
        })
    }

    const handleOnSelectWallet = (wallet) => {
        setCurrentWallet(wallet)
        handleChange(wallet[WALLET_FIELDS.id], TRANSACTION_FIELDS.walletId)
    }

    const handleOnClickSave = async (e) => {
        e.preventDefault();
        if (isCanSubmit && isDataChanged) {
            setIsCanSubmit(false)
            if (isOnEdit) {
                const payload = getChangedKeys(formData, initialData);
                await updateTransaction({ transactionId: currentTransaction[TRANSACTION_FIELDS.id], ...payload });

            } else {
                await createTransaction({ ...formData })
            }
        }
    }

    useEffect(() => {
        if (isCreatedSuccess) {
            toast.success('Added transaction successfully')
            onCreated(createdData.metadata)
            onClose();
        }
        if (createdError) {
            toastError(createdError);
        }

    }, [isCreatedSuccess, createdData, createdError])

    useEffect(() => {
        if (isUpdatedSuccess) {
            toast.success('Updated transaction successfully')
            onUpdated(updatedData.metadata)
            onClose();
        }
        if (updatedError) {
            toastError(updatedError);
        }

    }, [isUpdatedSuccess, createdError, updatedData])


    useEffect(() => {
        if (currentTransaction) {
            if (!currentTransaction[TRANSACTION_FIELDS.date]) {
                let currentDate = new Date();
                currentTransaction[TRANSACTION_FIELDS.date] = currentDate.toISOString().split('T')[0];
            } else {
                currentTransaction = {
                    ...currentTransaction,
                    [TRANSACTION_FIELDS.date]: currentTransaction[TRANSACTION_FIELDS.date].split('T')[0]
                }
            }
            const newFormData = JSON.parse(JSON.stringify(currentTransaction));
            const newInitialData = JSON.parse(JSON.stringify(currentTransaction));

            setFormData(newFormData);
            setInitialData(newInitialData);
            if (!currentTransaction.amount || currentTransaction.amount < 0) {
                setIsExpense(true);
            } else {
                setIsExpense(false)
            }
        } else {
            setInitialData(BLANK_WALLET);
            setFormData(BLANK_WALLET)
        }
    }, [currentTransaction])

    useEffect(() => {
        if (currentTransaction) {
            const checkIsSubmit = formData[TRANSACTION_FIELDS.amount] !== 0 && !containsEmptyStringOrNaN(removeKey(formData, [TRANSACTION_FIELDS.note]))
            setIsCanSubmit(checkIsSubmit);
            if (isExpense) {
                formData[TRANSACTION_FIELDS.amount] = -Math.abs(formData[TRANSACTION_FIELDS.amount]);
            } else {
                formData[TRANSACTION_FIELDS.amount] = Math.abs(formData[TRANSACTION_FIELDS.amount]);
            }
            const updatedData = getChangedKeys(formData, initialData);
            setIsDataChanged(!isEmptyObj(updatedData));
        }
    }, [currentTransaction, formData, isExpense])

    useEffect(() => {
        if (currentTransaction) {
            if (getWalletListSuccess) {
                const options = walletList.metadata;
                setWalletOptions(walletList.metadata);
                if (currentTransaction[TRANSACTION_FIELDS.walletId]) {
                    const initialWallet = options.find(wallet => wallet[WALLET_FIELDS.id] === currentTransaction[TRANSACTION_FIELDS.walletId])
                    setCurrentWallet(initialWallet);
                } else {
                    setCurrentWallet(BLANK_WALLET);
                }

            }
        }
    }, [walletList, getWalletListSuccess, currentTransaction])


    return (
        <>
            {isOpen && (
                <div
                    data-state={isOpen ? 'open' : 'closed'}
                    className={`fixed inset-0 z-50 bg-black/80 transition-opacity duration-300 ${isOpen ? 'opacity-100' : 'opacity-0'}`}
                    style={{ pointerEvents: isOpen ? 'auto' : 'none' }}
                    aria-hidden={!isOpen}
                    onClick={(e) => onClose()}
                ></div>
            )}
            <div
                className={`fixed z-50 bg-stone-950 p-6 shadow-lg transition-transform duration-300 ease-in-out overflow-auto
                    inset-y-0 right-0 h-full w-3/4 border-l border-borderTable transform ${isOpen ? 'translate-x-0' : 'translate-x-full'}
                    sm:max-w-sm text-stone-200`}
                style={{ pointerEvents: isOpen ? 'auto' : 'none' }}
            >
                <div className="flex flex-col space-y-2 text-center sm:text-left">
                    <h2 className="text-lg font-semibold">Add Transaction</h2>
                    <p className="text-sm text-stone-300">Make changes to your transaction here. Click save when you're done.</p>
                </div>
                <div className="grid gap-5 py-4">
                    <div className="flex flex-col gap-2">
                        <label className="text-sm font-bold text-left">Date</label>
                        <input
                            type="date"
                            className="max-w-[10rem] h-10 text-stone-200 bg-stone-950 rounded-md border border-borderTable px-3 py-2 text-sm 
                            focus:outline-stone-200 focus:outline"
                            onChange={e => handleChange(e.target.value, TRANSACTION_FIELDS.date)}
                            value={formData[TRANSACTION_FIELDS.date] || ''}
                        />
                    </div>
                    <div className="flex flex-col gap-2">
                        <label className="text-sm font-bold text-right flex flex-row gap-1 items-center">
                            Type
                        </label>
                        <div
                            className="col-span-3 flex flex-row rounded-md border border-borderTable max-w-[10rem] px-3 py-2 gap-2 cursor-pointer"
                            onClick={() => setIsExpense(!isExpense)}>
                            <img src={!isExpense ? plusIcon : minusIcon} alt="" className="h-7" />
                            <span>{isExpense ? 'Expense' : 'Income'}</span>
                        </div>
                    </div>
                    <div className="flex flex-col gap-2">
                        <label className="text-sm font-bold text-left">Category</label>
                        <CategorySelector
                            type={isExpense ? 'expense' : 'income'}
                            onSelectCat={(catId) => handleChange(catId, TRANSACTION_FIELDS.categoryId)}
                            initialCatId={formData && formData[TRANSACTION_FIELDS.categoryId]}
                        />
                    </div>
                    <div className="flex flex-col gap-2">
                        <label className="text-sm font-bold text-left">Wallet</label>
                        <WalletSelector
                            walletOptions={walletOptions || []}
                            currentWallet={currentWallet}
                            onSelectWallet={handleOnSelectWallet}
                            className='relative h-[3.5rem] flex flex-row gap-2 items-center max-w-[15rem] bg-stone-950 rounded-md border border-borderTable px-3 py-2 text-sm 
                            focus:outline-stone-200 focus:outline'
                        />
                    </div>
                    <MoneyInputField
                        label='Amount'
                        inputStyle="relative flex flex-row max-w-[15rem] text-stone-200 bg-stone-950 rounded-md border border-borderTable px-2 py-2 text-sm 
                            focus:outline-stone-200 focus:outline"
                        initialValue={formData && formData[TRANSACTION_FIELDS.amount] || ''}
                        currencyCode={currentWallet[WALLET_FIELDS.currency]}
                        onChangeAmount={value => handleChange(value, TRANSACTION_FIELDS.amount)}
                    />
                </div>
                <div className="flex flex-col gap-2">
                    <label className="text-sm font-bold text-left">Note:</label>
                    <textarea
                        type="text"
                        className="max-w-[15rem] max-h-[10rem] text-stone-200 bg-stone-950 rounded-md border border-borderTable px-3 py-2 text-sm 
                            focus:outline-stone-200 focus:outline"
                        value={formData[TRANSACTION_FIELDS.note] || ''}
                        onChange={e => handleChange(e.target.value, TRANSACTION_FIELDS.note)}
                    />
                </div>
                <div className="flex flex-col-reverse sm:flex-row sm:justify-start sm:space-x-2 mt-4">
                    <button
                        className="inline-flex items-center justify-center rounded-md text-sm font-medium 
                        bg-stone-200 text-stone-900 hover:bg-stone-300 h-10 px-4 py-2 disabled:opacity-85 disabled:hover:bg-stone-200"
                        type="submit"
                        onClick={handleOnClickSave}
                        disabled={!isCanSubmit || !isDataChanged}
                    >Save changes</button>
                </div>
                <button
                    type="button"
                    className="absolute right-4 top-4 rounded-sm opacity-70 transition-opacity hover:opacity-100 focus:outline-none"
                    onClick={onClose}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                        strokeLinejoin="round" className="h-4 w-4">
                        <path d="M18 6 6 18"></path>
                        <path d="m6 6 12 12"></path>
                    </svg>
                    <span className="sr-only">Close</span>
                </button>
            </div>
        </>
    );
}

export default TransactionSlidePanel;