import { ATTRIBUTES_ON_TYPE, WALLET_FIELDS } from "configs/wallet.config";
import threeDotsIcon from 'assets/img/threedots-icon.svg'
import { formatCurrency, formatDate } from 'utils/helpers';
import Pie from 'components/core/PiePercentage';
import trashIcon from 'assets/img/trash-icon.svg'


const GoalWalletCard = ({ walletItem, onClickEdit, onClickDelete }) => {
    if (walletItem[WALLET_FIELDS.type] !== 'goal') return null

    const walletAmount = walletItem[WALLET_FIELDS.balance] + walletItem[WALLET_FIELDS.initialBalance];
    const goalAmount = walletItem[WALLET_FIELDS.attributes][ATTRIBUTES_ON_TYPE.goal.goalValue];
    const percentage = (walletAmount / goalAmount * 100).toFixed(1)
    const remainingAmount = goalAmount - walletAmount > 0 ? goalAmount - walletAmount : 0

    return (
        <div className=" flex flex-col justify-between bg-blue-600  text-stone-200 relative group z-0 p-5 rounded-lg shadow-md">
            <div className="w-full flex flex-row gap-6 justify-left items-center">
                <Pie
                    percentage={percentage}
                    color='blue'
                    width={100}
                    height={100}
                    fontSize='1rem'
                    strokeWidth='1rem' />
                <div className='xl:text-xl phone:text-sm text-stone-300 font-bold' >
                    <p>
                        {walletItem[WALLET_FIELDS.name]}
                    </p>
                    <p>
                        {formatCurrency(goalAmount, walletItem[WALLET_FIELDS.currency])}
                    </p>
                </div>
            </div>
            <div className='xl:text-lg phone:text-sm'>
                <p className='text-stone-300'>Remaining Amount:</p>
                <p className='text-stone-200 font-bold'>
                    {formatCurrency(remainingAmount, walletItem[WALLET_FIELDS.currency])}
                </p>
                <p className='text-stone-300'>End date:</p>
                <p className='text-stone-200 font-bold'>
                    {formatDate(walletItem[WALLET_FIELDS.attributes][ATTRIBUTES_ON_TYPE.goal.endingDate])}
                </p>
            </div>
            <div className='absolute top-2 right-3 cursor-pointer' onClick={e => onClickEdit(e, walletItem)}>
                <img src={threeDotsIcon} alt="" className='h-8' />
            </div>
            <div className='absolute bottom-3 right-3 cursor-pointer' onClick={e => onClickDelete(e, walletItem)}>
                <img src={trashIcon} alt="" className='h-7' />
            </div>
        </div>
    );
}

export default GoalWalletCard;