import { useEffect, useState } from 'react';
import TransactionRow from './TransactionRow';
import { TRANSACTION_FIELDS } from 'configs/transaction.config';
import sortIcon from 'assets/img/sort-icon.svg'


const TransactionTable = ({ transactionList, onSelectedIds, onEdit, onSort, isDeleted, isCancelSelect }) => {
    const [selectedIds, setSelectedIds] = useState([]);
    const [transactions, setTransactions] = useState([]);

    const toggleSelect = (id) => {
        setSelectedIds(prev =>
            prev.includes(id) ? prev.filter(item => item !== id) : [...prev, id]
        );
    };

    const isChecked = (id) => selectedIds.includes(id);

    const toggleSelectAll = () => {
        if (selectedIds.length === transactionList.length) {
            setSelectedIds([]);
        } else {
            setSelectedIds(transactionList.map(item => item[TRANSACTION_FIELDS.id]));
        }
    };

    useEffect(() => {
        onSelectedIds(selectedIds);
    }, [selectedIds])

    useEffect(() => {
        setTransactions(transactionList)
    }, [transactionList])

    useEffect(() => {
        if (isDeleted || isCancelSelect) setSelectedIds([]);
    }, [isDeleted, isCancelSelect])


    const CheckmarkSVG = () => (
        <span className="flex items-center justify-center text-current" style={{ pointerEvents: 'none' }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="h-4 w-4">
                <path d="M20 6 9 17l-5-5"></path>
            </svg>
        </span>
    );

    return (
        <div className="overflow-auto w-full relative shadow-md sm:rounded-lg border border-b-0 border-borderTable ">
            <table className="w-full text-base text-stone-200 ">
                <thead className="text-stone-300  bg-stone-950 hover:bg-slate-900 border-b border-borderTable">
                    <tr>
                        <th className="h-12 px-4 text-left ">
                            <button
                                type="button"
                                role="checkbox"
                                className={`h-4 w-4 shrink-0 rounded-sm border border-primary ring-offset-stone-900 focus-visible:outline-none 
                                focus-visible:ring-2 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 
                                 ${selectedIds.length === transactionList.length ? "bg-stone-200 text-stone-900" : ""}`}
                                aria-label="Select all"
                                onClick={toggleSelectAll}
                            >
                                {selectedIds.length === transactionList.length ? <CheckmarkSVG /> : null}
                            </button>
                        </th>
                        <th scope="col" className="p-3 flex justify-center">
                            <div
                                className='flex flex-row items-center justify-center w-1/2 py-2 cursor-pointer rounded hover:bg-slate-800'
                                onClick={(e) => onSort(TRANSACTION_FIELDS.date)}
                            >
                                <span>Date</span> <img src={sortIcon} className='h-5' alt="" />
                            </div>
                        </th>
                        <th scope="col" className="p-3 text-left">Category</th>
                        <th scope="col" className="p-3">Wallet</th>
                        <th scope="col" className="p-3 flex justify-center">
                            <div
                                className='flex flex-row items-center justify-center w-1/2 py-2 cursor-pointer rounded hover:bg-slate-800'
                                onClick={(e) => onSort(TRANSACTION_FIELDS.amount)}
                            >
                                <span>Amount</span> <img src={sortIcon} className='h-5' alt="" />
                            </div>
                        </th>
                        <th scope="col" className="p-3">Note</th>
                        <th scope="col" className="p-3"></th>
                    </tr>
                </thead>
                <tbody>
                    {transactions && transactions.map((item, index) => (
                        <TransactionRow
                            key={item[TRANSACTION_FIELDS.id]}
                            transaction={item}
                            isChecked={isChecked}
                            toggleSelect={toggleSelect}
                            onEdit={onEdit}
                        />
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default TransactionTable;
