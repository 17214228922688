const Input = ({
    className,
    type = 'text',
    required,
    label,
    handleFocus,
    handleBlur,
    handleChange,
}) => {

    const defaultClassName = 'text-sm text-left bg-accessSecondaryBackground grow m-0 pt-[14px] pr-0 pb-0.5 pl-2 outline-none truncate'

    return (
        <input
            className={`${defaultClassName} ${className}`}
            required={required}
            type={type}
            id={label}
            name={label}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={handleChange}
        />
    );
}

export default Input;