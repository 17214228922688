import { configureStore } from '@reduxjs/toolkit';
import authReducer from './auth'
import walletFilterReducer from './wallet'
import dateFilterReducer from './date'
import { accessApi } from 'services/api/AccessService';
import { setupListeners } from '@reduxjs/toolkit/query';
import { rtkQueryErrorLogger } from 'services/middlewares/errorHandling';
import { categoryApi } from 'services/api/CategoryService';
import { walletApi } from 'services/api/WalletService';
import { transactionApi } from 'services/api/TransactionService';

const store = configureStore({
    reducer: {
        auth: authReducer,
        walletFilter: walletFilterReducer,
        dateFilter: dateFilterReducer,
        [accessApi.reducerPath]: accessApi.reducer,
        [categoryApi.reducerPath]: categoryApi.reducer,
        [walletApi.reducerPath]: walletApi.reducer,
        [transactionApi.reducerPath]: transactionApi.reducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: ['transactionApi/executeMutation/fulfilled'],
            ignoredPaths: ['transactionApi.mutations'],
        }
    })
        .concat(rtkQueryErrorLogger)
        .concat(accessApi.middleware)
        .concat(categoryApi.middleware)
        .concat(walletApi.middleware)
        .concat(transactionApi.middleware)

})

setupListeners(store.dispatch)

export default store;
