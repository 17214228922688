import Modal from "components/core/Modal";
import ToggleOnOff from "components/core/ToggleOnOff";
import cashIcon from 'assets/img/cash-icon.svg'
import MoneyInputField from "components/core/MoneyInputField";
import CurrencySelector from "components/core/CurrencySelection";
import { WALLET_FIELDS } from "configs/wallet.config";
import { getCurrencySymbol } from "utils/helpers";
import useWalletModalController from "hooks/wallet/useWalletModalController";

const BasicWalletModal = ({ currentWallet, isOnOpen, onClose, onUpdated, onCreated }) => {
    const {
        isModalOpen,
        isCanSubmit,
        formData,
        isDataChanged,
        closeModal,
        handleChange,
        handleOnSave
    } = useWalletModalController(currentWallet, isOnOpen, onClose, onUpdated, onCreated)


    return (
        <Modal
            isOpen={isModalOpen}
            onClose={closeModal}
            isForm
            handleOnSave={handleOnSave}
            isDataChanged={isDataChanged}
            isCanSubmit={isCanSubmit}
        >
            <h4 className="mb-5 text-lg font-bold">Basic Wallet</h4>
            <div className="flex flex-col gap-4 w-[20rem]">
                <div className="flex flex-col gap-2 text-base w-[15rem]">
                    <label className="font-bold text-sm text-stone-300">Wallet Name</label>
                    <input
                        className="h-7 py-4 px-4 bg-stone-900 rounded outline-none"
                        onChange={e => handleChange(e.target.value, 'name')}
                        value={formData && formData[WALLET_FIELDS.name]}
                        placeholder='Enter name'
                    />
                </div>
                <div className="flex flex-col gap-2 text-base w-[15rem]">
                    <label className="font-bold text-sm text-stone-300">Currency</label>
                    <CurrencySelector
                        initialValue={formData && `${formData[WALLET_FIELDS.currency]} (${getCurrencySymbol(formData[WALLET_FIELDS.currency])})`}
                        onChange={value => handleChange(value, 'currency')} />
                    <MoneyInputField
                        label='Initial Balance'
                        initialValue={formData && formData[WALLET_FIELDS.initialBalance]}
                        currencyCode={formData && formData[WALLET_FIELDS.currency]}
                        onChangeAmount={value => handleChange(value, WALLET_FIELDS.initialBalance)}
                    />
                </div>
                <div className="flex flex-col gap-2 text-base w-[15rem]">
                    <label className="font-bold text-sm text-stone-300">Include in total</label>
                    <input type='hidden' value={formData && formData[WALLET_FIELDS.includeInTotal]} />
                    <ToggleOnOff isOn={formData && formData[WALLET_FIELDS.includeInTotal]} onToggle={value => handleChange(value, 'include_in_total')} />
                </div>
            </div>
            <div className="absolute right-5 top-5 opacity-40 rotate-45 overflow-hidden z-[-1]">
                <img src={cashIcon} className="h-[10rem]" alt="" />
            </div>
        </Modal>
    );
}

export default BasicWalletModal;