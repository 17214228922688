import Button from "components/core/Button";

const Modal = ({
    className,
    isOpen,
    onClose,
    isForm,
    closeText = 'Cancel',
    saveText = 'Save',
    isDataChanged = true,
    isCanSubmit = true,
    handleOnSave,
    children
}) => {

    if (!isOpen) {
        return null
    }

    const handleModalContentClick = (e) => e.stopPropagation();

    return (
        <div className="fixed flex top-0 left-0 w-screen h-screen bg-[rgba(0,0,0,0.5)] z-10 justify-center items-center " onClick={onClose}>
            {!isForm &&
                <div className={` relative bg-stone-800 text-base text-stone-200 rounded ${className}`} onClick={handleModalContentClick}>
                    {children}
                </div>}
            {isForm &&
                <form className="relative bg-stone-800 text-base text-stone-200 z-10 px-5 pt-5 pb-20 rounded" onClick={handleModalContentClick}>
                    {children}
                    <div className="absolute bottom-2 right-2 flex flex-row gap-5">
                        <Button type='button' className='bg-stone-600 px-3 py-2' onClick={onClose}>{closeText}</Button>
                        <Button type='submit' disabled={!isDataChanged || !isCanSubmit} onClick={handleOnSave} className='bg-green-600 px-3 py-2 disabled:opacity-[0.7] disabled:cursor-auto'>{saveText}</Button>
                    </div>
                </form>}
        </div>
    );
}

export default Modal;