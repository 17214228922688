import { useRef, useState, useEffect } from "react";
import dropdownIcon from 'assets/img/dropdown-icon.svg'
import dateIcon from 'assets/img/date-icon.svg'
import { getStartEndDateByTimePeriod } from "utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import { setDateFilter } from "store/date";

const TIME_PERIOD_OPTIONS = [
    'This month',
    'Last 30 days',
    'Last 7 days',
    'Last month',
    'This year',
    'Customize'
]
const TimePeriodFilter = () => {
    const dispatch = useDispatch();
    const dropdownRef = useRef(null)
    const [isOpen, setIsOpen] = useState(false);
    const value = useSelector(state => state.dateFilter.name);
    const dateFilter = useSelector(state => state.dateFilter.filterDate);

    const handleMenuOpen = () => {
        setIsOpen(!isOpen)
    }
    const handleSelect = (option) => {
        setIsOpen(false)
        if (option != 'Customize') {
            dispatch(setDateFilter({ name: option, filterDate: getStartEndDateByTimePeriod(option) }));
        } else {
            dispatch(setDateFilter({ name: option }))
        }
    }
    const handleDateCustomizeChange = (e, option) => {
        const value = e.target.value
        let selectedDate = ''
        if (value) {
            selectedDate = new Date(value).toISOString()
        }
        const newDateFilter = {
            ...dateFilter,
            [option]: selectedDate
        };
        dispatch(setDateFilter({ name: 'Customize', filterDate: newDateFilter }));
    }


    useEffect(() => {
        const handleClickOutside = (e) => {
            if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
                setIsOpen(false)
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])


    return (<div className="flex sm:flex-row flex-col gap-2 ">
        <div
            ref={dropdownRef}
            className="relative flex flex-row gap-2 items-center w-[13rem]  bg-stone-950 rounded-md border 
            border-borderTable px-3 text-sm focus:outline-stone-200 focus:outline cursor-pointer h-[3rem]"
            onClick={e => handleMenuOpen()}
        >
            <img src={dateIcon} alt="wallet icon" className="h-5" />
            <span
                className=" text-stone-200 bg-stone-950 text-sm cursor-pointer w-full"
            >
                {value}
            </span>
            <span className={`absolute right-3 top-[16px] transition-transform cursor-pointer ${isOpen ? 'rotate-180' : ''}`}>
                <img src={dropdownIcon} className='h-3' alt="" />
            </span>
            <div className=' absolute h-7 w-[1px] bg-stone-200 top-2 right-[35px]'></div>
            {isOpen && <ul className="absolute left-0 top-[3.5rem] w-full bg-stone-950 text-stone-200 border border-stone-700
                max-h-[200px] overflow-y-scroll list-none z-10 rounded py-2">
                {TIME_PERIOD_OPTIONS.map((option, index) =>
                    <li
                        key={index}
                        className="flex flex-row text-sm justify-left items-center px-3 py-2
                         rounded-sm cursor-pointer hover:bg-stone-700 h-[3rem]"
                        onClick={(e) => handleSelect(option)}
                    >
                        <div className='flex flex-col '>
                            <span >{option}</span>
                        </div>
                    </li>
                )}
            </ul>}
        </div>
        {value === 'Customize' && <div className="flex flex-row items-center gap-2 text-stone-200 h-[3rem]">
            <input
                type="date"
                className="max-w-[10rem] h-full text-stone-200 bg-stone-950 rounded-md border border-borderTable px-3 py-2 text-sm 
                            focus:outline-stone-200 focus:outline"
                value={dateFilter?.start?.split('T')[0]}
                onChange={(e) => handleDateCustomizeChange(e, 'start')}
            />
            <span>to</span>
            <input
                type="date"
                className="max-w-[10rem] h-full text-stone-200 bg-stone-950 rounded-md border border-borderTable px-3 py-2 text-sm 
                            focus:outline-stone-200 focus:outline"
                value={dateFilter?.end?.split('T')[0]}
                onChange={(e) => handleDateCustomizeChange(e, 'end')}
            />
        </div>}
    </div>);
}

export default TimePeriodFilter;