import { getAuthStored } from 'utils/helpers';
import { useAuthUserController } from 'hooks/access/useAuthUserController';
import { useEffect } from 'react';
import { useAuthUserMutation } from 'services/api/AccessService';
import { useSelector } from 'react-redux';

const FormAccessContainer = ({ className, children }) => {
    const authState = useSelector(state => state.auth)
    const [authUser, { data: authData, error: authError }] = useAuthUserMutation();
    const { handleAuthStored, handleCheckAuth } = useAuthUserController();

    const authStored = getAuthStored();

    useEffect(() => {
        handleAuthStored(authStored, authUser)
    }, [])

    useEffect(() => {
        handleCheckAuth(authState.isLogin, authData, authStored, authError);
    }, [authState.isLogin, authData, authError])

    className = `flex flex-col mt-3 max-w-sm w-[24rem] justify-center border h-auto border-stone-300 bg-accessPrimaryBackground ${className}`
    return (
        <div className={className}>
            {children}
        </div>
    );
}

export default FormAccessContainer;