import Modal from "components/core/Modal";
import ToggleOnOff from "components/core/ToggleOnOff";
import creditIcon from 'assets/img/credit-icon.svg'
import DropdownDateInput from "components/core/DropdownDateInput";
import MoneyInputField from "components/core/MoneyInputField";
import CurrencySelector from "components/core/CurrencySelection";
import useWalletModalController from "hooks/wallet/useWalletModalController";
import { ATTRIBUTES_ON_TYPE, WALLET_FIELDS } from "configs/wallet.config";
import { getCurrencySymbol } from "utils/helpers";
import get from 'lodash/get'


const CreditWalletModal = ({ currentWallet, isOnOpen, onClose, onUpdated, onCreated }) => {
    const {
        isModalOpen,
        isCanSubmit,
        formData,
        isDataChanged,
        closeModal,
        handleChange,
        handleOnSave
    } = useWalletModalController(currentWallet, isOnOpen, onClose, onUpdated, onCreated)

    return (
        <Modal
            isOpen={isModalOpen}
            onClose={closeModal}
            isForm
            handleOnSave={handleOnSave}
            isDataChanged={isDataChanged}
            isCanSubmit={isCanSubmit}
        >
            <h4 className="mb-5 text-lg font-bold">Credit Wallet</h4>
            <div className="flex flex-col gap-4 w-[30rem]">
                <div className="flex flex-col gap-2 text-base w-full">
                    <label className="font-bold text-sm text-stone-300">Wallet Name</label>
                    <input
                        className="h-7 py-4 px-4 bg-stone-900 rounded outline-none w-1/2"
                        onChange={e => handleChange(e.target.value, 'name')}
                        value={formData && formData[WALLET_FIELDS.name]}
                    />
                    <div className="flex flex-col gap-2 w-1/2 my-2">
                        <span className="font-bold text-sm text-stone-300 ">Currency</span>
                        <CurrencySelector
                            initialValue={formData && `${formData[WALLET_FIELDS.currency]} (${getCurrencySymbol(formData[WALLET_FIELDS.currency])})`}
                            onChange={value => handleChange(value, 'currency')} />
                    </div>
                    <div className="flex flex-row gap-2 ">
                        <MoneyInputField
                            label='Credit Limit'
                            initialValue={get(formData, `${WALLET_FIELDS.attributes}.${ATTRIBUTES_ON_TYPE.credit.creditLimit}`, '')}
                            currencyCode={get(formData, `${WALLET_FIELDS.currency}`, 'USD')}
                            onChangeAmount={value => handleChange(value, ATTRIBUTES_ON_TYPE.credit.creditLimit)}
                        />
                        <MoneyInputField
                            label='Initial Balance'
                            initialValue={get(formData, `${WALLET_FIELDS.initialBalance}`, '')}
                            currencyCode={get(formData, `${WALLET_FIELDS.currency}`, 'USD')}
                            onChangeAmount={value => handleChange(value, WALLET_FIELDS.initialBalance)}
                        />
                    </div>
                    <div className="flex flex-row gap-2 mt-3 ">
                        <div className="flex flex-col gap-2 w-full">
                            <label className="font-bold text-sm text-stone-300">Statement day </label>
                            <DropdownDateInput
                                initialValue={get(formData, `${WALLET_FIELDS.attributes}.${ATTRIBUTES_ON_TYPE.credit.statementDay}`, '')}
                                onChange={day => handleChange(day, 'statement_day')} />
                        </div>
                        <div className="flex flex-col gap-2 w-full">
                            <label className="font-bold text-sm text-stone-300">Payment due day</label>
                            <DropdownDateInput initialValue={formData?.attributes?.payment_due_day} onChange={day => handleChange(day, 'payment_due_day')} />
                        </div>
                    </div>
                </div>
                <div className="flex flex-col gap-2 text-base w-[10rem]">
                    <label className="font-bold text-sm text-stone-300">Include in total</label>
                    <input type='hidden' value={formData && formData[WALLET_FIELDS.includeInTotal]} />
                    <ToggleOnOff isOn={formData && formData[WALLET_FIELDS.includeInTotal]} onToggle={value => handleChange(value, 'include_in_total')} />
                </div>
            </div>

            <div className="absolute right-5 top-5 opacity-40 rotate-45 overflow-hidden z-[-1]">
                <img src={creditIcon} className="h-[10rem]" alt="" />
            </div>
        </Modal>
    );
}

export default CreditWalletModal;