
import { CATEGORY_FIELDS } from "configs/category.config";
import toast from "react-hot-toast";


/**
 * Type predicate to narrow an unknown error to `FetchBaseQueryError`
 */
export function isFetchBaseQueryError(error) {
    return typeof error === 'object' && error !== null && 'status' in error;
}

/**
 * Type predicate to narrow an unknown error to an object with a string 'message' property
 */
export function isErrorWithMessage(error) {
    return (
        typeof error === 'object' &&
        error !== null &&
        'message' in error &&
        typeof error.message === 'string'
    );
}

export function getAuthStored() {
    try {
        const jsonAuthUser = localStorage.getItem('authUser');
        if (!jsonAuthUser) return null

        const authUser = JSON.parse(jsonAuthUser);
        if (!authUser.userId || !authUser.accessToken) return null

        return authUser
    } catch (error) {
        return null;
    }
}

export function isAnyKeyNull(object) {
    return Object.values(object).some(value => value === null);
}

export function toastError(error) {
    if (isFetchBaseQueryError(error)) {
        const errorMessage = error.data ? error.data.message : error.error
        toast.error(errorMessage)
    }
    if (isErrorWithMessage(error)) {
        toast.error(error.message)
    }
}

export function findObjByAttr(arr, attr, value) {
    const foundObj = arr.find(item => item[attr] === value);
    if (!foundObj) return null;
    return foundObj;
}

export function isEmptyObj(obj) {
    return Object.keys(obj).length === 0;
}
// flatten the parent-children array
export function flatten(data) {
    return data.reduce((acc, { children, ...otherProps }) => [
        ...acc,
        otherProps,
        ...flatten(children)
    ], []);
}
export function sortCategoryArr(rawRenderArr) {
    //add children field to category
    const map = rawRenderArr.reduce(
        (acc, item) => ({ ...acc, [item[CATEGORY_FIELDS.id]]: { ...item, children: [] } }), {}
    )
    // push children item to parent item
    const result = []
    rawRenderArr.forEach((item) => {
        if (item[CATEGORY_FIELDS.parentCatId] !== null && map[item[CATEGORY_FIELDS.parentCatId]]) {
            map[item[CATEGORY_FIELDS.parentCatId]].children.push(map[item[CATEGORY_FIELDS.id]]);
        } else {
            result.push(map[item[CATEGORY_FIELDS.id]]);
        }
    });

    return flatten(result)
}

export function getNextPaymentDate(paymentDueDayOfMonth) {
    if (!paymentDueDayOfMonth)
        return null
    const today = new Date();
    let paymentYear = today.getFullYear();
    const currentMonth = today.getMonth()
    const currentDate = today.getDate()
    let paymentMonth = paymentDueDayOfMonth > currentDate ? currentMonth : currentMonth + 1
    if (paymentMonth === 13) {
        paymentMonth = 1
        paymentYear++
    }
    return new Date(paymentYear, paymentMonth, paymentDueDayOfMonth)
}

/**
 * Formats a date string from "MM/DD/YYYY" to "Month DD, YYYY".
 * @param {string} dateString - The date string in "MM/DD/YYYY" format.
 * @returns {string} - The formatted date string in "Month DD, YYYY" format.
 */
export function formatDate(dateString) {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
}
export function formatDateChart(dateString) {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { month: 'long', day: 'numeric' });
}

export function formatMoneyNumber(num) {
    const plainNumbers = num.replace(/[^\d]/g, '');
    return plainNumbers.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
export function moneyToNumber(str) {
    return parseInt(str.replace(/,/g, ''), 10);
}

/**
 * Formats a number as a currency string using the given currency code.
 * 
 * @param {number} number The number to format.
 * @param {string} currencyCode The ISO currency code (e.g., "USD", "EUR").
 * @return {string} The formatted currency string.
 */
export function formatCurrency(number, currencyCode) {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currencyCode,
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
    }).format(number);
}

export function getCurrencySymbol(currencyCode = 'USD', locale = 'en-US') {
    if (!currencyCode) return '';
    const formatter = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: currencyCode,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    });
    const parts = formatter.formatToParts(0);
    const symbol = parts.find(part => part.type === 'currency').value;
    return symbol;
}

export function isObject(item) {
    return item !== null && typeof item === 'object' && !Array.isArray(item);
}

export function getChangedKeys(baseObj, compareObj) {
    let result = Array.isArray(baseObj) ? [] : {}; // Initialize the result as an array or object based on baseObj
    Object.keys(baseObj).forEach(key => {
        if (isObject(baseObj[key]) && isObject(compareObj[key])) {
            const childObject = getChangedKeys(baseObj[key], compareObj[key]);
            if (Object.keys(childObject).length > 0) {
                result[key] = childObject; // Include only changed nested objects
            }
        } else if (baseObj[key] !== compareObj[key]) {
            result[key] = baseObj[key]; // Include keys where values are different
        }
    });
    return result;
}

export function containsEmptyStringOrNaN(obj) {
    for (const key in obj) {
        if (obj[key] === "" || Number.isNaN(obj[key]) || obj[key] === undefined) {
            return true;
        }

        if (isObject(obj[key]) && containsEmptyStringOrNaN(obj[key])) {
            return true;
        }
    }
    return false;
}

export function timestampToDateInputValue(timestamp) {
    const date = new Date(timestamp);

    // Check if the date is valid
    if (isNaN(date.getTime())) {
        console.error('Invalid timestamp');
        return null;  // Return null or any appropriate value indicating the error
    }

    return date.toISOString().split('T')[0];
}

export function dateInputValueToTimestamp(dateValue) {
    const date = new Date(dateValue);
    return date.getTime();
}

export function removeKey(obj, keys) {
    if (!Array.isArray(keys)) {
        keys = [keys];
    }

    const rest = { ...obj };
    keys.forEach(key => {
        delete rest[key];
    });

    return rest;
}

export function getStartEndDateByTimePeriod(period) {
    const today = new Date();
    let startDate;
    let endDate;

    switch (period) {
        case 'All time':
            return null;
        case 'Last 7 days':
            startDate = new Date(today);
            startDate.setDate(today.getDate() - 7);
            startDate.setHours(0, 0, 0, 0);
            endDate = new Date(today);
            endDate.setHours(23, 59, 59, 999);
            break;
        case 'Last 30 days':
            startDate = new Date(today);
            startDate.setDate(today.getDate() - 30);
            startDate.setHours(0, 0, 0, 0);
            endDate = new Date(today);
            endDate.setDate(today.getDate() + 1);
            endDate.setHours(23, 59, 59, 999);
            break;
        case 'This month':
            startDate = new Date(today.getUTCFullYear(), today.getUTCMonth(), 1);
            startDate.setUTCHours(23, 59, 59, 999);
            endDate = new Date(today.getUTCFullYear(), today.getUTCMonth() + 1, 0);
            endDate.setUTCHours(23, 59, 59, 999);
            break;
        case 'Last month':
            startDate = new Date(today.getUTCFullYear(), today.getUTCMonth() - 1, 1);
            startDate.setUTCHours(23, 59, 59, 999);
            endDate = new Date(today.getUTCFullYear(), today.getUTCMonth(), 0);
            endDate.setUTCHours(23, 59, 59, 999);
            break;
        case 'This year':
            startDate = new Date(today.getUTCFullYear(), 0, 1);
            startDate.setUTCHours(23, 59, 59, 999);
            endDate = new Date(today.getUTCFullYear(), 11, 31);
            endDate.setUTCHours(23, 59, 59, 999);
            break;
        default:
            throw new Error('Invalid time period specified');
    }

    return {
        start: startDate.toISOString(),
        end: endDate.toISOString()
    };
}

export function fillMissingTransactions(data, startDate, endDate) {
    const result = [];
    const start = new Date(startDate);
    start.setDate(start.getDate() + 1);
    const end = new Date(endDate);
    end.setDate(end.getDate() + 1);
    let dataIndex = 0;

    const monthsDifference = (end.getFullYear() - start.getFullYear()) * 12 + (end.getMonth() - start.getMonth());

    if (monthsDifference > 3) {
        for (let d = new Date(start); d <= end; d.setMonth(d.getMonth() + 1)) {
            const monthString = d.toISOString().slice(0, 7);
            if (dataIndex < data.length && data[dataIndex].date === monthString) {
                result.push(data[dataIndex]);
                dataIndex++;
            } else {
                result.push({ date: monthString, income: 0, expense: 0 });
            }
        }
        console.log(result);
    } else {
        for (let d = new Date(start); d <= end; d.setDate(d.getDate() + 1)) {
            const dateString = d.toISOString().split('T')[0];
            if (dataIndex < data.length && data[dataIndex].date === dateString) {
                result.push(data[dataIndex]);
                dataIndex++;
            } else {
                result.push({ date: dateString, income: 0, expense: 0 });
            }
        }
    }

    return result;
}







