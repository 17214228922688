import Modal from "components/core/Modal";
import { useEffect, useState } from "react";
import { categoriesIconName } from "utils/categoriesIconName";



const CategoryIconSelection = ({
    defaultSelectedIcon,
    onSelectIcon
}) => {
    const [selectedIcon, setSelectedIcon] = useState(defaultSelectedIcon);
    const [isModalOpen, setIsModalOpen] = useState(false)
    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false)
    const handleSelectIcon = (iconName) => {
        setSelectedIcon(`assets/category-icon/${iconName}`);
        closeModal()
    }
    useEffect(() => {
        onSelectIcon(selectedIcon);
    }, [selectedIcon]);

    return (<>
        {selectedIcon &&
            <div className="rounded-full flex justify-center items-center bg-stone-300 h-11 w-11 cursor-pointer" onClick={openModal}>
                <img src={selectedIcon} className="h-8" alt="icon" />
            </div>
        }
        <Modal
            className='z-10'
            isOpen={isModalOpen}
            onClose={closeModal}
        >
            <div className="flex flex-wrap justify-center gap-5 w-[30rem] p-10">
                {
                    categoriesIconName.map((iconName) =>
                        <div key={iconName} className="rounded-full flex justify-center items-center bg-stone-300 h-14 w-14 cursor-pointer" onClick={() => handleSelectIcon(iconName)}>
                            <img src={`/assets/category-icon/${iconName}`} className="h-10" alt="icon" />
                        </div>
                    )
                }
            </div>
        </Modal>
    </>);
}

export default CategoryIconSelection;