import { useEffect, useState } from "react";
import { formatDate, formatMoneyNumber } from "utils/helpers";

const Card = ({ title, icon, bgIcon, currency, amount, dateRange, previousAmount }) => {
    const [percentChange, setPercentChange] = useState(0)
    useEffect(() => {
        if (!amount || !previousAmount) {
            setPercentChange(0)
        } else {
            let percent = Math.floor(Math.abs((amount - previousAmount) / previousAmount) * 100)
            setPercentChange(percent)
        }
    }, [amount, previousAmount])
    return (
        <div className="bg-stone-950 text-stone-200 w-full h-[10rem] p-5 rounded-md">
            <div className="flex flex-col gap-2 justify-between h-full">
                <div className="flex flex-row justify-between gap-2">
                    <div className="text-left">
                        <h3 className="text-base font-bold">{title}</h3>
                        <span className="text-sm text-stone-400">
                            {dateRange.start ? formatDate(dateRange.start) : 'loading'}-
                            {dateRange.end ? formatDate(dateRange.end) : 'loading'}</span>
                    </div>
                    <div className={`p-2 ${bgIcon} rounded-md`}>
                        <img src={icon} className="h-10" />
                    </div>
                </div>
                <div>
                    <div className="text-left">
                        <h3 className="text-base font-bold">

                            {currency}{amount >= 0 ? '+' : '-'}{amount ? formatMoneyNumber(amount.toString()) : '0'}
                        </h3>
                        {<span className="text-sm text-green-500">{percentChange}% change from last period</span>}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Card;