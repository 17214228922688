import { useState } from "react";

const ToggleOnOff = ({ isOn, onToggle }) => {
    return (
        <button
            type="button"
            onClick={() => {
                onToggle(!isOn)
            }}
            className={`w-14 h-8 flex items-center rounded-full p-1 cursor-pointer ${isOn ? 'bg-green-400' : 'bg-gray-200'} transition-colors duration-300`}>
            <span className={`block w-6 h-6 bg-white rounded-full shadow-md transform ${isOn ? 'translate-x-6' : 'translate-x-0'} transition-transform duration-300`}>
            </span>
        </button>
    );
}

export default ToggleOnOff;