import { createApi } from "@reduxjs/toolkit/query/react"
import { baseQueryWithReAuth } from "./QueryReAuth"
export const transactionApi = createApi({
    reducerPath: 'transactionApi',
    baseQuery: baseQueryWithReAuth,
    endpoints: (builder) => ({
        createTransaction: builder.mutation({
            query: ({ ...body }) => {
                return {
                    url: `/transaction`,
                    method: 'POST',
                    body: body
                }
            }
        }),
        listTransaction: builder.mutation({
            query: (query) => {
                return {
                    url: `/transaction/list`,
                    method: 'POST',
                    body: query
                }
            }
        }),
        deleteTransactions: builder.mutation({
            query: (body) => {
                return {
                    url: `/transaction/delete`,
                    method: 'POST',
                    body: body
                }
            }
        }),
        duplicateTransactions: builder.mutation({
            query: (body) => {
                return {
                    url: `/transaction/duplicate`,
                    method: 'POST',
                    body: body
                }
            }
        }),
        updateTransaction: builder.mutation({
            query: ({ transactionId, ...body }) => {
                return {
                    url: `/transaction/${transactionId}`,
                    method: 'PATCH',
                    body: body
                }
            }
        }),
        getWalletStatic: builder.mutation({
            query: (body) => {
                return {
                    url: '/transaction/wallet-static',
                    method: 'POST',
                    body: body
                }
            }
        }),
        getTransactionStatic: builder.mutation({
            query: (body) => {
                return {
                    url: '/transaction/transaction-static',
                    method: 'POST',
                    body: body
                }
            }
        }),
        getCategoryStatic: builder.mutation({
            query: (body) => {
                return {
                    url: '/transaction/category-static',
                    method: 'POST',
                    body: body
                }
            }
        }),
        exportCSV: builder.mutation({
            query: (query) => {
                return {
                    url: `/transaction/export-csv`,
                    method: 'POST',
                    body: query,
                    responseHandler: (response) => response.blob(),
                }
            }
        })
    })
})

export const {
    useCreateTransactionMutation,
    useListTransactionMutation,
    useDeleteTransactionsMutation,
    useUpdateTransactionMutation,
    useGetWalletStaticMutation,
    useGetTransactionStaticMutation,
    useGetCategoryStaticMutation,
    useExportCSVMutation,
    useDuplicateTransactionsMutation
} = transactionApi
