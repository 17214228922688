import { useEffect, useState } from "react";
import { ATTRIBUTES_ON_TYPE, WALLET_FIELDS } from "configs/wallet.config";
import { containsEmptyStringOrNaN, dateInputValueToTimestamp, getChangedKeys, isEmptyObj, removeKey } from "utils/helpers";
import { useCreateWalletMutation, useUpdateWalletMutation } from "services/api/WalletService";
import toast from "react-hot-toast";
import { toastError } from "utils/helpers";
import { useListWalletsQuery } from 'services/api/WalletService';


const useWalletModalController = (currentWallet, isOnOpen, onClose, onUpdated, onCreated) => {
    const { refetch } = useListWalletsQuery();
    const [isModalOpen, setIsModalOpen] = useState(isOnOpen);
    const [initialData, setInitialData] = useState(currentWallet);
    const [formData, setFormData] = useState({})
    const [isDataChanged, setIsDataChanged] = useState(false)
    const [isCanSubmit, setIsCanSubmit] = useState(false)
    const [updateWallet, { data: updatedData, isSuccess: isUpdateSuccess, error: updateError }] = useUpdateWalletMutation();
    const [createWallet, { data: createdData, isSuccess: isCreateSuccess, error: createError }] = useCreateWalletMutation();

    const closeModal = () => {
        setFormData(initialData)
        onClose()
        setIsModalOpen(false)
    }

    const handleOnSave = (e) => {
        e.preventDefault()
        if (formData && formData[WALLET_FIELDS.type] === 'credit') {
            if (formData[WALLET_FIELDS.attributes][ATTRIBUTES_ON_TYPE.credit.creditLimit] < formData[WALLET_FIELDS.balance]) {
                toast.error('Balance cannot be higher than credit limit');
                return
            }
        }
        if (!formData[WALLET_FIELDS.id]) {
            createWallet({
                ...formData,
                [WALLET_FIELDS.balance]: 0
            })
        } else {
            const updatedData = getChangedKeys(formData, initialData);
            updateWallet({
                walletId: initialData[WALLET_FIELDS.id],
                ...updatedData
            })
        }
    }

    useEffect(() => {
        if (updateError) {
            toastError(updateError);
        }
        if (isUpdateSuccess) {
            toast.success('wallet updated!')
            refetch();
            onUpdated(formData)
            onClose()
            setIsModalOpen(false)
        }
    }, [updateError, isUpdateSuccess])

    useEffect(() => {
        if (createError) {
            toastError(createError);
        }
        if (isCreateSuccess) {
            toast.success('wallet created!')
            refetch();
            onCreated(createdData.metadata)
            onClose()
            setIsModalOpen(false)
        }
    }, [createError, isCreateSuccess, createdData])

    useEffect(() => {
        setIsCanSubmit(!containsEmptyStringOrNaN(removeKey(formData, WALLET_FIELDS.balance)))
        const updatedData = getChangedKeys(formData, initialData)
        setIsDataChanged(!isEmptyObj(updatedData));
    }, [formData, initialData])


    useEffect(() => {
        setInitialData(currentWallet);
        setFormData(currentWallet);
    }, [currentWallet])


    useEffect(() => {
        setIsModalOpen(isOnOpen)
    }, [isOnOpen])

    const handleChange = (value, type = '') => {
        switch (type) {
            case WALLET_FIELDS.name:
                setFormData((prev) => {
                    return {
                        ...prev,
                        [WALLET_FIELDS.name]: value
                    }
                })
                break;
            case WALLET_FIELDS.currency:
                setFormData((prev) => {
                    return {
                        ...prev,
                        [WALLET_FIELDS.currency]: value
                    }
                })
                break;
            case WALLET_FIELDS.includeInTotal:
                setFormData((prev) => {
                    return {
                        ...prev,
                        [WALLET_FIELDS.includeInTotal]: value
                    }
                })
                break;
            case WALLET_FIELDS.initialBalance:
                setFormData((prev) => {
                    return {
                        ...prev,
                        [WALLET_FIELDS.initialBalance]: value
                    }
                })
                break;
            case ATTRIBUTES_ON_TYPE.credit.creditLimit:
                setFormData((prev) => {
                    return {
                        ...prev,
                        [WALLET_FIELDS.attributes]: {
                            ...prev[WALLET_FIELDS.attributes],
                            credit_limit: value
                        }
                    }
                })
                break;
            case ATTRIBUTES_ON_TYPE.credit.statementDay:
                setFormData((prev) => {
                    return {
                        ...prev,
                        [WALLET_FIELDS.attributes]: {
                            ...prev[WALLET_FIELDS.attributes],
                            statement_day: value
                        }
                    }
                })
                break;
            case ATTRIBUTES_ON_TYPE.credit.paymentDueDay:
                setFormData((prev) => {
                    return {
                        ...prev,
                        [WALLET_FIELDS.attributes]: {
                            ...prev[WALLET_FIELDS.attributes],
                            payment_due_day: value
                        }
                    }
                })
                break;
            case ATTRIBUTES_ON_TYPE.goal.goalValue:
                setFormData((prev) => {
                    return {
                        ...prev,
                        [WALLET_FIELDS.attributes]: {
                            ...prev[WALLET_FIELDS.attributes],
                            [ATTRIBUTES_ON_TYPE.goal.goalValue]: value
                        }
                    }
                })
                break;
            case ATTRIBUTES_ON_TYPE.goal.endingDate:
                setFormData((prev) => {
                    return {
                        ...prev,
                        [WALLET_FIELDS.attributes]: {
                            ...prev[WALLET_FIELDS.attributes],
                            [ATTRIBUTES_ON_TYPE.goal.endingDate]: dateInputValueToTimestamp(value)
                        }
                    }
                })
                break;
            default:
                break
        }

    }


    return {
        isModalOpen,
        isCanSubmit,
        initialData,
        formData,
        isDataChanged,
        closeModal,
        handleOnSave,
        handleChange
    };
}

export default useWalletModalController;