import homeIcon from "assets/img/home-icon.svg"
import dashBoardIcon from "assets/img/dashboard-icon.svg"
import transactionIcon from "assets/img/transaction-icon.svg"
import settingIcon from "assets/img/setting-icon.svg"
import accountIcon from "assets/img/account-icon.svg"
import categoryIcon from "assets/img/category-icon.svg"
import walletIcon from 'assets/img/wallet-icon.svg'
import budgetIcon from 'assets/img/budget-icon.svg'

export const SIDEBAR_ITEMS = [
    {
        icon: dashBoardIcon,
        text: 'Dashboard',
        to: '/dashboard'
    },
    {
        icon: transactionIcon,
        text: 'Transactions',
        to: '/transactions'
    },
    {
        icon: categoryIcon,
        text: 'Categories',
        to: '/categories'
    },
    {
        icon: walletIcon,
        text: 'Wallets',
        to: '/wallets'
    },
    {
        icon: budgetIcon,
        text: 'Budgets',
        to: '/budgets'
    }

    // {
    //     icon: settingIcon,
    //     text: 'Setting',
    //     to: '/setting'
    // },
    // {
    //     icon: accountIcon,
    //     text: 'Account',
    //     to: '/account'
    // },
]