import TimePeriodFilter from "components/core/TimePeriodFilter";
import WalletFilter from "components/core/WalletFilter";
import Card from "components/dashboard-page/Card";
import { useSelector } from "react-redux";
import balanceIcon from 'assets/img/balance-icon.svg'
import increaseIcon from 'assets/img/increase-icon.svg'
import decreaseIcon from 'assets/img/decrease-icon.svg'
import IncomeExpenseAreaChart from "components/dashboard-page/ExpenseIncomeAreaChart";
import CategoriesPieChart from "components/dashboard-page/CategoriesPieChart";
import { useGetTransactionStaticMutation, useGetWalletStaticMutation, useGetCategoryStaticMutation } from "services/api/TransactionService";
import { useEffect, useState } from "react";
import { fillMissingTransactions, toastError } from "utils/helpers";

const Dashboard = () => {
    const walletId = useSelector(state => state.walletFilter.id)
    const authState = useSelector(state => state.auth);
    const timePeriod = useSelector(state => state.dateFilter.filterDate)
    const [walletQuery, setWalletQuery] = useState({
        walletId,
        startDate: null,
        endDate: null
    })
    const [getWalletStatic, { data: walletStaticData, isSuccess: getWalletStaticSuccess, error: getWalletStaticError }] = useGetWalletStaticMutation();
    const [getTransactionStatic, { data: transactionStaticData, isSuccess: getTransactionStaticSuccess, error: getTransactionStaticError }] = useGetTransactionStaticMutation();
    const [getCategoryStatic, { data: categoryStaticData, isSuccess: getCategoryStaticSuccess, error: getCategoryStaticError }] = useGetCategoryStaticMutation();


    const [walletStatic, setWalletStatic] = useState({
        balance: undefined,
        previousBalance: undefined,
        totalExpense: undefined,
        previousTotalExpense: undefined,
        totalIncome: undefined,
        previousTotalIncome: undefined
    })

    const [transactionStatic, setTransactionStatic] = useState([]);
    const [categoryStatic, setCategoryStatic] = useState([]);

    useEffect(() => {
        setWalletQuery(prev => {
            return {
                ...prev,
                walletId,
                startDate: timePeriod.start,
                endDate: timePeriod.end
            }
        })
    }, [timePeriod, walletId])
    useEffect(() => {
        const fetchStaticData = async () => {
            await Promise.all([
                getWalletStatic(walletQuery),
                getTransactionStatic(walletQuery),
                getCategoryStatic(walletQuery)
            ]);
        };
        fetchStaticData();
    }, [walletQuery])

    useEffect(() => {
        if (getWalletStaticSuccess) {
            setWalletStatic(walletStaticData.metadata)
        }
        if (getWalletStaticError) {
            toastError(getWalletStaticError)
        }
    }, [walletStaticData, getWalletStaticSuccess, getWalletStaticError])

    useEffect(() => {
        if (getCategoryStaticSuccess) {
            setCategoryStatic(categoryStaticData.metadata);
        }
        if (getCategoryStaticError) {
            toastError(getCategoryStaticError)
        }
    }, [categoryStaticData, getCategoryStaticSuccess, getCategoryStaticError])

    useEffect(() => {
        if (getTransactionStaticSuccess) {
            let transactionStaticFullDate = fillMissingTransactions(transactionStaticData.metadata, timePeriod.start, timePeriod.end)
            setTransactionStatic(transactionStaticFullDate);
        }
        if (getTransactionStaticError) {
            toastError(getTransactionStaticError);
        }
    }, [transactionStaticData, getTransactionStaticSuccess, getTransactionStaticError]);

    return (<div className="h-full w-full mb-[10rem] ">
        <div className="w-full h-[20rem] bg-gradient-to-b from-slate-800 to-slate-600 shadow-sm shadow-slate-600 p-10">
            <div className="py-5 text-stone-200">
                <h3 className="font-bold xl:text-[2rem] phone:text-[1rem]">Welcome back, {authState.userProfile.userEmail}</h3>
                <span className="text-base text-stone-300 ">This is your financial overview report</span>
            </div>
            <div className="flex xl:flex-row flex-col gap-4">
                <WalletFilter />
                <TimePeriodFilter />
            </div>

        </div>
        <div className="w-full px-10 xl:-translate-y-[5rem] -translate-y-[3rem] ">
            <div className="flex xl:flex-row flex-col justify-between gap-4">
                <Card
                    title="Balance"
                    icon={balanceIcon}
                    bgIcon='bg-blue-500 opacity-50'
                    currency={'$'}
                    amount={walletStatic.balance}
                    dateRange={timePeriod}
                    previousAmount={walletStatic.previousBalance}
                />
                <Card
                    title="Income"
                    icon={increaseIcon}
                    bgIcon='bg-green-200 opacity-50'
                    currency={'$'}
                    amount={walletStatic.totalIncome}
                    dateRange={timePeriod}
                    previousAmount={walletStatic.previousTotalIncome}
                />
                <Card
                    title="Expense"
                    icon={decreaseIcon}
                    bgIcon='bg-red-300 opacity-50'
                    currency={'$'}
                    amount={walletStatic.totalExpense}
                    dateRange={timePeriod}
                    previousAmount={walletStatic.previousTotalExpense}
                />
            </div>
            <div className="flex xl:flex-row flex-col mt-10 gap-4 ">
                <div className="xl:w-2/3 w-full bg-stone-950 rounded-md p-5">
                    <div className="text-stone-200">
                        <h2>Transaction</h2>
                    </div>
                    <IncomeExpenseAreaChart
                        data={transactionStatic}
                    />
                </div>
                <div className="xl:w-1/3 w-full bg-stone-950 rounded-md p-5">
                    <div className="text-stone-200 w-full h-[500px]">
                        <h2>Categories</h2>
                        <CategoriesPieChart categoryStatic={categoryStatic} />
                    </div>
                </div>
            </div>
        </div>
    </div>);
}

export default Dashboard;