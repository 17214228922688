import { useCallback, useEffect, useState } from 'react';
import searchIcon from 'assets/img/search-icon.svg'
import clearSearchIcon from 'assets/img/clear-search-icon.svg'
import { debounce } from 'lodash';

const CategorySearchBar = ({ onSearch }) => {
    const [searchValue, setSearchValue] = useState('')
    const debouncedHandleSearch = useCallback(
        debounce((value) => onSearch(value), 500), []);

    const handleClearInput = () => {
        setSearchValue('')
        onSearch('')
    }

    return (
        <div className="bg-stone-950 shadow p-2 flex rounded-md my-5">
            <span className="w-auto flex justify-end items-center p-2 cursor-pointer">
                <img src={searchIcon} className='h-7' alt="" />
            </span>
            <input className="w-full bg-stone-950 rounded p-2 text-stone-200 outline-none xl:text-base phone:text-sm" type="text" placeholder="Search..."
                value={searchValue}
                onChange={(e) => {
                    setSearchValue(e.target.value)
                    debouncedHandleSearch(e.target.value)
                }} />
            {searchValue && <span className="w-auto flex justify-end items-center p-2 cursor-pointer" onClick={handleClearInput}>
                <img src={clearSearchIcon} className='h-7' alt="" />
            </span>}
        </div>
    )
}

export default CategorySearchBar;