import { useEffect, useRef, useState } from "react";
import dropdownIcon from 'assets/img/dropdown-icon.svg';
import { useListWalletsQuery } from "services/api/WalletService";
import { WALLET_FIELDS } from "configs/wallet.config";
import walletIcon from 'assets/img/wallet-icon.svg';
import { useDispatch, useSelector } from 'react-redux';
import { setWalletFilter } from "store/wallet";
import basicWalletIcon from 'assets/img/cash-icon.svg'
import creditWalletIcon from 'assets/img/credit-icon.svg'
import goalWalletIcon from 'assets/img/goal-money-icon.svg'

const walletIconMap = {
    'basic': basicWalletIcon,
    'credit': creditWalletIcon,
    'goal': goalWalletIcon
}

const WalletFilter = () => {
    const dispatch = useDispatch();
    const selectedWallet = useSelector(state => state.walletFilter);
    const { data, isSuccess } = useListWalletsQuery();
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);
    const [options, setOptions] = useState([]);

    const handleMenuOpen = () => {
        setIsOpen(!isOpen);
    };

    const handleSelectWallet = (wallet) => {
        setIsOpen(false);
        dispatch(setWalletFilter({
            name: wallet[WALLET_FIELDS.name],
            id: wallet[WALLET_FIELDS.id]
        }));
    };

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (isSuccess) {
            setOptions([
                {
                    [WALLET_FIELDS.name]: 'Total',
                    [WALLET_FIELDS.id]: null
                },
                ...data.metadata
            ]);
        }
    }, [isSuccess]);

    return (
        <div
            onClick={handleMenuOpen}
            ref={dropdownRef}
            className='relative flex flex-row gap-2 items-center w-[13rem] max-w-[15rem] h-[3rem] bg-stone-950 rounded-md border 
            border-borderTable px-3 text-sm focus:outline-stone-200 focus:outline cursor-pointer'>
            <img src={walletIcon} alt="wallet icon" className="h-5" />
            <span
                className=" text-stone-200 bg-stone-950 outline-none text-sm"
            >
                {selectedWallet[WALLET_FIELDS.name]}
            </span>
            <span className={`absolute right-3 top-[16px] transition-transform ${isOpen ? 'rotate-180' : ''}`}>
                <img src={dropdownIcon} className='h-3' alt="" />
            </span>
            <div className='absolute h-7 w-[1px] bg-stone-200 top-2 right-[35px]'></div>
            {isOpen && (
                <ul className="absolute left-0 top-[3.5rem] w-full bg-stone-950 text-stone-200 border border-stone-700
                max-h-[200px] overflow-y-scroll list-none z-10 rounded py-2">
                    {options.map((option, index) => (
                        <li
                            key={index}
                            className="flex flex-row text-sm justify-left items-center px-3 py-2
                            rounded-sm cursor-pointer hover:bg-stone-700 h-[3.5rem]"
                            onClick={() => handleSelectWallet(option)}
                        >
                            <div className="rounded-full flex justify-center items-center bg-stone-300 h-10 w-10">
                                <img src={walletIconMap[option.type]} className="inline w-7" />
                            </div>
                            <div className='flex flex-col ml-5'>
                                <span>{option[WALLET_FIELDS.name]}</span>
                            </div>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default WalletFilter;
