
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { setLogin, setLogout } from "store/auth";
import { isErrorWithMessage, isFetchBaseQueryError } from "utils/helpers";
const AccessRoute = ['/signup', '/forgot-password', '/reset-password/']
export const useAuthUserController = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    const handleAuthStored = async (authStored, authRequest) => {
        if (!authStored) return;

        await authRequest();

    }

    const handleResponseData = (resData, authStored) => {
        if (!authStored) return
        const authData = resData.metadata;
        dispatch(setLogin({
            userId: authData.userId,
            useName: authData.name,
            userEmail: authData.email,
            accessToken: authStored.accessToken,
            refreshToken: authStored.refreshToken,
        }))
    }

    const handleCheckAuth = (isLogin, authData, authStored, error) => {
        if (!isLogin && !AccessRoute.includes(location.pathname)) {
            navigate('/login');
        }
        if (isLogin) {
            navigate('/dashboard');
        }
        if (authData) {
            handleResponseData(authData, authStored);
        }
        if ((error && isErrorWithMessage(error)) || (error && isFetchBaseQueryError(error) && error.status !== 401)) {
            dispatch(setLogout());
        }
    }


    return {
        handleAuthStored,
        handleResponseData,
        handleCheckAuth
    }

}