import { AreaChart, Area, XAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { formatDateChart } from 'utils/helpers';


const IncomeExpenseAreaChart = ({ data }) => {
    return (
        <ResponsiveContainer width="100%" height={500}>
            <AreaChart data={data}
                margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                <defs>
                    <linearGradient id="colorIncome" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                        <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                    </linearGradient>
                    <linearGradient id="colorExpense" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                        <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                    </linearGradient>
                </defs>
                <XAxis dataKey="date" tickFormatter={formatDateChart} tick={{ fontSize: 12, fill: '#555' }} />
                <Tooltip formatter={value => value.toLocaleString()} />
                <Area type="monotone" dataKey="income" stroke="#82ca9d" fillOpacity={1} fill="url(#colorIncome)" />
                <Area type="monotone" dataKey="expense" stroke="#8884d8" fillOpacity={1} fill="url(#colorExpense)" />
            </AreaChart>
        </ResponsiveContainer>
    )
};

export default IncomeExpenseAreaChart;