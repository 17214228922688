import { formatCurrency, formatMoneyNumber } from "utils/helpers";
import { WALLET_FIELDS } from "configs/wallet.config";
import cashIcon from 'assets/img/cash-icon.svg'
import threeDotsIcon from 'assets/img/threedots-icon.svg'
import trashIcon from 'assets/img/trash-icon.svg'


const BasicWalletCard = ({ walletItem, onClickEdit, onClickDelete }) => {
    if (!walletItem || walletItem[WALLET_FIELDS.type] !== 'basic') return null
    return (
        <div className="flex flex-col justify-between bg-lime-700 text-stone-200 relative group z-0 p-5 rounded-lg shadow-md">
            <div className="w-full flex flex-row gap-6 justify-left items-center">
                <img src={cashIcon} className="h-[90px]" alt="icon" />
                <div className='xl:text-2xl phone:text-sm text-stone-300 font-bold' >
                    <p>
                        {walletItem[WALLET_FIELDS.name]}
                    </p>
                </div>
            </div>
            <div className='xl:text-xl phone:text-sm'>
                <p className='text-stone-300'>Balance:</p>
                <p className='text-stone-200 font-bold tracking-wider'>
                    {formatCurrency(walletItem[WALLET_FIELDS.balance] + walletItem[WALLET_FIELDS.initialBalance], walletItem[WALLET_FIELDS.currency])}
                </p>
            </div>
            <div className='absolute top-2 right-3 cursor-pointer' onClick={e => onClickEdit(e, walletItem)}>
                <img src={threeDotsIcon} alt="" className='h-8' />
            </div>
            <div className='absolute bottom-3 right-3 cursor-pointer' onClick={e => onClickDelete(e, walletItem)}>
                <img src={trashIcon} alt="" className='h-7' />
            </div>
        </div>
    );
}

export default BasicWalletCard;