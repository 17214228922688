import Text from "components/core/Text";
import FormAccessContainer from "components/access-page/FormAccessContainer";
import HeaderFormAccessContainer from "components/access-page/HeaderFormAccessContainer";
import { useNavigate } from "react-router-dom";
import ResetPasswordForm from "components/access-page/ResetPasswordForm";
import Footer from "components/layouts/Footer";

const ResetPassword = () => {
    const navigate = useNavigate();
    const moveToSignUp = () => {
        navigate('/signup');
    }
    const moveToLogin = () => {
        navigate('/login');
    }
    return (
        <main className="bg-stone-200 p-0 m-0 flex flex-col h-full ">
            <div className=" text-stone-900 flex flex-col justify-center items-center ml-auto mr-auto h-full mb-11 pb-11">
                <FormAccessContainer>
                    <HeaderFormAccessContainer>
                        <div className="mt-20 mx-10">
                            <Text
                                className='text-[16px] font-semibold leading-5'
                            >
                                Create A Strong Password
                            </Text>

                        </div>
                        <div className="mb-10 mx-10">
                            <Text className='text-accessSecondaryText text-[14px]'>
                                Your password must be at least 6 characters and should include a combination of numbers, letters and special characters (!$@%).
                            </Text>
                        </div>
                    </HeaderFormAccessContainer>
                    <ResetPasswordForm className='flex flex-col w-full px-10 mb-5 mt-2 justify-center' />
                    <div className="py-2 px-9 w-full" onClick={moveToSignUp}>
                        <Text className='text-accessLink text-sm cursor-pointer font-semibold'>
                            Create new account
                        </Text>
                    </div>
                    <div onClick={moveToLogin} className="w-full text-accessPrimaryText flex flex-row justify-center round-[1px] py-1 mt-5 bg-accessSecondaryBackground border-solid border-stone-300 border cursor-pointer">
                        <span className="w-full text-sm text-center m-2 font-semibold ">Back to login</span>
                    </div>
                </FormAccessContainer>
            </div>
            <Footer className="text-accessSecondaryText" />
        </main>
    );
}

export default ResetPassword;