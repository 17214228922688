const cleanPercentage = (percentage) => {
    const isNegativeOrNaN = !Number.isFinite(+percentage) || percentage < 0; // we can set non-numbers to 0 here
    const isTooHigh = percentage > 100;
    return isNegativeOrNaN ? 0 : isTooHigh ? 100 : +percentage;
};

const Circle = ({ color, percentage, r, cx, cy, circ, strokeWidth }) => {
    const strokePct = ((100 - percentage) * circ) / 100;
    return (
        <circle
            r={r}
            cx={cx}
            cy={cy}
            fill="transparent"
            stroke={strokePct !== circ ? color : ""}
            strokeWidth={strokeWidth}
            strokeDasharray={circ}
            strokeDashoffset={percentage ? strokePct : 0}
            strokeLinecap="round"
        ></circle>
    );
};


const Text = ({ percentage, cx, cy, fontSize }) => {
    return (
        <text
            x={cx}
            y={cy}
            dominantBaseline="central"
            textAnchor="middle"
            fontSize={fontSize}
        >
            {percentage.toFixed(0)}%
        </text>
    );
};


const Pie = ({ percentage, color, width, height, fontSize, strokeWidth }) => {
    const pct = cleanPercentage(percentage);
    const size = Math.min(width, height);
    const r = size / 3;
    const cx = size / 2;
    const cy = size / 2;
    const circ = 2 * Math.PI * r;
    return (
        <svg width={size} height={size}>
            <g transform={`rotate(-90 ${cx} ${cy})`}>
                <Circle color="lightgrey" r={r} cx={cx} cy={cy} circ={circ} strokeWidth={strokeWidth} />
                <Circle color={color} percentage={pct} r={r} cx={cx} cy={cy} circ={circ} strokeWidth={strokeWidth} />
            </g>
            <Text percentage={pct} cx={cx} cy={cy} fontSize={fontSize} />
        </svg>
    );
};


export default Pie