import { createApi } from "@reduxjs/toolkit/query/react"
import { baseQueryWithReAuth } from "./QueryReAuth"
export const accessApi = createApi({
    reducerPath: 'authApi',
    baseQuery: baseQueryWithReAuth,
    endpoints: (builder) => ({
        signUpUser: builder.mutation({
            query: ({ ...body }) => {
                return {
                    url: "/user/signup",
                    method: 'POST',
                    body: body
                }
            }
        }),
        logInUser: builder.mutation({
            query: ({ ...body }) => {
                return {
                    url: "/user/login",
                    method: 'POST',
                    body: body
                }
            },
        }),
        authUser: builder.mutation({
            query: () => {
                return {
                    url: '/user/auth',
                    method: 'POST',
                }
            }
        }),
        logOut: builder.mutation({
            query: ({ token }) => {
                return {
                    url: "/user/logout",
                    method: "POST",
                    headers: {
                        'x-rtoken-id': token,
                    },
                }
            },
        }),
        logInFbUser: builder.mutation({
            query: ({ ...body }) => {
                return {
                    url: "/user/login-facebook",
                    method: "POST",
                    body: body
                }
            }
        }),
        logInGGUser: builder.mutation({
            query: ({ ...body }) => {
                return {
                    url: "/user/login-google",
                    method: 'POST',
                    body: body
                }
            }
        }),
        forgotPassword: builder.mutation({
            query: ({ ...body }) => {
                return {
                    url: "/user/forgot-password",
                    method: "POST",
                    body: body
                }
            }
        }),
        resetPassword: builder.mutation({
            query: ({ ...body }) => {
                return {
                    url: "/user/reset-password",
                    method: "POST",
                    body: body
                }
            }
        })
    })
})

export const {
    useSignUpUserMutation,
    useLogInUserMutation,
    useAuthUserMutation,
    useLogOutMutation,
    useLogInFbUserMutation,
    useLogInGGUserMutation,
    useForgotPasswordMutation,
    useResetPasswordMutation
} = accessApi
