import Link from "components/core/Link"
import { useNavigate } from "react-router-dom";

const Footer = ({ className }) => {
    className = `flex flex-col justify-center items-center text-base phone:text-sm p-5 gap-2 ${className}`
    return (
        <footer className={className}>
            <nav className="flex flex-row justify-center gap-x-4 ">
                <Link url='/' className='hover:underline '>Home</Link>
                <Link url="/terms" className='hover:underline '>Terms</Link>
                <Link url="/privacy" className='hover:underline '>Privacy Policy</Link>
            </nav>
            <aside>
                <p className="text-center">Copyright © 2024 - All right reserved by <b>Money Dating</b> from <b>lamdu125</b></p>
            </aside>
        </footer>
    )
}

export default Footer