
const HEADER = {
    API_KEY: 'x-api-key',
    CLIENT_ID: 'x-client-id',
    AUTHORIZATION: 'authorization',
    REFRESHTOKEN: 'x-rtoken-id'
}
//signup config
const SignUpFields = [
    {
        label: 'email',
        text: 'Email'
    },
    {
        label: 'name',
        text: 'Full name'
    },
    {
        label: 'password',
        text: 'Password'
    }
];
const reqSignUpConfig = {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
        'x-api-key': '19650a17d483c7468fd5d3a574fddff6ed3db9f9eb4c3fba14be7de405b2eb6c32bddc0938f9fa0fa4af1bdeb314d24e39172d260e7bc16cbc8577e0bd64b380'
    }
}
const signUpUrl = 'http://localhost:3075/v1/api/user/signup'


//login config
const LoginFields = [
    {
        label: 'email',
        text: 'Email'
    },
    {
        label: 'password',
        text: 'Password'
    }
];
const reqLoginConfig = {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
        'x-api-key': '19650a17d483c7468fd5d3a574fddff6ed3db9f9eb4c3fba14be7de405b2eb6c32bddc0938f9fa0fa4af1bdeb314d24e39172d260e7bc16cbc8577e0bd64b380'
    }
}
const loginUrl = 'http://localhost:3075/v1/api/user/login'


//logout config
const reqLogoutConfig = {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
        [HEADER.API_KEY]: '19650a17d483c7468fd5d3a574fddff6ed3db9f9eb4c3fba14be7de405b2eb6c32bddc0938f9fa0fa4af1bdeb314d24e39172d260e7bc16cbc8577e0bd64b380'
    }
}
const logoutUrl = 'http://localhost:3075/v1/api/user/logout'

//auth config
const reqAuthConfig = {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
        [HEADER.API_KEY]: '19650a17d483c7468fd5d3a574fddff6ed3db9f9eb4c3fba14be7de405b2eb6c32bddc0938f9fa0fa4af1bdeb314d24e39172d260e7bc16cbc8577e0bd64b380'
    }
}
const authUrl = 'http://localhost:3075/v1/api/user/logout'

module.exports = {
    HEADER,
    SignUpFields,
    reqSignUpConfig,
    signUpUrl,
    LoginFields,
    reqLoginConfig,
    loginUrl,
    reqLogoutConfig,
    logoutUrl,
    reqAuthConfig,
    authUrl
}