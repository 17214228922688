import { useEffect, useState } from "react"
import { isEmail, isPasswordValid } from 'utils/validation'

export const useAccessInputController = ({ label, onCheckValid, onAccessInputChange, ...props }) => {
    const defaultPlaceholderStyle = 'text-[12px] text-accessSecondaryText leading-9 h-9 overflow-hidden'
        + ' absolute left-2 right-0 origin-left cursor-text overflow-hidden ';
    const focusPlaceholderStyle = 'transition ease-out duration-100 scale-[0.83] translate-y-[-10px]';

    const [value, setValue] = useState('');
    const [isFocused, setIsFocused] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const [showHidePassProps, setShowHidePassProps] = useState({
        isShow: false,
        inputType: 'password',
        buttonText: 'Show'
    });

    const finalPlaceholderStyle = defaultPlaceholderStyle + ((isFocused || value) && focusPlaceholderStyle);
    const inputType = label === 'password' || label === 'repeat_password' ? showHidePassProps.inputType : 'text';
    const isShowValidSign = !isFocused && value && isValid;
    const isShowWrongSign = !isFocused && value && !isValid;
    const isShowHidePassButton = value && (label === 'password' || label === 'repeat_password');

    const checkValid = (label) => {
        switch (label) {
            case 'email':
                setIsValid(isEmail(value));
                break;
            case 'password':
                setIsValid(isPasswordValid(value));
                break;
            case 'repeat_password':
                setIsValid(isPasswordValid(value) && props.isPassMatch);
                break;
            default:
                setIsValid(true)
                break;
        }
    }

    const handleClickButton = (e) => {
        e.preventDefault();
        setShowHidePassProps((prev) => {
            if (prev.isShow) {
                return {
                    isShow: false,
                    inputType: 'password',
                    buttonText: 'Show'
                }
            }
            return {
                isShow: true,
                inputType: 'text',
                buttonText: 'Hide'
            }
        })
    }


    const handleChange = (event) => {
        onAccessInputChange(event.target.name, event.target.value);
        const inputVal = event.target.value;
        setValue(inputVal);
    }

    useEffect(() => {
        checkValid(label)
    }, [value, props.isPassMatch])

    const handleFocus = () => {
        setIsFocused(true);
    }
    const handleBlur = () => {
        setIsFocused(false);
    }

    return {
        value,
        isFocused,
        isValid,
        showHidePassProps,
        finalPlaceholderStyle,
        inputType,
        isShowValidSign,
        isShowWrongSign,
        isShowHidePassButton,
        checkValid,
        handleClickButton,
        handleChange,
        handleFocus,
        handleBlur
    }
}
