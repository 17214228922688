import { useState, useEffect } from 'react';
import { formatMoneyNumber, getCurrencySymbol, moneyToNumber } from 'utils/helpers';
import clearIcon from 'assets/img/clear-search-icon.svg'

function MoneyInputField({ label,
    inputStyle = 'w-full h-10 relative flex flex-row bg-stone-900 rounded text-stone-100',
    initialValue = '',
    currencyCode = 'USD',
    onChangeAmount }) {
    const [value, setValue] = useState('');
    const [currencySymbol, setCurrencySymbol] = useState('$');
    const handleChange = (event) => {
        const input = event.target.value;
        if (/^\d*$/.test(input.replace(/,/g, ''))) {
            onChangeAmount(moneyToNumber(input))
            const formattedValue = formatMoneyNumber(input);
            setValue(formattedValue);
        }
    };

    const handleClearInput = () => {
        setValue('');
        onChangeAmount('')
    }

    useEffect(() => {
        setValue(formatMoneyNumber(initialValue.toString()));
    }, [initialValue]);

    useEffect(() => {
        let symbol = getCurrencySymbol(currencyCode);
        setCurrencySymbol(symbol);
    }, [currencyCode])

    return (
        <div className="flex flex-col gap-2 w-full">
            <label className="font-bold text-sm text-stone-300">{label}</label>
            <div className={inputStyle}>
                <span className="text-base  w-auto self-center text-center px-2">
                    {currencySymbol}
                </span>
                <input
                    type="text"
                    value={value}
                    onChange={handleChange}
                    className='inline-block pr-4 bg-transparent outline-none w-full'
                    placeholder="Enter a number"
                />

                {value &&
                    <div className=' absolute bg-stone-200 top-1/2 -translate-y-1/2 right-[20px] cursor-pointer' onClick={() => handleClearInput()} >
                        <img src={clearIcon} className='bg-stone-900 h-5' />
                    </div>
                }
            </div>
        </div>
    );
}

export default MoneyInputField;
