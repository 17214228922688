import { useEffect } from "react";
import { useExportCSVMutation } from "services/api/TransactionService";


const ExportButton = ({ query }) => {
    const [exportCSV, { isLoading }] = useExportCSVMutation();

    const handleExport = async () => {
        try {
            const { page, limit, ...exportQuery } = query
            const blob = await exportCSV(exportQuery).unwrap();
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'transactions.csv');
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            console.error('Failed to export CSV:', error);
        }
    };

    return (
        <button
            className='flex flex-row gap-2 items-center p-2 bg-green-700 text-stone-200 outline-none rounded-lg'
            onClick={handleExport}
            disabled={isLoading}>
            {isLoading ? 'Exporting...' : 'Export CSV'}
        </button>
    );
};

export default ExportButton;
