import CategoryCreateButton from "components/categories-page/CategoryCreateButton";
import CategoryListItem from "components/categories-page/CategoryListItem";
import CategoryNavbar from "components/categories-page/CategoryNavbar";
import CategoryUpdateModal from "components/categories-page/CategoryUpdateModal";
import { useEffect, useState } from "react";
import { useListExpenseCategoriesQuery, useListOweCategoriesQuery, useListIncomeCategoriesQuery } from "services/api/CategoryService";
import { CATEGORY_FIELDS } from "configs/category.config";
import { sortCategoryArr } from "utils/helpers";
import CategoryConfirmDeleteModal from "components/categories-page/CategoryConfirmDeleteModal";
import CategoryReadOnlyModal from "components/categories-page/CategoryReadOnlyModal";
import CategorySearchBar from "components/categories-page/CategorySearchBar";


const Categories = ({ defaultActiveType }) => {

    const { data: expenseList, isSuccess: isGetExpenseListSuccess, refetch: reListExpenseCat } = useListExpenseCategoriesQuery();
    const { data: incomeList, isSuccess: isGetIncomeListSuccess, refetch: reListIncomeCat } = useListIncomeCategoriesQuery();
    const { data: oweList, isSuccess: isGetOweListSuccess, refetch: reListOweCat } = useListOweCategoriesQuery();
    const [editItem, setEditItem] = useState();
    const [isOpenEdit, setIsOpenEdit] = useState(false)
    const [deleteItem, setDeleteItem] = useState()
    const [isOpenConfirmDelete, setIsOpenConfirmDelete] = useState(false)
    const [readOnlyItem, setReadOnlyItem] = useState();
    const [isOpenReadOnly, setIsOpenReadOnly] = useState(false)


    // Mapping object to map type to the corresponding list and success variable
    const typeToList = {
        'expense': { list: expenseList, isSuccess: isGetExpenseListSuccess, refetch: reListExpenseCat },
        'income': { list: incomeList, isSuccess: isGetIncomeListSuccess, refetch: reListIncomeCat },
        'owe': { list: oweList, isSuccess: isGetOweListSuccess, refetch: reListOweCat },
    };

    const [typeActive, setTypeActive] = useState(defaultActiveType);
    const [renderList, setRenderList] = useState([]);

    useEffect(() => {
        if (typeActive in typeToList && typeToList[typeActive]['isSuccess']) {
            const rawRenderArr = [...typeToList[typeActive]['list'].metadata];

            const sortCategories = sortCategoryArr(rawRenderArr);

            setRenderList(sortCategories)
        }
    }, [typeActive, isGetExpenseListSuccess, isGetIncomeListSuccess, isGetOweListSuccess]);

    const handleActiveType = (type) => {
        setTypeActive(type)
    }

    const handleOnUpdatedData = (data) => {
        setRenderList((prev) => {
            const updatedList = prev.map((item) => {
                if (item[CATEGORY_FIELDS.parentCatId] === data[CATEGORY_FIELDS.id] && data[CATEGORY_FIELDS.parentCatId]) {
                    return {
                        ...item,
                        [CATEGORY_FIELDS.parentCatId]: data[CATEGORY_FIELDS.parentCatId]
                    }
                }
                return item[CATEGORY_FIELDS.id] === data[CATEGORY_FIELDS.id] ? { ...item, ...data } : item
            })
            const sortCategories = sortCategoryArr(updatedList);
            return sortCategories;
        })
        handleOnCloseEdit()
        typeToList[typeActive]['refetch']();
    }
    const handleOnCreatedData = (data) => {
        setRenderList((prev) => {
            const updatedList = [...prev, { ...data }];
            const sortCategories = sortCategoryArr(updatedList);
            return sortCategories;
        });
        typeToList[typeActive]['refetch']();
    };


    const handleOnDeleted = (catId) => {
        if (!catId) return
        setRenderList((prev) => prev.filter(catItem => catItem[CATEGORY_FIELDS.id] !== catId));
        typeToList[typeActive]['refetch']();
    }

    const handleOnEdit = (catItem) => {
        setEditItem(catItem);
        setIsOpenEdit(true)
    }
    const handleOnCloseEdit = () => {
        setEditItem();
        setIsOpenEdit(false)
    }

    const handleOnEditChildCat = (cat) => {
        setIsOpenReadOnly(false)
        handleOnCloseEdit();
        handleOnEdit(cat)
    }

    const handleOnDelete = (catItem) => {
        setDeleteItem(catItem)
        setIsOpenConfirmDelete(true)
    }
    const handleOnCloseDelete = () => {
        setDeleteItem()
        setIsOpenConfirmDelete(false)
    }
    const handleOnCloseReadOnly = () => {
        setReadOnlyItem();
        setIsOpenReadOnly(false)
    }
    const handleOnReadOnly = (cat) => {
        setReadOnlyItem(cat);
        setIsOpenReadOnly(true)
    }
    const [searchList, setSearchList] = useState([]);
    const [isShowSearchList, setIsShowSearchList] = useState(false);
    const [searchValue, setSearchValue] = useState('')
    const handleSearch = (value) => {
        setSearchValue(value);
    }

    useEffect(() => {
        if (renderList && searchValue) {
            setSearchList(() => {
                const listSearch = renderList.filter(item => {
                    return item[CATEGORY_FIELDS.name].toLowerCase().includes(searchValue.toLowerCase());
                })
                return listSearch
            })
        }
        if (!searchValue) {
            setSearchList([])
        }
    }, [renderList, searchValue])

    useEffect(() => {
        if (searchList.length > 0) {
            setIsShowSearchList(true);
        } else {
            setIsShowSearchList(false)
        }
    }, [searchList, searchValue])

    return (
        <div className="flex flex-col mb-[10rem] xl:w-full p-10">
            <div className="py-5 text-stone-200">
                <h3 className="font-bold xl:text-base phone:text-sm">Your category</h3>
            </div>
            <CategorySearchBar onSearch={handleSearch} />
            <CategoryNavbar defaultActive='expense' onActiveType={handleActiveType} />
            <div className="grid xl:grid-cols-4 lg:grid-cols-4 sm:grid-cols-3 phone:grid-cols-2 gap-5 py-5">
                {
                    !isShowSearchList && renderList && renderList.map(category =>
                        <CategoryListItem
                            key={category[CATEGORY_FIELDS.id]}
                            name={category[CATEGORY_FIELDS.name]}
                            id={category[CATEGORY_FIELDS.id]}
                            parentCatId={category[CATEGORY_FIELDS.parentCatId]}
                            iconDir={category[CATEGORY_FIELDS.iconDir]}
                            isOweType={category[CATEGORY_FIELDS.isOweType]}
                            onDelete={() => handleOnDelete(category)}
                            onEdit={() => handleOnEdit(category)}
                            onReadOnly={() => handleOnReadOnly(category)}
                            renderList={renderList}
                            typeActive={typeActive}
                        />)
                }
                {
                    isShowSearchList && searchList && searchList.map(category =>
                        <CategoryListItem
                            key={category[CATEGORY_FIELDS.id]}
                            name={category[CATEGORY_FIELDS.name]}
                            id={category[CATEGORY_FIELDS.id]}
                            parentCatId={category[CATEGORY_FIELDS.parentCatId]}
                            iconDir={category[CATEGORY_FIELDS.iconDir]}
                            isOweType={category[CATEGORY_FIELDS.isOweType]}
                            onDelete={() => handleOnDelete(category)}
                            onEdit={() => handleOnEdit(category)}
                            onReadOnly={() => handleOnReadOnly(category)}
                            renderList={renderList}
                            typeActive={typeActive}
                        />)
                }
                {typeActive !== 'owe' &&
                    <CategoryCreateButton onCreatedData={handleOnCreatedData} type={typeActive} renderList={renderList} />
                }
            </div>
            <CategoryUpdateModal
                catItem={editItem}
                renderList={renderList}
                isOnOpen={isOpenEdit}
                onClose={() => handleOnCloseEdit()}
                onUpdated={handleOnUpdatedData}
                onEditChildCat={handleOnEditChildCat}
            />
            <CategoryConfirmDeleteModal
                catItem={deleteItem}
                renderList={renderList}
                isOnOpen={isOpenConfirmDelete}
                onClose={() => handleOnCloseDelete()}
                typeActive={typeActive}
                onDeleted={handleOnDeleted}
            />
            <CategoryReadOnlyModal
                catItem={readOnlyItem}
                renderList={renderList}
                isOnOpen={isOpenReadOnly}
                onClose={() => handleOnCloseReadOnly()}
                onEditChildCat={handleOnEditChildCat}
            />
        </div>
    );
}

export default Categories;