import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    userProfile: {
        userId: null,
        userEmail: null,
        userName: null
    },
    isLogin: false,
    accessToken: null,
    refreshToken: null,
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setLogin: (state, action) => {
            localStorage.setItem('authUser', JSON.stringify({
                userId: action.payload.userId,
                accessToken: action.payload.accessToken,
                refreshToken: action.payload.refreshToken
            }))
            state.isLogin = true
            state.accessToken = action.payload.accessToken
            state.refreshToken = action.payload.refreshToken
            state.userProfile.userId = action.payload.userId
            state.userProfile.userName = action.payload.userName
            state.userProfile.userEmail = action.payload.userEmail
        },
        setLogout: (state) => {
            localStorage.removeItem('authUser')
            state.isLogin = false
            state.userProfile.userName = null
            state.accessToken = null
            state.refreshToken = null
            state.userProfile.userId = null
            state.userProfile.userEmail = null
            window.location.href = "/login";
        }
    }
})


export const { setLogin, setLogout } = authSlice.actions;
export default authSlice.reducer;
