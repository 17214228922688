import logo from "assets/img/logo.png"
import BgImage from "components/core/BgImage";
import Text from "components/core/Text";
import ChoiceSeparation from "components/core/ChoiceSeparation";
import FormAccessContainer from "components/access-page/FormAccessContainer";
import HeaderFormAccessContainer from "components/access-page/HeaderFormAccessContainer";
import LogInForm from "components/access-page/LogInForm";
import { useNavigate } from "react-router-dom";
import GoogleLoginButton from "components/access-page/GoogleLoginButton";
import Footer from "components/layouts/Footer";
import DemoLoginButton from "components/access-page/DemoLoginButton";


const LogIn = () => {

    const navigate = useNavigate();
    const moveToLogin = () => {
        navigate('/signup');
    }
    const moveToForgotPassword = () => {
        navigate('/forgot-password');
    }
    return (
        <main className="bg-stone-200 p-0 m-0 flex flex-col h-full ">
            <div className=" text-stone-900 flex flex-col justify-center items-center ml-auto mr-auto h-full mb-11 pb-11">
                <FormAccessContainer className='pb-2'>
                    <HeaderFormAccessContainer classCss="flex flex-col justify-center items-center">
                        <div className="mt-9 mb-3" >
                            <BgImage
                                url={logo}
                                height="100px"
                                width="100px"
                                size='contain'
                            />
                        </div>
                        <div className="mt-0 mx-10 mb-3">
                            <Text
                                className='text-[16px] font-semibold leading-5'
                            >
                                The Money Date
                            </Text>
                        </div>
                    </HeaderFormAccessContainer>
                    <LogInForm className='flex flex-col w-full px-10 justify-center'>
                    </LogInForm>
                    <div className="py-3 px-10 w-full">
                        <ChoiceSeparation />
                    </div>
                    <div className="py-2 px-9 w-full ">
                        <GoogleLoginButton />
                        <DemoLoginButton />
                    </div>
                    <div className="py-2 px-9 w-full " onClick={moveToForgotPassword}>
                        <Text className='text-accessLink text-xs cursor-pointer' >
                            Forgot password?
                        </Text>
                    </div>
                </FormAccessContainer>
                <div className="w-full text-accessPrimaryText flex flex-row justify-center round-[1px] py-3 bg-accessPrimaryBackground border-solid border-stone-300 border my-3 ">
                    <p className="w-full text-sm text-center m-4">Don't have an account?<span onClick={moveToLogin} className='inline text-sm font-semibold text-accessPrimaryButton cursor-pointer'> Sign up</span></p>

                </div>
            </div>
            <Footer className="text-accessSecondaryText" />
        </main>
    );
}

export default LogIn;