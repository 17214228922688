const ChoiceSeparation = () => {
    return (
        <div className="flex flex-row text-[100%] ">
            <div className=" relative grow top-[0.45em] h-[1px] bg-stone-300"></div>
            <div className="text-stone-500 text-[0.8125rem] mx-[18px] font-[600]">OR</div>
            <div className=" relative grow top-[0.45em] h-[1px] bg-stone-300"></div>
        </div>
    );
}

export default ChoiceSeparation;