const isEmail = (email) => {
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return regex.test(email);
}
const isPasswordValid = (password) => {
    if (!password || password.length < 5) {
        return false;
    }

    const commonPasswords = [
        '12345',
        'password',
        'qwerty',
        'letmein',
        'admin',
    ];

    if (commonPasswords.includes(password.toLowerCase())) {
        return false;
    }

    return true;
}

module.exports = {
    isEmail,
    isPasswordValid
}
