
import { useEffect, useRef, useState } from "react";
import { useDeleteCategoryMutation } from "services/api/CategoryService";
import { findObjByAttr, toastError } from "utils/helpers";
import trashIcon from "assets/img/trash-icon.svg"
import editIcon from "assets/img/edit-icon.svg"
import readIcon from 'assets/img/read-icon.svg'
import { CATEGORY_DEFAULT_ON_TYPE, CATEGORY_FIELDS } from "configs/category.config";



const CategoryListItem = ({ name, iconDir, parentCatId, isOweType, onEdit, onDelete, onReadOnly, renderList, typeActive }) => {
    const [parentName, setParentName] = useState()
    const [isReadOnlyCat, setIsReadOnlyCat] = useState(false);

    useEffect(() => {
        if (parentCatId) {
            const parentCat = findObjByAttr(renderList, [CATEGORY_FIELDS.id], parentCatId)
            if (parentCat) setParentName(parentCat[CATEGORY_FIELDS.name])
        } else {
            setParentName()
        }
    }, [parentCatId])

    useEffect(() => {
        setIsReadOnlyCat(() => {
            return isOweType || (name === CATEGORY_DEFAULT_ON_TYPE[typeActive])
        })
    }, [typeActive, name, isOweType])


    return (<>
        <div className="bg-stone-950 text-stone-200 text-base xl:w-full phone:w-[10rem] relative group z-0 p-4 rounded-lg shadow-md">
            <div className="w-full flex xl:flex-row phone:flex-col gap-2 justify-left items-center transition-all duration-300 ease-in-out group-hover:scale-95 group-hover:shadow-lg group-hover:opacity-50">
                <div className="rounded-full flex justify-center items-center bg-stone-300 h-11 w-11">
                    <img src={iconDir} className=" h-8" alt="icon" />
                </div>
                <div className={`${parentName ? 'text-sm sm:text-center xl:text-left ' : 'xl:text-base phone:text-sm'}`} >
                    <p>
                        {name}
                    </p>
                    <p className="text-stone-500">
                        {parentName}
                    </p>
                </div>

            </div>
            {!isReadOnlyCat && (<div className="xl:absolute phone:static phone:justify-center xl:mt-1 phone:mt-2 flex flex-row xl:gap-5 phone:gap-2 right-2 z-0 top-[35%] ">
                <span onClick={() => onEdit()} className="xl:hidden phone:block cursor-pointer opacity-70 group-hover:block hover:scale-110 hover:opacity-90 transition-all duration-300 ease-in-out ">
                    <img className="phone:h-4 xl:h-6 " src={editIcon} alt="edit icon" />
                </span>
                <span onClick={() => onDelete()} className="xl:hidden phone:block cursor-pointer opacity-70 group-hover:block hover:scale-110 hover:opacity-90 transition-all duration-300 ease-in-out ">
                    <img className="phone:h-4 xl:h-6 " src={trashIcon} alt="delete icon" />
                </span>
            </div>)}
            {isReadOnlyCat &&
                (<div className="xl:absolute phone:static phone:justify-center xl:mt-1 phone:mt-3 flex flex-row gap-5 right-5 z-0 top-[35%] ">
                    <span className="xl:hidden phone:block cursor-pointer opacity-70 group-hover:block hover:scale-110 hover:opacity-90 transition-all duration-300 ease-in-out ">
                        <img onClick={() => onReadOnly()} className="phone:h-5 xl:h-6" src={readIcon} alt="edit icon" />
                    </span>
                </div>)}
        </div >
    </>);
}

export default CategoryListItem;