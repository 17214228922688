import Button from "components/core/Button";
import AccessInput from "components/access-page/AccessInput";
import useAccessFormController from "hooks/access/useAccessFormController";
import { useEffect, useState } from "react";
import Text from 'components/core/Text'
import { useLocation } from "react-router-dom";
import { useResetPasswordMutation } from "services/api/AccessService";
import toast from "react-hot-toast";

const ResetPasswordForm = ({ className, children }) => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const { isCanSubmit, formData, handleOnSubmit, handleCheckValid, handleOnAccessInputChange, handleURLParams, handleUserData, handleError } = useAccessFormController()
    const [resetPassword, { data, isSuccess, isLoading, error }] = useResetPasswordMutation();
    const [isPassMatch, setIsPassMatch] = useState(undefined)

    useEffect(() => {
        handleURLParams({
            userId: queryParams.get('id'),
            token: queryParams.get('token')
        })
    }, [formData.userId, formData.token]);

    useEffect(() => {
        if (error) {
            handleError(error);
        }
        if (isSuccess) {
            toast.success('Change password success!')
            setTimeout(() => {
                handleUserData(data);
            }, 3000)
        }
    }, [data, error])

    useEffect(() => {
        if (formData['repeat_password']) {
            if (formData['password'] !== formData['repeat_password']) {
                setIsPassMatch(false)
            }
            if (formData['password'] === formData['repeat_password']) {
                setIsPassMatch(true)
            }
        }
    }, [formData['password'], formData['repeat_password'], isPassMatch])

    return (
        <form
            onSubmit={(e) => handleOnSubmit(e, resetPassword)}
            className={className}
            method="POST">
            <AccessInput
                label='password'
                labelText='New password'
                onAccessInputChange={handleOnAccessInputChange}
                onCheckValid={handleCheckValid}
            />
            <br></br>
            <AccessInput
                label='repeat_password'
                labelText='New password, again'
                onAccessInputChange={handleOnAccessInputChange}
                onCheckValid={handleCheckValid}
                isPassMatch={isPassMatch}
            />
            {isPassMatch === false && <Text className='text-start text-sm text-accessSecondaryText'>Passwords don't match</Text>}
            {children}
            <br></br>
            <Button
                type='submit'
                disabled={!isCanSubmit || !isPassMatch || isLoading}
                className='bg-accessPrimaryButton text-accessTextWhite py-3 px-[16px] my-3'>
                {isLoading ? 'Waiting' : 'Reset password'}
            </Button>
        </form>
    );
}

export default ResetPasswordForm;