const clientId = process.env.REACT_APP_GG_CLIENT_ID;
export const loadGoogleSignInScript = (handleResponse) => {
    const script = document.createElement('script');
    script.src = 'https://accounts.google.com/gsi/client';
    script.onload = () => {
        window.google.accounts.id.initialize({
            client_id: clientId,
            callback: handleResponse,
        });
        window.google.accounts.id.renderButton(
            document.getElementById('signInGoogle'),
            { theme: 'outline', size: 'large' }
        );
        window.google.accounts.id.prompt();
    };
    document.body.appendChild(script);
}

