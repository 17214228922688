import Modal from "components/core/Modal";
import { CATEGORY_DEFAULT_ON_TYPE, CATEGORY_FIELDS } from "configs/category.config";
import moveToIcon from "assets/img/move-to.svg"
import CategorySelectionOnReplacement from "./CategorySelectionOnReplacement";
import { useState, useEffect } from "react";
import Button from "components/core/Button";
import { findObjByAttr, toastError } from "utils/helpers";
import { useDeleteCategoryMutation } from "services/api/CategoryService";
import toast from "react-hot-toast";


const CategoryConfirmDeleteModal = ({ isOnOpen, onClose, catItem, renderList, typeActive, onDeleted }) => {

    const [deleteItem, setDeleteItem] = useState(catItem)
    const [replacementCat, setReplacementCat] = useState({ name: CATEGORY_DEFAULT_ON_TYPE[typeActive] });
    const [isModalOpen, setIsModalOpen] = useState(isOnOpen)
    const [deleteCategory, { data, isSuccess, error }] = useDeleteCategoryMutation();


    const handleOnSelectedCat = (cat) => {
        setReplacementCat(cat)
    }
    const closeModal = () => {
        setReplacementCat()
        onClose()
        setIsModalOpen(false)
    }
    const handleDeleteAll = async (catId) => {
        await deleteCategory({ catId, replaceId: '' })
    }
    const handleReplacement = async (catId, replaceId) => {
        if (!replaceId) {
            const defaultReplacement = findObjByAttr(renderList, [CATEGORY_FIELDS.name], CATEGORY_DEFAULT_ON_TYPE[typeActive]);
            replaceId = defaultReplacement[CATEGORY_FIELDS.id]
        }
        await deleteCategory({ catId, replaceId });
    }

    useEffect(() => {
        if (error) {
            toastError(error);
        }
        if (isSuccess) {
            toast.success('category deleted!')
            onClose()
            onDeleted(deleteItem[CATEGORY_FIELDS.id])
            setIsModalOpen(false)
        }
    }, [error, isSuccess])

    useEffect(() => {
        if (!replacementCat) {
            const defaultReplacementCat = findObjByAttr(renderList, [CATEGORY_FIELDS.name], CATEGORY_DEFAULT_ON_TYPE[typeActive])
            setReplacementCat(defaultReplacementCat)
        }
    }, [replacementCat])

    useEffect(() => {
        setDeleteItem(catItem)
    }, [catItem])
    useEffect(() => {
        setIsModalOpen(isOnOpen);
    }, [isOnOpen])
    return (
        <Modal
            isOpen={isModalOpen}
            onClose={closeModal}
            className='max-h-1/2 max-w-1/2 h-[20rem] w-[35rem] flex flex-col gap-2 items-center pt-4 px-4'
        >
            <div className="flex flex-col gap-5 justify-left min-w-64 w-full  ">
                <span className="text-left leading-7 w-full">Do you want to delete all transactions of category
                    <span type="text" className="bg-red-700 rounded-md mx-2 px-2 py-1">{deleteItem && deleteItem[CATEGORY_FIELDS.name]}</span>
                    or
                </span>

                <div className="flex flex-row justify-left items-center">
                    <div className="rounded-full h-11 w-11 mr-2" >
                        <img src={moveToIcon} className="inline h-10" alt="icon" />
                    </div>
                    <span>Move them to:</span>
                    <CategorySelectionOnReplacement
                        deletedCatId={deleteItem && deleteItem[CATEGORY_FIELDS.id]}
                        onSelectCat={handleOnSelectedCat}
                        renderList={renderList}
                        defaultReplacementCatName={CATEGORY_DEFAULT_ON_TYPE[typeActive]}
                    />
                </div>


            </div>
            <div className=" absolute w-full bottom-5 flex flex-col justify-center items-center gap-2 px-5">
                <Button
                    onClick={(e) => handleReplacement(deleteItem[CATEGORY_FIELDS.id], replacementCat[CATEGORY_FIELDS.id])}
                    className='bg-green-700 px-3 py-2'> Move to {replacementCat && replacementCat[CATEGORY_FIELDS.name]}
                </Button>
                <Button onClick={(e) => handleDeleteAll(deleteItem[CATEGORY_FIELDS.id])} className='bg-red-700 px-3 py-2'>Delete All Transactions</Button>
                <Button type='button' className='bg-stone-600 px-3 py-2' onClick={closeModal}>Cancel</Button>
            </div>
        </Modal>);
}

export default CategoryConfirmDeleteModal;