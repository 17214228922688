import { useCallback, useState, useEffect } from "react";
import { useDispatch } from 'react-redux'
import { setLogin } from "store/auth";
import toast from "react-hot-toast";
import { isErrorWithMessage, isFetchBaseQueryError } from "utils/helpers";

export default function useAccessFormController() {
    const [isCanSubmit, setIsCanSubmit] = useState(false)
    const [formData, setFormData] = useState({})
    const [checkValid, setCheckValid] = useState({})
    const dispatch = useDispatch()

    const handleURLParams = (params) => {
        if (!params) return
        setFormData((prev) => {
            return {
                ...prev,
                ...params
            }
        })
    }


    const handleOnAccessInputChange = (name, value) => {
        setFormData((prev) => {
            return {
                ...prev,
                [name]: value
            }
        })
    }
    const handleCheckValid = useCallback((label, isValid) => {
        setCheckValid((prev) => {
            return {
                ...prev,
                [label]: isValid
            }
        })
    }, [])

    useEffect(() => {
        setIsCanSubmit(Object.values(checkValid).every(value => value === true));
    }, [checkValid]);


    const handleOnSubmit = async (e, requestCallback) => {
        e.preventDefault();
        if (isCanSubmit) {
            await requestCallback({ ...formData });
        }
    }


    const handleUserData = (responseData) => {
        const authData = responseData.metadata;
        dispatch(setLogin({
            userId: authData.user.userId,
            userEmail: authData.user.email,
            userName: authData.user.name,
            accessToken: authData.tokens.accessToken,
            refreshToken: authData.tokens.refreshToken
        }))
    }

    const handleError = (error) => {
        if (isFetchBaseQueryError(error)) {
            const errorMessage = error.data ? error.data.message : error.error
            toast.error(errorMessage)
        }
        if (isErrorWithMessage(error)) {
            toast.error(error.message)
        }
    }

    return {
        isCanSubmit,
        formData,
        handleOnAccessInputChange,
        handleOnSubmit,
        handleCheckValid,
        handleUserData,
        handleError,
        handleURLParams
    }
}
