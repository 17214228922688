const Link = ({ url = '#', className, children }) => {

    const defaultClassName = 'm-0 p-0 inline bg-transparent cursor-pointer'

    return (
        <a href={url} className={`${defaultClassName} ${className}`}>
            {children}
        </a >
    );
}

export default Link;